import {
    Autocomplete,
    AutocompleteOption,
    Box,
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    ListItemContent,
    Textarea,
    Typography
} from "@mui/joy";
import React, {useContext, useEffect, useState} from "react";
import {NativeSelect} from "@mui/material";
import {PreviewProduct} from "../../model/product";
import ProductPreview from "../molecule/ProductPreview";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {createProductFromReference} from "../../api/mutation";
import Loading from "../atom/Loading";
import {ErrorContext} from "../../context/ErrorContext";
import {SERVICE_REQUEST_PRODUCTS_V2_PATH} from "../../constant/routes";
import {ForwardTo} from "../molecule/ForwardTo";
import {findTestProductForReference, jiraInitiatives, product} from "../../api/query";
import {useErrorNotification} from "../../api/apolloClient";
import RequestFor from "../molecule/formControl/RequestFor";
import ProductName from "../molecule/formControl/ProductName";
import Initiative from "../molecule/formControl/Initiative";
import ExistingReferenceProduct from "../molecule/ExistingReferenceProduct";
import {AuthContext} from "../../context/AuthContext";
import {MissingTeamOrInitiativeCta} from "../molecule/MissingTeamOrInitiativeCta";
import Stage from "../molecule/formControl/Stage";
import SalesLinesMultiSelect from "../molecule/formControl/SalesLinesMultiSelect";
import Prio from "../molecule/formControl/Prio";
import DueDate from "../molecule/formControl/DueDate";
import MultiCheckbox from "../molecule/MultiCheckbox";
import SapPlantId from "../molecule/formControl/SapPlantId";
import {SourceNodesContext} from "../../context/SourceNodesContext";
import {intSourceNodes, qaSourceNodes } from "../../constant/sourceNodes"

const RequestNewTestProduct = () => {
    const {user} = useContext(AuthContext);
    const [requestFor, setRequestFor] = useState("")
    const [team, setTeam] = useState("")
    const [initiative, setInitiative] = useState("")
    const [reference, setReference] = useState("")
    const [preview, setPreview] = useState({})
    const [stage, setStage] = useState("")
    const salesLinesState = useState("MM");
    const [salesLines] = salesLinesState
    let now = new Date();
    now.setDate(now.getDate() + 14)
    const [dueDate, setDueDate] = useState(now.toISOString().split('T')[0])
    const [prio, setPrio] = useState("Trivial")
    const featuresState = useState([]);
    const [features] = featuresState

    const [availableOnline, setAvailableOnline] = useState("")
    const [storeAvailability, setStoreAvailability] = useState("")
    const [availableInStoreIds, setAvailableInStoreIds] = useState([])
    const [furtherDetails, setFurtherDetails] = useState("")
    const [warranties, setWarranties] = useState("")
    const [services, setServices] = useState("")
    const [price, setPrice] = useState("")
    const [logisticsClass, setLogisticsClass] = useState("")
    const [morphologicalData, setMorphologicalData] = useState("")
    const [fspId, setFspId] = useState("")
    const [sapPlantId, setSapPlantId] = useState("")

    const [createdId, setCreatedId] = useState(undefined);
    const {setError} = useContext(ErrorContext);
    const jiraInitiativesQuery = useQuery(jiraInitiatives, {notifyOnNetworkStatusChange: true})
    const [fetchProductForReference, findTestProductForReferenceQuery] = useLazyQuery(findTestProductForReference, {notifyOnNetworkStatusChange: true})
    const [fetchProduct, productQuery] = useLazyQuery(product, {notifyOnNetworkStatusChange: true})
    const {sourceNodesByForeignId, sourceNodesQuery} = useContext(SourceNodesContext);

    useErrorNotification(jiraInitiativesQuery.error, setError)
    useErrorNotification(sourceNodesQuery?.error, setError)
    useErrorNotification(findTestProductForReferenceQuery.error, setError)
    useErrorNotification(productQuery.error, setError)

    const [createProductFromReferenceMutation, {loading}] = useMutation(createProductFromReference);
    useEffect(() => {
        if (!preview?.id) return
        if (!stage) return
        fetchProductForReference({
            notifyOnNetworkStatusChange: true,
            variables: {
                input: {
                    id: preview.id,
                    country: preview.country.toUpperCase(),
                    stage: stage
                }
            }
        }).then(res => {
            const ref = res.data?.findTestProductForReference?.id
            if (ref) {
                fetchProduct({
                    notifyOnNetworkStatusChange: true,
                    variables: {
                        input: {productId: ref}
                    }
                })
            }
        })

    }, [preview?.id, stage, fetchProductForReference, fetchProduct, preview?.country])

    if (jiraInitiativesQuery.loading || sourceNodesQuery?.loading) return <Loading/>
    if (!jiraInitiativesQuery.data?.jiraInitiatives) return null
    const initiatives = jiraInitiativesQuery.data.jiraInitiatives.initiatives.map(it => `${it.key} ${it.summary}`);

    const existingReference = findTestProductForReferenceQuery.data?.findTestProductForReference?.id;
    const readyToSubmit = preview && preview.id && existingReference === null

    /**
     * @param e
     * @param {PreviewProduct} product
     */
    const handleSubmit = (e, product) => {
        e.preventDefault();
        if (!readyToSubmit || !user.isAdmin) return
        createProductFromReferenceMutation({
            notifyOnNetworkStatusChange: true, variables: {
                input: {
                    reference: {
                        country: product.country.toUpperCase(),
                        salesLine: product.salesLine,
                        id: product.id,
                    },
                    targetStage: stage,
                    team: team || null,
                    initiative: initiative || null,
                    creationDetails: {
                        availableOnline: availableOnline === "t",
                        availableInStoreIds: availableInStoreIds.map(it => it.foreignId),
                        furtherDetails,
                        warranties,
                        services,
                        price,
                        logisticsClass,
                        morphologicalData,
                        fspId,
                        sapPlantId
                    }
                }
            }
        })
            .catch(err => setError(err))
            .then(res => setCreatedId(res.data.createProductFromReference.sagaId))
    };

    if (createdId) {
        return <ForwardTo allowBack target={SERVICE_REQUEST_PRODUCTS_V2_PATH + "/" + createdId}/>
    }

    return <>
        <Typography level={"h2"}>Request Test Product</Typography>
        <Typography level={"subtitle"}>Pick a product from production to replicate it in the test system</Typography>

        <form onSubmit={(e) => handleSubmit(e, preview)}>
            <Box sx={{display: "flex", gap: 1, flexDirection: "column"}}>
                <RequestFor value={requestFor} onChange={(e) => setRequestFor(e.target.value)}/>

                {requestFor === "team" &&
                    <ProductName value={team} onChange={(event, newValue) => setTeam(newValue)}/>}
                {requestFor === "initiative" && <Initiative
                    value={initiative}
                    onChange={(event, newValue) => {
                        setInitiative(newValue);
                    }}
                    options={initiatives}/>}
                <FormHelperText>We will prioritize your request based on this quarters business
                    priorities.</FormHelperText>
                <MissingTeamOrInitiativeCta/>
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>

                <FormControl>
                    <FormLabel required>Production Webshop Reference</FormLabel>
                    <Input required value={reference}
                           name={"production-reference"}
                           placeholder={"https://www.mediamarkt.de/de/product/_samsung-galaxy-s22-5g-128-gb-phantom-white-dual-sim-galaxy-s22-5g-2784780.html"}
                           onChange={e => setReference(e.target.value)}
                           onBlur={e => setPreview(PreviewProduct.fromUrl(e.target.value))}
                    />
                </FormControl>
                <Typography level={"h3"}>Create in:</Typography>
                <Box
                    sx={{display: "flex", flexWrap: "wrap", gap: 3}}>
                    <Stage value={stage} onChange={(e) => {
                        setStage(e.target.value);
                        setAvailableInStoreIds([])
                    }}/>
                    <FormControl orientation={"vertical"}>
                        <FormLabel>Country</FormLabel>
                        <NativeSelect disabled value={preview?.country?.toUpperCase() || ""} variant={"filled"}>
                            <option value={preview?.country?.toUpperCase() || ""} disabled>{preview?.country?.toUpperCase() || ""}</option>
                        </NativeSelect>
                    </FormControl>

                    {preview?.country?.toUpperCase() === "DE" && <><SalesLinesMultiSelect state={salesLinesState}/></>}
                    <Prio value={prio} onChange={(e) => setPrio(e.target.value)}/>
                    <DueDate value={dueDate} onChange={e => {
                        setDueDate(e.target.value);
                    }}/>
                </Box>
                <>
                    {preview.id && preview.salesLine && preview.country &&
                        <ProductPreview product={preview}></ProductPreview>}
                </>
                {
                    findTestProductForReferenceQuery.loading && <Loading/>
                }
                {
                    existingReference && <>
                        <Typography color={"danger"} aria-invalid={true}>a similar product already exists, please pick a different reference from production</Typography>
                        <ExistingReferenceProduct product={productQuery.data?.product.product}></ExistingReferenceProduct>
                    </>
                }

                <Box sx={{display: "flex", gap: 3}}>
                    <div style={{width: "60%"}}>
                        <Box sx={{display: 'flex', flexDirection: "column", gap: 2}}>

                            <FormControl orientation={"horizontal"}>
                                <FormLabel required>Needs Online Availability</FormLabel>
                                <NativeSelect required value={availableOnline} variant={"filled"}
                                              onChange={(e) => setAvailableOnline(e.target.value)}>
                                    <option value="">please select</option>
                                    <option value={"t"}>yes</option>
                                    <option value={"f"}>no</option>
                                </NativeSelect>
                            </FormControl>

                            <FormControl orientation={"horizontal"}>
                                <FormLabel required>Needs Store Availability</FormLabel>
                                <NativeSelect required value={storeAvailability} variant={"filled"}
                                              onChange={(e) => setStoreAvailability(e.target.value)}>
                                    <option value="">please select</option>
                                    <option value={"t"}>yes</option>
                                    <option value={"f"}>no</option>
                                </NativeSelect>
                            </FormControl>

                            {storeAvailability === "t" && preview?.country && stage && <FormControl>
                                <FormLabel required>In Stores:</FormLabel>
                                <Autocomplete
                                    required={availableInStoreIds.length === 0}
                                    multiple
                                    filterSelectedOptions
                                    value={availableInStoreIds}
                                    onChange={(event, newValue) => {
                                        setAvailableInStoreIds(newValue);
                                    }}
                                    placeholder="409 MM Examplestore"
                                    options={[...sourceNodesByForeignId.values()]

                                        .filter(it => (stage === "QA" ? qaSourceNodes : intSourceNodes).includes(+it.foreignId))
                                        .filter(it => it.country === preview?.country?.toUpperCase())
                                        .filter(it => it.type === "STORE")
                                        .filter(it => preview?.country?.toUpperCase() === "DE" ? salesLines.includes(it.name.substring(0, 2)) : true)
                                        .sort((it, other) => it.foreignId - other.foreignId)
                                    }
                                    sx={{maxWidth: 500}}

                                    getOptionLabel={(option) => `${option.foreignId}${option.active ? " " : " (inactive) "}${option.name}`}
                                    renderOption={(props, option) => (
                                        <AutocompleteOption {...props}>
                                            <ListItemContent>
                                                {`${option.foreignId}${option.active ? " " : " (inactive) "}${option.name}`}
                                            </ListItemContent>
                                        </AutocompleteOption>
                                    )}

                                />
                            </FormControl>}
                            <FormControl>
                                <FormLabel>More</FormLabel>
                                <Textarea minRows={2} value={furtherDetails}
                                          onChange={e => setFurtherDetails(e.target.value)}
                                          placeholder={"Name, category, FSK, pre-order, promotion, bundle, lead time, ...."}/>
                                <FormHelperText>what else do we need to know?</FormHelperText>
                            </FormControl>

                        </Box>

                    </div>
                    <div style={{width: "40%"}}>
                        <Box sx={{display: 'flex', flexDirection: "column", gap: 2}}>
                            <Box sx={{display: 'flex', flexDirection: "column", gap: 1}}>
                                <FormControl>
                                    <FormLabel>additional requirements</FormLabel>
                                </FormControl>
                                <MultiCheckbox value={"Attached Warranty"} state={featuresState}/>
                                <MultiCheckbox value={"Attached Service"} state={featuresState}/>
                                <MultiCheckbox value={"Specific Price"} state={featuresState}/>
                                <MultiCheckbox value={"Logistics Class"} state={featuresState}/>
                                <MultiCheckbox value={"Morphological Data"} state={featuresState}/>
                                <MultiCheckbox value={"FSP ID"} state={featuresState}/>
                                <MultiCheckbox value={"SAP Plant ID"} state={featuresState}/>
                            </Box>

                            {features.includes("Attached Warranty") && <FormControl>
                                <FormLabel required>Warranties</FormLabel>
                                <Input required type="text" value={warranties}
                                       placeholder={"Product ID"}
                                       onChange={e => setWarranties(e.target.value)}/>
                            </FormControl>}
                            {features.includes("Attached Service") && <FormControl>
                                <FormLabel required>Services</FormLabel>
                                <Input required value={services}
                                       placeholder={"Product ID"}
                                       onChange={e => setServices(e.target.value)}/>
                            </FormControl>}
                            {features.includes("Specific Price") && <FormControl>
                                <FormLabel required>Price</FormLabel>
                                <Input required value={price} placeholder={"12.99"}
                                       onChange={e => setPrice(e.target.value)}
                                       slotProps={{
                                           "input": {
                                               "pattern": "[0-9.,]*"
                                           }
                                       }
                                       }
                                />
                                <FormHelperText>positiv number, ',' or '.' as decimal separator</FormHelperText>
                            </FormControl>}

                            {features.includes("Logistics Class") && <FormControl>
                                <FormLabel required>Logistics Class</FormLabel>
                                <Input required value={logisticsClass} placeholder={"3"}
                                       onChange={e => setLogisticsClass(e.target.value)}/>
                            </FormControl>}
                            {features.includes("Morphological Data") && <FormControl>
                                <FormLabel required>Morphological Data</FormLabel>
                                <Input required value={morphologicalData}
                                       placeholder={"10cmx5cmx1cm weight 0.06kg"}
                                       onChange={e => setMorphologicalData(e.target.value)}/>
                            </FormControl>}
                            {features.includes("FSP ID") && <FormControl>
                                <FormLabel required>FSP ID</FormLabel>
                                <Input required value={fspId} placeholder={"1403, 1402"}
                                       onChange={e => setFspId(e.target.value)}/>
                            </FormControl>}
                            {features.includes("SAP Plant ID") && <SapPlantId value={sapPlantId}
                                                                              onChange={e => setSapPlantId(e.target.value)}/>}
                        </Box>
                        <Box p={3}>
                            <Button fullWidth disabled={!readyToSubmit} type={"submit"}>{loading ? <Loading/> : "Submit"}</Button>
                        </Box>

                    </div>
                </Box>


            </Box>
        </form>


    </>
}

export default RequestNewTestProduct