import {IS_STAGE_DEV, IS_STAGE_INT, IS_STAGE_PROD, IS_STAGE_QA} from "./stage";

function serviceurl() {
    if (IS_STAGE_DEV) return "https://test-data-navigator-dev.cloud.mmst.eu"
    if (IS_STAGE_INT) return "https://test-data-navigator-int.cloud.mmst.eu"
    if (IS_STAGE_QA) return "https://test-data-navigator-qa.cloud.mmst.eu"
    if (IS_STAGE_PROD) return "https://test-data-navigator.cloud.mmst.eu"
    return "http://localhost:8080";
}

const SERVICE_URL = serviceurl()

export default SERVICE_URL
