import {Autocomplete, FormControl, FormLabel} from "@mui/joy";
import {distinct} from "../../../model/utils";
import {productNames} from "../../../constant/productName";
import React from "react";

const ProductName = ({onChange, value}) => <FormControl>
    <FormLabel required>Product Name</FormLabel>
    <Autocomplete
        required
        value={value}
        onChange={onChange}
        placeholder="After Sales, Store Checkout,...."
        options={distinct(productNames).sort()}
    />
</FormControl>;

export default ProductName