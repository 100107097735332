import React from "react";
import {Box, Card, Typography} from "@mui/joy";
import {ShoppingCart} from "@mui/icons-material";
import WebshopLink from "./WebshopLink";
import {LinkTargetBlank} from "../atom/LinkTartgetBlank";
import {SERVICE_CATALOG_PATH} from "../../constant/routes";
import {SERVICE_CATALOG} from "../../constant/services";

export const displayName = product => product.salesProductsApiScrapeResults[0]?.basicName || product.webshopScrapeResults[0]?.title || "unknown";

const ExistingReferenceProduct = ({product}) => {
    if (!product) return null
    return <Card variant={"soft"}
                 sx={{display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-around"}}>
        <Box>
            <Box display={"flex"} sx={{alignItems: "baseline", gap: 1}}>
                <Typography
                    level={"h4"}>{product.env}-{product.country}-{product.foreignId}</Typography>
                <Typography level={"h5"}>{displayName(product)}</Typography>
                <Typography level={"h5"}>{product.salesProductsApiScrapeResults[0]?.kindOfProduct}</Typography>
            </Box>
            <Box display={"flex"} sx={{alignItems: "flex-start"}}>
                {product.webshopScrapeResults[0]?.httpStatusCode === 200 && <>
                    <ShoppingCart color={product.webshopScrapeResults[0]?.buyButtonEnabled ? "success" : "error"}
                                  fontSize={"small"} sx={{marginRight: 1}}/>
                    <WebshopLink product={product}></WebshopLink>
                </>}
            </Box>
            <LinkTargetBlank
                href={"/#" + SERVICE_CATALOG_PATH + "?q=" + product.foreignId}
            >find in {SERVICE_CATALOG.icon} Catalog</LinkTargetBlank>
        </Box>
    </Card>
};

export default ExistingReferenceProduct