import {Box, FormControl, FormLabel, Input} from "@mui/joy";
import React, {useEffect, useState} from "react";

export const RangeFilter = ({labels, fromState: [from, setFrom], untilState: [until, setUntil], upperBound, factor}) => {
    const [fromValue, setFromValue] = useState(from)
    const [untilValue, setUntilValue] = useState(until)
    const _upperBound = "" +(Math.ceil(upperBound * factor) || 0)

    useEffect(() => {
        if (fromValue === "") {
            setFrom("")
        } else if (!isNaN(+fromValue)) {
            setFrom(+fromValue)
        }

        if (+fromValue < 0) {
            setFromValue(Math.abs(+fromValue))
        }
        if (+untilValue < 0) {
            setUntilValue(Math.abs(+untilValue))
        }

        if (untilValue === "") {
            setUntil("")
        } else if (!isNaN(+untilValue)) {
            setUntil(+untilValue)
        }
    }, [fromValue, untilValue, setUntil, setFrom])

    useEffect(() => {
        if (from === "") {
            setFromValue("")
        }
        if (until === "") {
            setUntilValue("")
        }
    }, [from, until, setUntilValue, setFromValue])

    return <>
        <Box
            sx={{"display": "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "flex-end"}}>
            <FormControl sx={{maxWidth: 0.48}}>
                <FormLabel>{labels[0]}</FormLabel>
                <Input variant={"soft"} value={fromValue} placeholder={"0.0"}
                       onChange={e => {
                           return setFromValue(e.target.value);
                       }}/>
            </FormControl>
            <FormControl sx={{maxWidth: 0.48}}>
                <FormLabel>{labels[1]}</FormLabel>
                <Input variant={"soft"} value={untilValue} placeholder={_upperBound}
                       onChange={e => {
                           return setUntilValue(e.target.value);
                       }}/>
            </FormControl>
        </Box>
    </>;
};