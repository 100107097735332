import {Box, Button} from "@mui/joy";
import {NavigateBefore, NavigateNext, FirstPage, LastPage} from "@mui/icons-material";
import React from "react";

const Pagination = ({results, perPage, currentPage, setPage}) => {
    const pages = Math.ceil(results / perPage);

    const PageButton = () => {
            return <Button color={"primary"} variant={"soft"} disabled>{currentPage + 1} / {pages}</Button>
    };

    return <>
        <Box sx={{display: "inline-flex", justifyContent: "center"}}>
            <Button variant={"plain"} onClick={() => setPage(0)}><FirstPage/></Button>
            <Button variant={"plain"} onClick={() => setPage(Math.max(0, currentPage - 1))}><NavigateBefore/></Button>
            <PageButton/>
            <Button variant={"plain"}
                    onClick={() => setPage(Math.min(pages - 1, currentPage + 1))}><NavigateNext/></Button>
            <Button variant={"plain"} onClick={() => setPage(pages - 1)}><LastPage/></Button>
        </Box>
    </>;
};

export default Pagination