import {useMutation} from '@apollo/client';
import React, {useContext, useEffect, useState} from "react";
import {Box, Button, FormControl, FormHelperText, FormLabel, Input, Switch, Textarea, Typography} from "@mui/joy";
import {ErrorContext} from "../../context/ErrorContext";
import LocalDevHelpText from "../molecule/LocalDevHelpText";
import {trackProduct} from "../../api/mutation";
import {CatalogContext} from "../../context/CatalogContext";
import Loading from "../atom/Loading";
import {useErrorNotification} from "../../api/apolloClient";
import AddToCatalog from "./AddToCatalog";

const ManageCatalog = () => {
    const {products, query} = useContext(CatalogContext)
    const {error, loading, refetch} = query;
    const {setError} = useContext(ErrorContext);

    const [id, setId] = useState("")
    const [stage, setStage] = useState("");
    const [salesLine, setSalesLine] = useState("MM");
    const [country, setCountry] = useState("");
    const [added, setAdded] = useState([]);

    const [, trackProductState] = useMutation(trackProduct);
    useErrorNotification(error, setError)

    useEffect(() => {
        if (country !== "DE") setSalesLine("MM")
    }, [country, setSalesLine])



    if (loading || !(products instanceof Array)) return <Loading/>
    if (error) {
        return null;
    }


    async function submitForm(e) {
        e.preventDefault()

        let ids
        if (id.includes("\n")) {
            ids = id.split("\n")
        } else {
            ids = [id]
        }
        setId("")
        setStage("")

        const toAdd = ids.map(it => it.replace(/\s/g,'')).filter(it => it.length !== 0).map(it => (
            {id: it,  stage, country, salesLine}
        ))

        setAdded([...added.filter(it => toAdd.every(add => JSON.stringify(add) !== JSON.stringify(it))), ...toAdd])
        setTimeout(() => refetch(), 2)
    }

    let createThis = async event => {
        const split = event.target.textContent.split(" ")
        await setAdded([...added.filter(it => JSON.stringify(it) !== JSON.stringify({
            id: split[2],
            stage: split[1],
            country: split[0],
            salesLine: "MM"
        })), {id: split[2], stage: split[1], country: split[0], salesLine: "MM"}]);
    };

    let setTextAreaValueWithAutoSplitting = e => {
        let v = e.target.value;
        let lines = v.split("\n").map(it => it.trimStart());
        let newLines = lines.flatMap(it => {
            if (it.length < 14) {
                return [it];
            }
            return it.replaceAll(/\s/g, '').match(/.{1,7}/g) ?? []
        }).join("\n");
        setId(newLines);
    };
    return <>
        <Typography level={"h1"}>Admin</Typography>
        <Box sx={{display: "flex", gap: 3}}>
        <form style={{maxWidth: "400px"}} onSubmit={submitForm}>
            <FormControl orientation={"horizontal"}>
                <FormLabel>country</FormLabel>
                <Input name="id" type="text" value={country} required={true}
                       placeholder={"DE"}
                       onChange={e => setCountry(e.target.value.toUpperCase())}/>
            </FormControl>

            {country === "DE" && <>

                <FormControl required sx={{flexBasis: "flex-start"}}>
                    <FormLabel required>Sales Line</FormLabel>
                    <Switch
                        startDecorator={'SE'}
                        checked={salesLine === "MM"}
                        onChange={() => setSalesLine(salesLine === "MM" ? "SE" : "MM")}
                        color={'neutral'}
                        variant="solid"
                        endDecorator={'MM'}
                    />
                </FormControl>

            </>}

            <label>
                <input required={!stage} type="radio" name="env-int" value="QA" checked={stage === 'QA'}
                       onChange={function (e) {
                           setStage(e.target.value);
                       }}/>
                QA
            </label>
            <label>
                <input required={!stage} type="radio" name="env-qa" value="INT" checked={stage === 'INT'}
                       onChange={function (e) {
                           setStage(e.target.value);
                       }}/>
                INT
            </label>
            <FormControl>
                <FormLabel>id</FormLabel>
                <Textarea name="id" type="text" value={id} required={true}
                          placeholder={"2234058"}
                          onChange={setTextAreaValueWithAutoSplitting}/>
                <FormHelperText>Add products to track</FormHelperText>
                <LocalDevHelpText>
                    for example:
                    <Button variant={"plain"} type={"button"} onClick={createThis}>DE QA 2838233</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>DE INT 2822862</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>DE INT 2822864</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>DE INT 2838064</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>ES INT 1537589</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>ES INT 1537650</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>ES INT 1537589</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>ES QA 1541878</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>NL INT 1742017</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>NL QA 1742017</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>NL INT 1742014</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>NL QA 1742014</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>BE INT 1886706</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>BE INT 2012489</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>BE QA 2037468</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>BE QA 2037475</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>AT QA 2833339</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>AT QA 2803234</Button>
                    <Button variant={"plain"} type={"button"} onClick={createThis}>CH INT 2141475</Button>
                </LocalDevHelpText>
            </FormControl>


            <button
                type={"submit"}>{trackProductState.loading ?
                <Loading/> : "add product to catalog"}</button>

        </form>
            {added.length > 0 && <Box sx={{display: "flex", flexDirection:"column"}}>
                {added.sort((it, other) => `${it.stage}-${it.country}-${it.salesLine}-${it.id}`.localeCompare(`${other.stage}-${other.country}-${other.salesLine}-${other.id}`)).map(it => <AddToCatalog key={JSON.stringify(it)} id={it.id} salesLine={it.salesLine} country={it.country} stage={it.stage}/>)}
            </Box>}
        </Box>
    </>
}

export default ManageCatalog