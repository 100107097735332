import {Box, Button, Card, FormControl, FormHelperText, FormLabel, Input, Typography} from "@mui/joy";
import React, {useContext, useState} from "react";
import {useParams} from "react-router-dom";
import {ADMIN_INSPECT_ID_PATH} from "../../constant/routes";
import {useMutation, useQuery} from "@apollo/client";
import {product} from "../../api/query";
import Loading from "../atom/Loading";
import {ErrorContext} from "../../context/ErrorContext";
import ChangesPagination from "../molecule/ChangesPagination";
import LocalDateTime from "../atom/LocalDateTime";
import {useErrorNotification} from "../../api/apolloClient";
import {WebshopDetailsDiff} from "../molecule/WebshopDetailsDiff";
import {SalesApiDetailsDiff} from "../molecule/SalesApiDetailsDiff";
import {HealthChecksDiff} from "../molecule/HealthChecksDiff";
import {NativeSelect} from "@mui/material";
import {addExpectation, checkProduct, removeExpectation} from "../../api/mutation";

const EnterId = () => {
    const [id, setId] = useState("");
    return <>
        <form action={`/#${ADMIN_INSPECT_ID_PATH}/${id}`} method={"GET"}>
            <FormControl orientation={"horizontal"}>
                <FormLabel>ID</FormLabel>
                <Input required value={id} onChange={e => setId(e.target.value)}
                       slotProps={{
                           input: {
                               maxLength: 30
                           },
                       }}/>
                <FormHelperText>UUID or env/country/id like INT-DE-2981238</FormHelperText>
                <Button type={"submit"}>submit</Button>

            </FormControl>
        </form>
    </>
}


const WebshopScrapeResultsHistory = ({results}) => {
    const [page, setPage] = useState(0)
    if (results.length === 0) {
        return null;
    }
    const newer = results[page]
    const older = results[page + 1];
    return <>
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography level={"body-sm"}><LocalDateTime epochSeconds={older?.createdAt?.seconds}/></Typography>
            <ChangesPagination results={results.length} currentPage={page} setPage={setPage}/>
            <Typography level={"body-sm"}><LocalDateTime epochSeconds={newer.createdAt?.seconds}/></Typography>
        </Box>
        <Box sx={{display: "flex", gap: 1}}>
            <Box sx={{width: 0.5}}>
                <WebshopDetailsDiff current={older} to={newer}/>
            </Box>
            <Box sx={{width: 0.5}}>
                <WebshopDetailsDiff current={newer} to={older}/>
            </Box>
        </Box>
    </>;
};

const SalesApiScrapeResultsHistory = ({results}) => {
    const [page, setPage] = useState(0)
    if (results.length === 0) {
        return null;
    }
    const newer = results[page]
    const older = results[page + 1];
    return <>
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography level={"body-sm"}><LocalDateTime epochSeconds={older?.createdAt?.seconds}/></Typography>
            <ChangesPagination results={results.length} currentPage={page} setPage={setPage}/>
            <Typography level={"body-sm"}><LocalDateTime epochSeconds={newer.createdAt?.seconds}/></Typography>
        </Box>
        <Box sx={{display: "flex", gap: 1}}>
            <Box sx={{width: 0.5}}>
                <SalesApiDetailsDiff current={older} to={newer}/>
            </Box>
            <Box sx={{width: 0.5}}>
                <SalesApiDetailsDiff current={newer} to={older}/>
            </Box>
        </Box>
    </>;
};

const HealthChecksHistory = ({expectation}) => {
    const [page, setPage] = useState(0)
    if (!expectation || !expectation.enabled || expectation?.checkResults?.length === 0) {
        return null;
    }
    const results = expectation.checkResults
    const newer = results[page]
    const older = results[page + 1];
    return <>
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography level={"body-sm"}><LocalDateTime epochSeconds={older?.createdAt?.seconds}/></Typography>
            <ChangesPagination results={results.length} currentPage={page} setPage={setPage}/>
            <Typography level={"body-sm"}><LocalDateTime epochSeconds={newer.createdAt?.seconds}/></Typography>
        </Box>
        <Typography level={'body'}
                    component={'h3'}>that {`${expectation.attribute} ${expectation.matcher} ${expectation.value}`}</Typography>
        <Box sx={{display: "flex", gap: 1}}>
            <Box sx={{width: 0.5}}>
                <HealthChecksDiff current={older} to={newer}/>
            </Box>
            <Box sx={{width: 0.5}}>
                <HealthChecksDiff current={newer} to={older}/>
            </Box>
        </Box>
    </>;
};

const InspectProductUUID = ({id}) => {
    const {loading, error, data, refetch} = useQuery(product, {
        variables: {
            input: {productId: id}
        }
    });
    const {setError} = useContext(ErrorContext);
    useErrorNotification(error, setError)

    if (loading) return <Loading/>
    if (error) {
        return null;
    }

    const p = data.product.product;

    let webshopScrapeResults = p.webshopScrapeResults || [];
    const apiScrapeResults = p.salesProductsApiScrapeResults || [];
    const expectations = p.expectations || [];
    const possibleExpectations = [
        {
            attribute: "WEBSHOP_BUY_BUTTON",
            matcher: "IS_PRESENT",
            possibleValues: [true, false]
        },
        {
            attribute: "PRODUCT_ONLINE_STATUS",
            matcher: "IS",
            possibleValues: ["AVAILABLE", "TEMPORARILY_NOT_AVAILABLE"]
        }
    ];

    const PossibleExpectation = ({expectation}) => {
        const [addExpectationMutation, addExpectationMutationState] = useMutation(addExpectation);
        const [removeExpectationMutation, removeExpectationMutationState] = useMutation(removeExpectation);
        const [checkProductMutation, checkProductState] = useMutation(checkProduct);
        useErrorNotification(addExpectationMutationState.error, setError)
        useErrorNotification(removeExpectationMutationState.error, setError)
        const anyOneLoading = addExpectationMutationState.loading || removeExpectationMutationState.loading || checkProductState.loading
        const existingExpectationOnProduct = expectations.find(e => e.attribute === expectation.attribute && e.enabled);
        const initalValue = existingExpectationOnProduct?.value ?? ""
        const [value, setValue] = useState(initalValue)
        return <>
            <Box>
                <form onSubmit={e => {
                    e.preventDefault();
                    if (!!existingExpectationOnProduct) {
                        removeExpectationMutation({
                            variables: {
                                input: {
                                    expectationId: existingExpectationOnProduct.id
                                }
                            }
                        }).then(() => refetch())
                    } else {
                        addExpectationMutation({
                            variables: {
                                input: {
                                    productId: p.id,
                                    attribute: expectation.attribute,
                                    matcher: expectation.matcher,
                                    value: value
                                }
                            }
                        }).then(() => {
                            checkProductMutation({
                                variables: {
                                    input: {
                                        productId: p.id
                                    }
                                }
                            })
                                .catch(() => refetch())
                                .then(() => refetch())
                        })
                            .catch(() => refetch())
                    }
                }}>
                    <FormControl orientation={"horizontal"}>
                        <FormLabel>{expectation.attribute} {expectation.matcher}</FormLabel>
                        <NativeSelect disabled={!!existingExpectationOnProduct} required value={value}
                                      onChange={(e) => {
                                          const value = e.target.value;
                                          setValue(value);
                                      }}>
                            <option value=""></option>
                            {expectation.possibleValues.map(v => <React.Fragment key={JSON.stringify(v)}>
                                <option value={v}>{JSON.stringify(v)}</option>
                            </React.Fragment>)}
                        </NativeSelect>
                        {anyOneLoading ? <Loading/> : <Button type={"submit"}>{existingExpectationOnProduct ? "disable" : "enable"}</Button>}
                    </FormControl>
                </form>
            </Box>
        </>;
    };

    const ConfigureChecks = () => <Card>
        <Typography level={"h2"}>Configure Checks</Typography>
        {possibleExpectations.map(it => <PossibleExpectation key={it.attribute + it.matcher} expectation={it}/>)}
    </Card>;

    const CheckResults = () => <Card>
        <Typography level={"h2"}>Checks</Typography>
        {expectations.map(it =>
            <React.Fragment key={it.attribute+it.matcher+it.value}>
                <HealthChecksHistory expectation={it}/>
            </React.Fragment>)
        }
    </Card>;

    const WebshopScrapeResults = () => <Card>
        <Typography level={"h2"}>Webshop Scrape Results</Typography>
        <WebshopScrapeResultsHistory results={webshopScrapeResults}/>
    </Card>;

    const SalesProductsApiScrapeResult = () => <Card>
        <Typography level={"h2"}>Sales API Scrape Results</Typography>
        <SalesApiScrapeResultsHistory results={apiScrapeResults}/>
    </Card>;

    return <>
        <Typography level={"h1"}>{p.env} {p.country} {p.foreignId} {p.salesLine} </Typography>
        <ConfigureChecks/>
        <CheckResults/>
        <WebshopScrapeResults/>
        <SalesProductsApiScrapeResult/>
    </>
}

const InspectId = () => {
    let {id} = useParams();
    if (!id) return <EnterId/>


    return <>
        <InspectProductUUID id={id}/>
    </>;
};

export default InspectId