import React, {useContext, useEffect, useState} from 'react';
import {IS_STAGE_LOCAL} from "../constant/stage";
import {ErrorContext} from "./ErrorContext";
import {useLocation, useNavigate} from "react-router-dom";
import SERVICE_URL from "../constant/serviceUrl";
import {track} from "../api/http";

export const AuthContext = React.createContext(undefined);

const mockUser = {
    "username": "localMockUser",
    "isAdmin": true
};

function AuthContextBoundary({children}) {
    const [user, setUser] = useState(IS_STAGE_LOCAL ? mockUser : null)
    const [tracked, setTracked] = useState(false)
    const {setError} = useContext(ErrorContext);
    const location = useLocation();
    const {pathname, search} = location;
    const target = pathname + search;
    const navigate = useNavigate();
    useEffect(() => {
        const fetchAuthElseLoginRememberingCurrentPath = () => {
            fetch(SERVICE_URL + '/auth', {credentials: "same-origin"})
                .catch(err => {
                    err.message += " " + SERVICE_URL + '/auth'
                    return setError(new Error(err));
                })
                .then(response => {
                    if (response.status !== 200) {
                        localStorage.setItem("targetUrl", target)
                        window.location.replace(SERVICE_URL + '/login')
                    }
                    return response.json()
                })
                .then(data => {
                    if (!data.authenticated) {
                        localStorage.setItem("targetUrl", target)
                        window.location.replace(SERVICE_URL + '/login')
                    }
                }).then(() => fetch(SERVICE_URL + '/auth/user', {credentials: "same-origin"})
                .then(response => {
                    if (response.status !== 200) {
                        localStorage.setItem("targetUrl", target)
                        window.location.replace(SERVICE_URL + '/login')
                    }
                    return response.json()
                })
                .then(data => {
                    setUser({
                        username: data.username
                    })
                    const targetUrl = localStorage.getItem("targetUrl")
                    if (targetUrl != null) {
                        localStorage.removeItem("targetUrl")
                        navigate(targetUrl)
                    }
                }))
        };

        if (!!user) {
            if (!IS_STAGE_LOCAL) {
                const interval = setInterval(() => {
                    fetchAuthElseLoginRememberingCurrentPath();
                }, 1000 * 60 * 5);
                return () => clearInterval(interval);
            }
        } else {
            fetchAuthElseLoginRememberingCurrentPath();
        }
    }, [navigate, target, setError, user, setUser]);

    useEffect(() => {
        if (!user) return
        if (user.isAdmin !== undefined) return
        fetch(SERVICE_URL + '/auth/isAdmin', {credentials: "same-origin"})
            .catch(err => {
                err.message += " " + SERVICE_URL + '/auth'
                return setError(new Error(err));
            })
            .then(response => {
                if (response.status === 200) {
                    setUser(Object.assign({isAdmin: true}, user))
                } else {
                    setUser(Object.assign({isAdmin: false}, user))
                }
            })
    }, [setError, user, setUser]);


    useEffect(() => {
        if (!user) return
        if (tracked) return
        if (user.isAdmin === undefined) return
        track("access", [`user=${user.username}`, `role=${user.isAdmin ? "admin" : "user"}`, `location=${target}`])
        setTracked(true)
    }, [user, setTracked, tracked, target]);

    const setAdmin = b => {
        let newUser = Object.assign({}, user);
        newUser.isAdmin = b
        setUser(newUser)
    }
    return <AuthContext.Provider value={{user, setAdmin}}>{children}</AuthContext.Provider>;
}

export default AuthContextBoundary;
