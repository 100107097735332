import RequestFor from "../molecule/formControl/RequestFor";
import React, {useContext, useRef, useState} from "react";
import ProductName from "../molecule/formControl/ProductName";
import Initiative from "../molecule/formControl/Initiative";
import {Box, Button, FormControl, FormHelperText, FormLabel, IconButton, Input, Textarea, Typography} from "@mui/joy";
import {MissingTeamOrInitiativeCta} from "../molecule/MissingTeamOrInitiativeCta";
import {ErrorContext} from "../../context/ErrorContext";
import {useMutation, useQuery} from "@apollo/client";
import {createJiraIssue} from "../../api/mutation";
import {jiraInitiatives} from "../../api/query";
import {useErrorNotification} from "../../api/apolloClient";
import Loading from "../atom/Loading";
import Stage from "../molecule/formControl/Stage";
import Country from "../molecule/formControl/Country";
import Prio from "../molecule/formControl/Prio";
import DueDate from "../molecule/formControl/DueDate";
import SalesLine from "../molecule/formControl/SalesLine";
import {AddCircle, Close} from "@mui/icons-material";
import {SuccessContext} from "../../context/SuccessContext";

const ChangeTestProduct = () => {
    const [requestFor, setRequestFor] = useState("")
    const [requestingParty, setRequestingParty] = useState("")
    const [team, setTeam] = useState("")
    const [initiative, setInitiative] = useState("")
    const [initiativeInputValue, setInitiativeInputValue] = useState("");
    const [country, setCountry] = useState("");
    const [stage, setStage] = useState("");
    const [salesLine, setSalesLine] = useState("MM");
    let now = new Date();
    now.setDate(now.getDate() + 14)
    const [dueDate, setDueDate] = useState(now.toISOString().split('T')[0])
    const [prio, setPrio] = useState("Trivial")
    const [ids, setIds] = useState([""])
    const [furtherDetails, setFurtherDetails] = useState([""])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const {setError} = useContext(ErrorContext);
    const {setSuccess} = useContext(SuccessContext);
    const [createJiraIssueMutation] = useMutation(createJiraIssue);
    const jiraInitiativesQuery = useQuery(jiraInitiatives, {notifyOnNetworkStatusChange: true})

    useErrorNotification(jiraInitiativesQuery.error, setError)

    const formRef = useRef(null);

    if (jiraInitiativesQuery.loading) return <Loading/>
    if (!jiraInitiativesQuery.data?.jiraInitiatives) return null
    const initiatives = jiraInitiativesQuery.data.jiraInitiatives.initiatives.map(it => `${it.key} ${it.summary}`);

    function handleSubmit(e) {
        e.preventDefault();
        if (isSubmitting) return
        setIsSubmitting(true);
        createJiraIssueMutation({
            notifyOnNetworkStatusChange: true, variables: {
                input: {
                    summary: `Change [${stage}] [${country}] [${salesLine}] for ${requestingParty}`,
                    description: `*${stage}-${country}-${salesLine}*\n` + ids.map((_, i) => `| ${ids[i]} | ${furtherDetails[i]} |`).join("\n"),
                    relatesToInitiativeKey: initiative ? initiative.split(" ")[0] : null,
                    dueDate,
                    prio,
                    service: "CHANGE_ARTICLE"
                }
            }
        })
            .catch(err => {
                setIsSubmitting(false);
                return setError(err);
            })
            .then(res => {
                setIsSubmitting(false);
                setSuccess(res.data.createJiraIssue.createdIssue.key);
            })
    }

    const add = () => {
        setFurtherDetails((previous) => [...previous, ""])
        setIds((previous) => [...previous, ""])
    };

    function deleteIndex(i) {
        return (previous) => {
            let newValue = [...previous];
            newValue.splice(i, 1)
            return newValue;
        };
    }

    const remove = (i) => {
        if (ids.length === 1) {
            setFurtherDetails([""])
            setIds([""])
            return
        }
        setFurtherDetails(deleteIndex(i))
        setIds(deleteIndex(i))
    };

    function updateIndex(i, e) {
        return (previous) => {
            let newValue = [...previous];
            newValue[i] = e.target.value
            return newValue;
        };
    }

    return <>
        <Typography level={"h2"}>Change Test Product</Typography>
        <form ref={formRef} onSubmit={handleSubmit}>
            <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                <Box sx={{display: "flex", gap: 1, flexDirection: "column"}}>
                    <RequestFor value={requestFor} onChange={(e) => {
                        let v = e.target.value;
                        setRequestFor(v);
                        if (v === "country") setRequestingParty("country")
                    }}/>

                    {requestFor === "team" && <ProductName value={team} onChange={(event, newValue) => {
                        setTeam(newValue);
                        setRequestingParty(newValue)
                    }}/>}
                    {requestFor === "initiative" && <Initiative
                        value={initiative}
                        onChange={(event, newValue) => {
                            setInitiative(newValue);
                            setRequestingParty(newValue)
                        }}
                        inputValue={initiativeInputValue}
                        onInputChange={(event, newInputValue) => {
                            setInitiativeInputValue(newInputValue);
                        }}
                        options={initiatives}/>}
                    <FormHelperText>We will prioritize your request based on this quarters business
                        priorities.</FormHelperText>
                    <MissingTeamOrInitiativeCta/>
                </Box>
                <Box sx={{display: "flex", flexWrap: "wrap", gap: 1}}>
                    <Prio value={prio} onChange={(e) => setPrio(e.target.value)}/>
                    <DueDate value={dueDate} onChange={e => {
                        setDueDate(e.target.value);
                    }}/>
                </Box>
                <Box
                    sx={{display: "flex", flexWrap: "wrap", gap: 1}}>
                    <Stage value={stage} onChange={(e) => {
                        setStage(e.target.value);
                    }}/>
                    <Country value={country} onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "DE") {
                            setSalesLine(["MM"])
                        }
                        setCountry(value);
                    }}/>

                    {country === "DE" && <><SalesLine value={salesLine}
                                                      onChange={(e) => setSalesLine(e.target.value)}/></>}
                </Box>


                {ids.map((_, i) => <Box  key={i} sx={{display: "flex", gap: 1}}>
                    <FormControl>
                        <FormLabel required>Product ID</FormLabel>
                        <Input required value={ids[i]} placeholder={"1249846"}
                               onChange={e => {
                                   setIds(updateIndex(i, e));
                               }}/>
                    </FormControl>
                    <FormControl sx={{flexGrow: 1}}>
                        <FormLabel required>What kind of change do you need?</FormLabel>
                        <Textarea required minRows={3} value={furtherDetails[i]}
                                  onChange={e => {
                                      setFurtherDetails(updateIndex(i, e));
                                  }}
                                  placeholder={i === 0 ? "Please change name, category, price, stock, FSK,\npre-order, promotion, bundle, lead time, ....": null}/>
                    </FormControl>
                    <IconButton color={"primary"} onClick={add}><AddCircle/></IconButton>
                    <IconButton color={"danger"} onClick={() => remove(i)}><Close/></IconButton>
                </Box>)}
                <Button disabled={isSubmitting}
                        endDecorator={isSubmitting ? <Typography fontSize="xx-small"><Loading/></Typography> : null}
                        type={"submit"}>Submit {ids.length > 1 ? " " + (ids.length) : ""}</Button>
            </Box>
        </form>
    </>
}

export default ChangeTestProduct