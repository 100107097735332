import './App.css';
import {Box} from "@mui/joy";
import LeftMenu from "./component/organism/LeftMenu";
import Page from "./component/page";
import Loading from "./component/atom/Loading";
import React, {useContext, useEffect} from "react";
import {AuthContext} from "./context/AuthContext";
import MainContent from "./component/organism/MainContent";
import {useLocation} from "react-router-dom";
import {trackNavigate} from "./api/http";

const App = () => {
    const {user} = useContext(AuthContext)
    const location = useLocation();

    const pathname = location?.pathname;
    useEffect(() => {
        if (!user) return;
        if (!pathname) return;
        trackNavigate(user, pathname);
    }, [pathname, user])

    if (!user) return <Box role={"main"}><Loading/></Box>
    return <>
        <Page leftNav={<LeftMenu/>}>{<MainContent/>}</Page>
    </>;
};

export default App;
