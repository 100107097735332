import RequestFor from "../molecule/formControl/RequestFor";
import React, {useContext, useRef, useState} from "react";
import ProductName from "../molecule/formControl/ProductName";
import Initiative from "../molecule/formControl/Initiative";
import {Box, Button, Checkbox, FormControl, FormHelperText, FormLabel, Input, Textarea, Typography} from "@mui/joy";
import {MissingTeamOrInitiativeCta} from "../molecule/MissingTeamOrInitiativeCta";
import {ErrorContext} from "../../context/ErrorContext";
import {useMutation, useQuery} from "@apollo/client";
import {createJiraIssue} from "../../api/mutation";
import {jiraInitiatives} from "../../api/query";
import {useErrorNotification} from "../../api/apolloClient";
import Loading from "../atom/Loading";
import Stage from "../molecule/formControl/Stage";
import Country from "../molecule/formControl/Country";
import Prio from "../molecule/formControl/Prio";
import DueDate from "../molecule/formControl/DueDate";
import SalesLine from "../molecule/formControl/SalesLine";
import {SuccessContext} from "../../context/SuccessContext";
import {NativeSelect} from "@mui/material";
import MultipleProductIds from "../molecule/formControl/MultipleProductIds";
import {LinkTargetBlank} from "../atom/LinkTartgetBlank";

const ProCo = () => {
    const [requestFor, setRequestFor] = useState("")
    const [requestingParty, setRequestingParty] = useState("")
    const [team, setTeam] = useState("")
    const [initiative, setInitiative] = useState("")
    const [initiativeInputValue, setInitiativeInputValue] = useState("");
    const [country, setCountry] = useState("");
    const [stage, setStage] = useState("");
    const [salesLine, setSalesLine] = useState("MM");
    const [type, setType] = useState("COUPON");
    const [ids, setIds] = useState("");
    const [discountType, setDiscountType] = useState("FIXED_AMOUNT");
    const [discountValue, setDiscountValue] = useState("");
    const [special, setSpecial] = useState("");
    const [loyalty, setLoyalty] = useState(false);
    let now = new Date();
    now.setDate(now.getDate() + 14)
    const [dueDate, setDueDate] = useState(now.toISOString().split('T')[0])
    const [prio, setPrio] = useState("Trivial")
    const [furtherDetails, setFurtherDetails] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)

    const {setError} = useContext(ErrorContext);
    const {setSuccess} = useContext(SuccessContext);
    const [createJiraIssueMutation] = useMutation(createJiraIssue);
    const jiraInitiativesQuery = useQuery(jiraInitiatives, {notifyOnNetworkStatusChange: true})

    useErrorNotification(jiraInitiativesQuery.error, setError)

    const formRef = useRef(null);

    if (jiraInitiativesQuery.loading) return <Loading/>
    if (!jiraInitiativesQuery.data?.jiraInitiatives) return null
    const initiatives = jiraInitiativesQuery.data.jiraInitiatives.initiatives.map(it => `${it.key} ${it.summary}`);

    function handleSubmit(e) {
        e.preventDefault();
        if (isSubmitting) return
        setIsSubmitting(true);
        const formValues = {type, ids: ids.replaceAll("\n", "; "), loyalty, special: special? "free shipping": "", discountType, discountValue, furtherDetails: furtherDetails.replaceAll("\n", "; ")};
        createJiraIssueMutation({
            notifyOnNetworkStatusChange: true, variables: {
                input: {
                    summary: `[${stage}] [${country}][${salesLine}] Promotions/Coupons for ${requestingParty}`,
                    description: `${Object.keys(formValues).map(it => `| ${it}| ${formValues[it]}|`).join("\n")}`,
                    relatesToInitiativeKey: initiative ? initiative.split(" ")[0] : null,
                    dueDate,
                    prio,
                    service: "CREATE_PROMOTIONS_AND_COUPONS"
                }
            }
        })
            .catch(err => {
                setIsSubmitting(false);
                return setError(err);
            })
            .then(res => {
                setIsSubmitting(false);
                setSuccess(res.data.createJiraIssue.createdIssue.key);
            })
    }

    return <>
        <Typography level={"h2"}>Promotions And Coupons</Typography>
        <form ref={formRef} onSubmit={handleSubmit}>
            <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                <Box sx={{display: "flex", gap: 1, flexDirection: "column"}}>
                    <RequestFor value={requestFor} onChange={(e) => {
                        let v = e.target.value;
                        setRequestFor(v);
                        if (v === "country") setRequestingParty("country")
                    }}/>

                    {requestFor === "team" && <ProductName value={team} onChange={(event, newValue) => {
                        setTeam(newValue);
                        setRequestingParty(newValue)
                    }}/>}
                    {requestFor === "initiative" && <Initiative
                        value={initiative}
                        onChange={(event, newValue) => {
                            setInitiative(newValue);
                            setRequestingParty(newValue)
                        }}
                        inputValue={initiativeInputValue}
                        onInputChange={(event, newInputValue) => {
                            setInitiativeInputValue(newInputValue);
                        }}
                        options={initiatives}/>}
                    <FormHelperText>We will prioritize your request based on this quarters business
                        priorities.</FormHelperText>
                    <MissingTeamOrInitiativeCta/>
                </Box>
                <Box sx={{display: "flex", flexWrap: "wrap", gap: 1}}>
                    <Prio value={prio} onChange={(e) => setPrio(e.target.value)}/>
                    <DueDate value={dueDate} onChange={e => {
                        setDueDate(e.target.value);
                    }}/>
                </Box>
                <Box
                    sx={{display: "flex", flexWrap: "wrap", gap: 1}}>
                    <Stage value={stage} onChange={(e) => {
                        setStage(e.target.value);
                    }}/>
                    <Country value={country} onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "DE") {
                            setSalesLine(["MM"])
                        }
                        setCountry(value);
                    }}/>

                    {country === "DE" && <><SalesLine value={salesLine}
                                                      onChange={(e) => setSalesLine(e.target.value)}/></>}
                    <FormHelperText>It's easier to manage for us when requests are only covering one env/country
                        combination. If you need more variants, just submit this form, then change the values and submit
                        again.</FormHelperText>
                </Box>
                <Box sx={{display: "flex", gap: 3, alignItems: "flex-end", flexWrap: "wrap"}}>
                    <FormControl orientation={"horizontal"}>
                        <FormLabel required>Type</FormLabel>
                        <NativeSelect required value={type} variant={"filled"}
                                      onChange={e => {
                                          setType(e.target.value);
                                          setIds("")
                                      }}>
                            <option value="COUPON">Coupon</option>
                            <option value="BASKET">Apply At Basket</option>
                        </NativeSelect>
                    </FormControl>
                    <MultipleProductIds required={type === "BASKET"} value={ids} onChange={e => setIds(e.target.value)}/>
                    <FormControl>
                        <FormLabel>
                            Loyalty
                        </FormLabel>
                        <Checkbox checked={loyalty}
                                  onChange={() => setLoyalty((previous) => !previous)}
                                  label={"only for loyalty members"}/>

                    </FormControl>
                    <FormControl orientation={"horizontal"}>
                        <FormLabel>Special Effect</FormLabel>
                        <Checkbox checked={special}
                                  onChange={() => setSpecial((previous) => !previous)}
                                  label={"Free Shipping"}/>

                    </FormControl>
                </Box>
                <Box sx={{display: "flex", gap: 3, flexWrap: "wrap"}}>
                    <FormControl orientation={"horizontal"}>
                        <FormLabel required={!special}>Discount Type</FormLabel>
                        <NativeSelect required={!special} value={discountType} variant={"filled"}
                                      onChange={e => setDiscountType(e.target.value)}>
                            <option value="FIXED_AMOUNT">Fixed Amount</option>
                            <option value="PERCENTAGE">Percentage</option>
                            <option value="FIXED_PRICE">Fixed Price</option>
                        </NativeSelect>
                    </FormControl>
                    <FormControl orientation={"horizontal"}>
                        <FormLabel required={!special}>Value</FormLabel>
                        <Input required={!special} variant={"soft"} value={discountValue} placeholder={"22.25€, 17.3%, ..."}
                               onChange={e => setDiscountValue(e.target.value)}/>
                    </FormControl>
                </Box>
                <Box sx={{display: "flex", gap: 3}}>
                    <FormControl>
                        <FormLabel>Other details</FormLabel>
                        <Textarea minRows={3} value={furtherDetails}
                                  onChange={e => setFurtherDetails(e.target.value)}
                                  placeholder={"one time/per custumer\nredemption limits\ncan combine with others, ..."}/>
                        <FormHelperText>Are you missing specific options or fields?
                            <Box p={0.2}/>
                            <Typography level={"body-sm"}>
                                <LinkTargetBlank
                                    href={"https://rkt.mediamarktsaturn.com/channel/test-data-solutions"}>Let us know!</LinkTargetBlank>
                            </Typography></FormHelperText>
                    </FormControl>
                </Box>

                <Button disabled={isSubmitting}
                        endDecorator={isSubmitting ? <Typography fontSize="xx-small"><Loading/></Typography> : null}
                        type={"submit"}>Submit</Button>
            </Box>
        </form>
    </>
}

export default ProCo