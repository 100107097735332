import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './normalize.css';
import '@fontsource/public-sans';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {HashRouter} from "react-router-dom";
import ErrorDisplayBoundary from "./context/ErrorContext";
import {ApolloProvider} from "@apollo/client";
import apolloClient from "./api/apolloClient";
import AuthContextBoundary from "./context/AuthContext";
import FeatureToggleContextBoundary from "./context/FeatureToggleContext";
import BackendIssue from "./component/molecule/BackendIssue";
import ReleaseNotesReadBoundary from "./context/ReleaseNotesReadContext";
import SuccessBoundary from "./context/SuccessContext";

const obsoleteUrl = window.location.href.includes("test-data-solutions")
if (obsoleteUrl) {
    window.location = window.location.href.replace("solutions", "navigator")
}

const backendDown = false;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
    {backendDown && <BackendIssue/>}
    {!backendDown && <ApolloProvider client={apolloClient}>
        <HashRouter>
            <SuccessBoundary>
                <ErrorDisplayBoundary>
                    <AuthContextBoundary>
                        <FeatureToggleContextBoundary>
                            <ReleaseNotesReadBoundary>
                                <App/>
                            </ReleaseNotesReadBoundary>
                        </FeatureToggleContextBoundary>
                    </AuthContextBoundary>
                </ErrorDisplayBoundary>
            </SuccessBoundary>
        </HashRouter>
    </ApolloProvider>}
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
