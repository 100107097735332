import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from "@apollo/client";
import { catalog } from "../api/query";
import { AuthContext } from "./AuthContext";

export const CatalogContext = React.createContext(undefined);

function CatalogContextBoundary({ children }) {
    const query = useQuery(catalog)
    const { loading, data } = query;
    const { user } = useContext(AuthContext)
    const [products, setProducts] = useState([])

    useEffect(() => {
        if (loading) return
        const queriedProducts = data?.catalog?.products;
        setProducts(queriedProducts);

    }, [loading, data, user.isAdmin])

    const remove = (id) => {
        setProducts([...products.filter(it => it.id !== id)])
    }
    return <CatalogContext.Provider value={{ products, query, remove }}>{children}</CatalogContext.Provider>;
}

export default CatalogContextBoundary;
