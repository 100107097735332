import {FormControl, FormLabel} from "@mui/joy";
import {NativeSelect} from "@mui/material";
import React from "react";

const RequestFor = ({value, onChange}) => <FormControl orientation={"horizontal"}>
    <FormLabel required>Request for</FormLabel>
    <NativeSelect required value={value} variant={"filled"}
                  onChange={onChange}>
        <option value="">please select</option>
        <option value={"team"}>Team</option>
        <option value={"initiative"}>Initiative</option>
        <option value={"country"}>Country</option>
    </NativeSelect>
</FormControl>;

export default RequestFor