import React from 'react';

import {useLocalStorage} from "@uidotdev/usehooks";

export const FeatureToggleContext = React.createContext(undefined);

function FeatureToggleContextBoundary({children}) {
    const [edi, setEdi] = useLocalStorage("edi", false)

    return <FeatureToggleContext.Provider
        value={{edi, setEdi}}>{children}</FeatureToggleContext.Provider>;
}

export default FeatureToggleContextBoundary;
