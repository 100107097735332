import {Comparing} from "./Comparing";
import {Typography} from "@mui/joy";
import React from "react";

export const WebshopDetailsDiff = ({current, to}) => {
    return <>
        {current && <>
            <dl>
                <Comparing current={current} to={to} on={"url"}/>
                <Comparing current={current} to={to} on={"httpStatusCode"}/>
                <Comparing current={current} to={to} on={"title"}/>
                <Comparing current={current} to={to} on={"buyButtonEnabled"}/>
                <Comparing current={current} to={to} on={"hasServices"}/>
                <Comparing current={current} to={to} on={"hasOneTimePaymentWarranties"}/>
                <Comparing current={current} to={to} on={"hasMonthlyPaymentWarranties"}/>
                <Comparing current={current} to={to} on={"marketplaceSeller"}/>
            </dl>
        </>}
        {current?.ctaDebug && <><Typography level={"body-sm"} component={"h3"}>Cta Debug</Typography>
            <dl>
                <Comparing current={current.ctaDebug} to={to?.ctaDebug} on={"price"}/>
                <Comparing current={current.ctaDebug} to={to?.ctaDebug} on={"delivery"}/>
                <Comparing current={current.ctaDebug} to={to?.ctaDebug} on={"isInAssortment"}/>
                <Comparing current={current.ctaDebug} to={to?.ctaDebug} on={"lifecycleStatus"}/>
                <Comparing current={current.ctaDebug} to={to?.ctaDebug} on={"onlineReleasedAt"}/>
                <Comparing current={current.ctaDebug} to={to?.ctaDebug} on={"isForcedOnline"}/>
                <Comparing current={current.ctaDebug} to={to?.ctaDebug} on={"productOnlineStatus"}/>
                <Comparing current={current.ctaDebug} to={to?.ctaDebug} on={"crossAvailabilityStatus"}/>
                <Comparing current={current.ctaDebug} to={to?.ctaDebug} on={"aggregatedOnlineStatus"}/>
                <Comparing current={current.ctaDebug} to={to?.ctaDebug} on={"crossLinkUrl"}/>
                <Comparing current={current.ctaDebug} to={to?.ctaDebug} on={"logisticsClass"}/>
                <Comparing current={current.ctaDebug} to={to?.ctaDebug} on={"isDigital"}/>
            </dl>
        </>}
    </>;
};