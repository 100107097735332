import SERVICE_URL from "../constant/serviceUrl";

/**
 *
 * @param {string }type
 * @param {string[]}data
 * @returns {Promise<Response>}
 */
export const track = (type, data) => fetch(SERVICE_URL + '/track', {
    credentials: "same-origin",
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({type, data})
})

export const trackNavigate = (user, route) => track("navigate", [`user=${user?.username}`, `role=${user?.isAdmin ? "admin" : "user"}`, `target=${route}`]);