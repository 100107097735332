import {Comparing} from "./Comparing";
import React from "react";

export const SalesApiDetailsDiff = ({current, to}) => {
    return <>
        {current && <>
            <dl>
                <Comparing current={current} to={to} on={"httpStatusCode"}/>
                <Comparing current={current} to={to} on={"kindOfProduct"}/>
                <Comparing current={current} to={to} on={"logisticsClass"}/>
                <Comparing current={current} to={to} on={"lifeCycleStatus"}/>
                <Comparing current={current} to={to} on={"basicName"}/>
                <Comparing current={current.metaData?.createdAtInMasterData} to={to?.metaData?.createdAtInMasterData}
                           on={"seconds"} labeled={"created in masterdata"} as={"LocalDateTime"}/>
                <Comparing current={current.dimensions} to={to?.dimensions} on={"widthMeters"}
                           labeled={"dimensions: width (m)"}/>
                <Comparing current={current.dimensions} to={to?.dimensions} on={"heightMeters"}
                           labeled={"dimensions: height (m)"}/>
                <Comparing current={current.dimensions} to={to?.dimensions} on={"depthMeters"}
                           labeled={"dimensions: depth (m)"}/>
                <Comparing current={current.dimensions} to={to?.dimensions} on={"weightGram"}
                           labeled={"dimensions: weight (g)"}/>
                <Comparing current={current.dimensionsPredicted} to={to?.dimensionsPredicted} on={"widthMeters"}
                           labeled={"dimensions predicted: width (m)"}/>
                <Comparing current={current.dimensionsPredicted} to={to?.dimensionsPredicted} on={"heightMeters"}
                           labeled={"dimensions predicted: height (m)"}/>
                <Comparing current={current.dimensionsPredicted} to={to?.dimensionsPredicted} on={"depthMeters"}
                           labeled={"dimensions predicted: depth (m)"}/>
                <Comparing current={current.dimensionsPredicted} to={to?.dimensionsPredicted} on={"weightGram"}
                           labeled={"dimensions predicted: weight (g)"}/>
                <Comparing current={current.packageDimensions} to={to?.packageDimensions} on={"widthMeters"}
                           labeled={"package dimensions: width (m)"}/>
                <Comparing current={current.packageDimensions} to={to?.packageDimensions} on={"heightMeters"}
                           labeled={"package dimensions: height (m)"}/>
                <Comparing current={current.packageDimensions} to={to?.packageDimensions} on={"depthMeters"}
                           labeled={"package dimensions: depth (m)"}/>
                <Comparing current={current.packageDimensions} to={to?.packageDimensions} on={"weightGram"}
                           labeled={"package dimensions: weight (g)"}/>
                <Comparing current={current.packageDimensionsPredicted} to={to?.packageDimensionsPredicted} on={"widthMeters"}
                           labeled={"package dimensions predicted: width (m)"}/>
                <Comparing current={current.packageDimensionsPredicted} to={to?.packageDimensionsPredicted} on={"heightMeters"}
                           labeled={"package dimensions predicted: height (m)"}/>
                <Comparing current={current.packageDimensionsPredicted} to={to?.packageDimensionsPredicted} on={"depthMeters"}
                           labeled={"package dimensions predicted: depth (m)"}/>
                <Comparing current={current.packageDimensionsPredicted} to={to?.packageDimensionsPredicted} on={"weightGram"}
                           labeled={"package dimensions predicted: weight (g)"}/>
            </dl>
        </>}
    </>;
};