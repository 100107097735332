import {List, ListItem} from "@mui/joy";
import ProductCatalogListItem from "./ProductCatalogListItem";
import React from "react";

const ProductsList = ({products}) => {
    if (products.length === 0) {
        return null
    }

    return <List spacing={2} role={"list"}>
        {[].concat(products).map((product) => <ListItem
            key={product.id}><ProductCatalogListItem product={product}/></ListItem>)}
    </List>;
};

export default ProductsList