import {gql} from "@apollo/client";

export const product = gql`
    query product($input: ProductInput!) {
        product(input: $input) {
            product {
                id
                env
                foreignId
                country
                salesLine
                lastScrapedAt {
                    seconds
                }
                webshopScrapeResults {
                    id
                    buyButtonEnabled
                    httpStatusCode
                    title
                    url
                    createdAt {
                        seconds
                    }
                    hasOneTimePaymentWarranties
                    hasMonthlyPaymentWarranties
                    hasServices
                    ctaDebug {
                        price
                        delivery
                        pickup
                        isInAssortment
                        lifecycleStatus
                        onlineReleasedAt
                        isForcedOnline
                        productOnlineStatus
                        crossAvailabilityStatus
                        aggregatedOnlineStatus
                        crossLinkUrl
                        logisticsClass
                        isDigital
                    }
                    marketplaceSeller
                }
                salesProductsApiScrapeResults {
                    id
                    httpStatusCode
                    kindOfProduct
                    logisticsClass
                    lifeCycleStatus
                    basicName
                    createdAt {
                        seconds
                    }
                    metaData {
                        createdAtInMasterData {
                            seconds
                        }
                    }
                    dimensions {
                        widthMeters
                        heightMeters
                        depthMeters
                        weightGram
                    }
                    dimensionsPredicted {
                        widthMeters
                        heightMeters
                        depthMeters
                        weightGram
                    }
                    packageDimensions {
                        widthMeters
                        heightMeters
                        depthMeters
                        weightGram
                    }
                    packageDimensionsPredicted {
                        widthMeters
                        heightMeters
                        depthMeters
                        weightGram
                    }
                }
                expectations {
                    id
                    enabled
                    attribute
                    matcher
                    value
                    checkResults {
                        id
                        factId
                        createdAt {
                            seconds
                        }
                        status
                        message
                    }
                }
            }
        }
    }
`

export const catalog = gql`
    query catalog {
        catalog {
            products {
                id
                env
                foreignId
                country
                salesLine
                lastScrapedAt {
                    seconds
                }
                latestWebshopScrapeResult {
                    buyButtonEnabled
                    httpStatusCode
                    title
                    url
                    hasOneTimePaymentWarranties
                    hasMonthlyPaymentWarranties
                    hasServices
                    ctaDebug {
                        price
                        productOnlineStatus
                    }
                    marketplaceSeller
                }
                latestSalesProductsApiScrapeResult {
                    kindOfProduct
                    logisticsClass
                    basicName
                    dimensions {
                        widthMeters
                        heightMeters
                        depthMeters
                        weightGram
                    }
                    dimensionsPredicted {
                        widthMeters
                        heightMeters
                        depthMeters
                        weightGram
                    }
                    packageDimensions {
                        widthMeters
                        heightMeters
                        depthMeters
                        weightGram
                    }
                    packageDimensionsPredicted {
                        widthMeters
                        heightMeters
                        depthMeters
                        weightGram
                    }
                }
                expectations {
                    id
                    enabled
                }
                pickupFulfillmentIndications {
                    sameDayPickupSourceNodeIds
                    nextDayPickupSourceNodeIds
                }
                deliveryFulfillmentIndication {
                    earliest {
                        seconds
                    }
                    latest {
                        seconds
                    }
                    isFromWarehouse
                    isFromStore
                    fromSourceNode
                }
            }
        }
    }`;

export const latestHealthCheckResults = gql`
    query latestHealthCheckResults {
        latestHealthCheckResults {
            id
            factId
            productId
            createdAt {
                seconds
            }
            status
            attribute
            matcher
            value
            message
        }
    }`;

export const latestEnvHealthCheckResults = gql`
    query envHealthChecks {
        envHealthChecks {
            checks {
                id
                title
                description
                steps
                country
                stage
                path
            }
            latestCheckResults {
                id
                check {
                    id
                    title
                    description
                    steps
                    country
                    stage
                    path
                }
                steps {
                    id
                    step
                    status
                    updatedAt {
                        seconds
                    }
                    message
                }
                status
                updatedAt {
                    seconds
                }
            }
        }
    }`;


export const envHealthCheckResults = gql`
    query envHealthCheckResults ($input: EnvHealthCheckResultsInput!) {
        envHealthCheckResults (input: $input) {
            check {
                id
                title
                description
                steps
                country
                stage
                path
            }
            results {
                id
                check {
                    id
                    title
                    description
                    steps
                    country
                    stage
                    path
                }
                steps {
                    id
                    step
                    status
                    updatedAt {
                        seconds
                    }
                    message
                }
                status
                updatedAt {
                    seconds
                }
            }
        }
    }`;

export const productCreationSaga = gql`
    query productCreationSaga($input: ProductCreationSagaInput!) {
        productCreationSaga(input: $input) {
            saga {
                id
                user
                referenceProductId {
                    country
                    salesLine
                    stage
                    productId
                }
                team
                initiative
                status
                error
                targetProductId {
                    country
                    salesLine
                    stage
                    productId
                }
                createdAt {
                    seconds
                }
                updatedAt {
                    seconds
                }
                jiraKey
            }
        }
    }`

export const sourceNodes = gql`
    query sourceNodes {
        sourceNodes {
            sourceNodes {
                id
                foreignId
                name
                country
                type
                active
            }
        }
    }`;

export const salesProduct = gql`
    query salesProductApi($input: SalesProductApiInput!) {
        salesProductApi(input: $input) {
            productImageUrl
            name
            kindOfProduct
        }
    }`;


export const jiraInitiatives = gql`
    query jiraInitiatives {
        jiraInitiatives {
            initiatives {
                key
                summary
            }
        }
    }`;


export const findTestProductForReference = gql`
    query findTestProductForReference($input: FindTestProductForReferenceInput!) {
        findTestProductForReference(input: $input) {
            id
        }
    }`;

