import React, {useContext} from "react";
import {AuthContext} from "../../context/AuthContext";
import {Box, FormControl, FormHelperText, FormLabel, Switch, Typography} from "@mui/joy";
import {FeatureToggleContext} from "../../context/FeatureToggleContext";

const AdminToggles = () => {
    const {user, setAdmin} = useContext(AuthContext)
    const {edi, setEdi} = useContext(FeatureToggleContext)
    return <Box sx={{width: "100%"}}>
        <Typography level={"body-md"} component={"h2"}>Admin Toggles</Typography>
        <FormControl orientation={"horizontal"} sx={{alignItems: "flex-end"}}>
            <FormLabel>Role</FormLabel>
            <Switch
                checked={user.isAdmin}
                onChange={() => setAdmin(!user.isAdmin)}
                color={'neutral'}
                variant="outlined"
                startDecorator={<><FormHelperText>user</FormHelperText></>}
                endDecorator={<><FormHelperText>admin</FormHelperText></>}
            />
        </FormControl>
        {
            <FormControl orientation={"horizontal"} sx={{alignItems: "flex-end"}}>
                <FormLabel>edi</FormLabel>
                <Switch
                    aria-checked={edi}
                    checked={edi}
                    onChange={() => setEdi(!edi)}
                    color={'neutral'}
                    variant="outlined"
                    startDecorator={<><FormHelperText>off</FormHelperText></>}
                    endDecorator={<><FormHelperText>on</FormHelperText></>}
                />
            </FormControl>
        }
    </Box>;
};

export default AdminToggles