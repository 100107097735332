import {Autocomplete, FormControl, FormLabel} from "@mui/joy";
import React from "react";

const Initiative = ({options, onChange, value}) => <FormControl>
    <FormLabel required>Initiative</FormLabel>
    <Autocomplete
        required
        value={value}
        onChange={onChange}
        placeholder="INIT-444, myMM,...."
        options={options}
    />
</FormControl>;

export default Initiative