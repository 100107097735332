import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import SERVICE_URL from "../constant/serviceUrl";
import {useEffect} from "react";

const link = createHttpLink({
    uri: SERVICE_URL + '/graphql', credentials: 'same-origin'
});

export const useErrorNotification = (error, setError) => {
    return useEffect(() => {
        if (error) {
            setError(error)
        }
    }, [error, setError]);
}


const apolloClient = new ApolloClient({cache: new InMemoryCache(), link});
export default apolloClient