import {Route, Routes} from "react-router-dom";
import {Home} from "./Home";
import {
    ADMIN_CHECKS_HISTORY_PATH,
    ADMIN_INSPECT_ID_PATH,
    ADMIN_MANAGE_CATALOG_PATH,
    ADMIN_PATH, ADMIN_TRIGGER_EDI_FROM_REFERENCE,
    RELEASE_NOTES_PATH,
    SERVICE_ADD_TEST_PRODUCT_TO_SAP_PATH,
    SERVICE_CATALOG_PATH,
    SERVICE_CHANGE_TEST_PRODUCT_PATH,
    SERVICE_CHECK_ENV_PATH,
    SERVICE_CREATE_PROMOTIONS_AND_COUPONS_PATH,
    SERVICE_REQUEST_PRODUCTS_PATH,
    SERVICE_REQUEST_PRODUCTS_V2_PATH,
    SERVICE_STAND_BY_SUPPORT_PATH,
    SERVICES_PATH,
} from "../../constant/routes";
import CatalogContextBoundary from "../../context/CatalogContext";
import CatalogFilterContextBoundary from "../../context/CatalogFilterContext";
import BrowseCatalog from "./BrowseCatalog";
import ProductCreationQuestionnaire from "./ProductCreationQuestionnaire";
import {Typography} from "@mui/joy";
import Admin from "./Admin";
import ManageCatalog from "./ManageCatalog";
import InspectId from "./InspectId";
import ChecksHistory from "../molecule/ChecksHistory";
import React, {useContext} from "react";
import EnvironmentDashboard from "./EnvironmentDashboard";
import SourceNodesContextBoundary from "../../context/SourceNodesContext";
import RequestNewTestProduct from "./RequestNewTestProduct";
import TestProductRequest from "./TestProductRequest";
import Services from "./Services";
import ChangeTestProduct from "./ChangeTestProduct";
import AddTestProductToSap from "./AddTestProductToSap";
import ProCo from "./ProCo";
import StandBy from "./StandBy";
import ReleaseNotes from "./ReleaseNotes";
import {FeatureToggleContext} from "../../context/FeatureToggleContext";
import TriggerEdi from "./TriggerEdi";

const MainContent = () => {
    const {edi} = useContext(FeatureToggleContext)

    return <Routes>
        <Route index element={<Home/>}/>
        <Route path={SERVICES_PATH} element={<Services/>}/>
        <Route path={SERVICE_CATALOG_PATH} element={
            <CatalogContextBoundary>
                <SourceNodesContextBoundary>
                    <CatalogFilterContextBoundary>
                        <BrowseCatalog/>
                    </CatalogFilterContextBoundary>
                </SourceNodesContextBoundary>
            </CatalogContextBoundary>
        }/>
        <Route path={SERVICE_REQUEST_PRODUCTS_PATH} element={
            <SourceNodesContextBoundary>
                {edi || <ProductCreationQuestionnaire/>}
                {edi && <RequestNewTestProduct/>}
            </SourceNodesContextBoundary>}/>
        <Route path={SERVICE_REQUEST_PRODUCTS_V2_PATH} element={<RequestNewTestProduct/>}/>
        <Route path={SERVICE_REQUEST_PRODUCTS_V2_PATH + "/:id"} element={<TestProductRequest/>}/>
        <Route path={SERVICE_CHECK_ENV_PATH} element={<EnvironmentDashboard/>}/>
        <Route path={SERVICE_CHANGE_TEST_PRODUCT_PATH} element={<ChangeTestProduct/>}/>
        <Route path={SERVICE_ADD_TEST_PRODUCT_TO_SAP_PATH} element={<AddTestProductToSap/>}/>
        <Route path={SERVICE_CREATE_PROMOTIONS_AND_COUPONS_PATH} element={<ProCo/>}/>
        <Route path={SERVICE_STAND_BY_SUPPORT_PATH} element={<StandBy/>}/>
        <Route path={ADMIN_PATH} element={<Admin/>}/>
        <Route path={ADMIN_MANAGE_CATALOG_PATH} element={<CatalogContextBoundary><ManageCatalog/></CatalogContextBoundary>}/>
        <Route path={ADMIN_CHECKS_HISTORY_PATH} element={<ChecksHistory/>}/>
        <Route path={ADMIN_INSPECT_ID_PATH} element={<InspectId/>}/>
        <Route path={ADMIN_INSPECT_ID_PATH + "/:id"} element={<InspectId/>}/>
        <Route path={ADMIN_TRIGGER_EDI_FROM_REFERENCE} element={<TriggerEdi/>}/>
        <Route path={RELEASE_NOTES_PATH} element={<ReleaseNotes/>}/>

        <Route path={"*"} element={<Typography color={"danger"} level={"h1"}>404</Typography>}/>
    </Routes>;
};

export default MainContent