import {
    SERVICE_ADD_TEST_PRODUCT_TO_SAP_PATH,
    SERVICE_CATALOG_PATH,
    SERVICE_CHANGE_TEST_PRODUCT_PATH, SERVICE_CHECK_ENV_PATH, SERVICE_CREATE_PROMOTIONS_AND_COUPONS_PATH,
    SERVICE_REQUEST_PRODUCTS_PATH, SERVICE_STAND_BY_SUPPORT_PATH
} from "./routes";
import {AddBusiness, AddCard, Hail, ListAlt, LocalActivity, StackedBarChart, Tune} from "@mui/icons-material";
import Service from "../model/service";

export const SERVICE_REQUEST_TEST_PRODUCT = new Service("Request Test Product", "Create Test Product", SERVICE_REQUEST_PRODUCTS_PATH, [
    "Answer a few questions and then create the JIRA issue for the Test Data Team"
], <AddCard/>);
export const SERVICE_CATALOG = new Service("Browse Available Test Products", "Catalog", SERVICE_CATALOG_PATH, [
    "Why create new test products when there are already plenty to go around.",
    "Search for one that fits your needs"
], <ListAlt/>);
export const SERVICE_ADD_TO_SAP = new Service("Add Test Product To SAP", "Add To SAP", SERVICE_ADD_TEST_PRODUCT_TO_SAP_PATH, ["For when existing test products are missing in SAP"],
    <AddBusiness/>);
export const SERVICE_CHANGE_TEST_PRODUCT = new Service("Change Test Product", "Change Test Product", SERVICE_CHANGE_TEST_PRODUCT_PATH, ["A test product you know to be good for your test is missing stock? Or the price should be higher?", "Request updates to existing test products"],
    <Tune/>);

export const SERVICE_CREATE_PROMOTIONS_AND_COUPONS = new Service("Create Promotions And Coupons", "Add ProCo", SERVICE_CREATE_PROMOTIONS_AND_COUPONS_PATH, ["Get global coupon codes for testing or special coupons for specific loyalty members."],
    <LocalActivity/>);

export const SERVICE_STAND_BY_SUPPORT = new Service("Get Stand-By Support", "Stand-By", SERVICE_STAND_BY_SUPPORT_PATH, [
    "Are you in dire need for test data? The Test Data Team can dispatch dedicated people to focus exclusively on your test data needs"
], <Hail/>);
export const SERVICE_ENV_CHECK = new Service('See Environment Health Checks', "Env Health", SERVICE_CHECK_ENV_PATH, [
    'Is the sales products API performing well? Webshop? What about...',
    'This board shows all our defined hourly environment checks'
], <StackedBarChart/>);


export const userServices = [
    SERVICE_CATALOG,
    SERVICE_REQUEST_TEST_PRODUCT,
    SERVICE_CHANGE_TEST_PRODUCT,
    SERVICE_ADD_TO_SAP,
    SERVICE_CREATE_PROMOTIONS_AND_COUPONS,
    SERVICE_STAND_BY_SUPPORT,
    SERVICE_ENV_CHECK
]

export const adminServices = [
]