import RequestFor from "../molecule/formControl/RequestFor";
import React, {useContext, useRef, useState} from "react";
import ProductName from "../molecule/formControl/ProductName";
import Initiative from "../molecule/formControl/Initiative";
import {Box, Button, FormControl, FormHelperText, FormLabel, Typography} from "@mui/joy";
import {MissingTeamOrInitiativeCta} from "../molecule/MissingTeamOrInitiativeCta";
import {ErrorContext} from "../../context/ErrorContext";
import {useMutation, useQuery} from "@apollo/client";
import {createJiraIssue} from "../../api/mutation";
import {jiraInitiatives} from "../../api/query";
import {useErrorNotification} from "../../api/apolloClient";
import Loading from "../atom/Loading";
import Stage from "../molecule/formControl/Stage";
import Prio from "../molecule/formControl/Prio";
import DueDate from "../molecule/formControl/DueDate";
import SalesLine from "../molecule/formControl/SalesLine";
import {NativeSelect} from "@mui/material";
import SapPlantId from "../molecule/formControl/SapPlantId";
import {SuccessContext} from "../../context/SuccessContext";
import MultipleProductIds from "../molecule/formControl/MultipleProductIds";

const AddTestProductToSap = () => {
    const [requestFor, setRequestFor] = useState("")
    const [requestingParty, setRequestingParty] = useState("")
    const [team, setTeam] = useState("")
    const [initiative, setInitiative] = useState("")
    const [initiativeInputValue, setInitiativeInputValue] = useState("");
    const [country, setCountry] = useState("");
    const [stage, setStage] = useState("");
    const [salesLine, setSalesLine] = useState("MM");
    let now = new Date();
    now.setDate(now.getDate() + 14)
    const [dueDate, setDueDate] = useState(now.toISOString().split('T')[0])
    const [prio, setPrio] = useState("Trivial")
    const [id, setId] = useState("")
    const [sapPlantId, setSapPlantID] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)


    const {setError} = useContext(ErrorContext);
    const {setSuccess} = useContext(SuccessContext);
    const [createJiraIssueMutation] = useMutation(createJiraIssue);
    const jiraInitiativesQuery = useQuery(jiraInitiatives, {notifyOnNetworkStatusChange: true})

    useErrorNotification(jiraInitiativesQuery.error, setError)

    const formRef = useRef(null);

    if (jiraInitiativesQuery.loading) return <Loading/>
    if (!jiraInitiativesQuery.data?.jiraInitiatives) return null
    const initiatives = jiraInitiativesQuery.data.jiraInitiatives.initiatives.map(it => `${it.key} ${it.summary}`);

    function handleSubmit(e) {
        e.preventDefault();
        if (isSubmitting) return
        setIsSubmitting(true);
        createJiraIssueMutation({
                notifyOnNetworkStatusChange: true,
                variables: {
                    input: {
                        summary: `Add to SAP [${stage}] [${country}][${salesLine}] for ${requestingParty}`,
                        description: `please add to SAP ${stage}-${country}-${salesLine}\n\n${id}`,
                        relatesToInitiativeKey: initiative ? initiative.split(" ")[0] : null,
                        dueDate,
                        prio,
                        service: "ADD_TO_SAP"
                    }
                }
            }
        )
            .catch(err => {
                setIsSubmitting(false);
                return setError(err);
            })
            .then(res => {
                setIsSubmitting(false);
                setSuccess(res.data.createJiraIssue.createdIssue.key);
            })
    }

    return <>
        <Typography level={"h2"}>Add Test Product To SAP</Typography>
        <form ref={formRef} onSubmit={handleSubmit}>
            <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                <Box sx={{display: "flex", gap: 1, flexDirection: "column"}}>
                    <RequestFor value={requestFor} onChange={(e) => {
                        let v = e.target.value;
                        setRequestFor(v);
                        if (v === "country") setRequestingParty("country")
                    }}/>

                    {requestFor === "team" &&
                        <ProductName value={team} onChange={(event, newValue) => {
                            setTeam(newValue);
                            setRequestingParty(newValue)
                        }}/>}
                    {requestFor === "initiative" && <Initiative
                        value={initiative}
                        onChange={(event, newValue) => {
                            setInitiative(newValue);
                            setRequestingParty(newValue)
                        }}
                        inputValue={initiativeInputValue}
                        onInputChange={(event, newInputValue) => {
                            setInitiativeInputValue(newInputValue);
                        }}
                        options={initiatives}/>}
                    <FormHelperText>We will prioritize your request based on this quarters business
                        priorities.</FormHelperText>
                    <MissingTeamOrInitiativeCta/>
                </Box>
                <Box sx={{display: "flex", flexWrap: "wrap", gap: 1}}>
                    <Prio value={prio} onChange={(e) => setPrio(e.target.value)}/>
                    <DueDate value={dueDate} onChange={e => {
                        setDueDate(e.target.value);
                    }}/>
                </Box>
                <Box
                    sx={{display: "flex", flexWrap: "wrap", gap: 1}}>
                    <Stage value={stage} onChange={(e) => {
                        setStage(e.target.value);
                    }}/>
                    <FormControl>
                        <FormLabel required>Country</FormLabel>
                        <NativeSelect fullWidth required value={country} onChange={(e) => {
                            const value = e.target.value;
                            if (value !== "DE") {
                                setSalesLine(["MM"])
                            }
                            setCountry(value);
                        }}>
                            <option value="DE">Germany (DE)</option>
                            <option value="NL">Netherlands (NL)</option>
                            <option value="" disabled>---</option>
                            <option value="AT">Austria (AT)</option>
                            <option value="BE">Belgium (BE)</option>
                            <option value="DE">Germany (DE)</option>
                            <option value="HU">Hungary (HU)</option>
                            <option value="IT">Italy (IT)</option>
                            <option value="LU">Luxembourg (LU)</option>
                            <option value="NL">Netherlands (NL)</option>
                            <option value="PL">Poland (PL)</option>
                            <option value="PT">Portugal (PT)</option>
                            <option value="ES">Spain (ES)</option>
                            <option value="SE">Sweden (SE)</option>
                            <option value="CH">Switzerland (CH)</option>
                            <option value="TR">Turkey (TR)</option>
                        </NativeSelect>
                    </FormControl>

                    {country === "DE" && <><SalesLine value={salesLine}
                                                      onChange={(e) => setSalesLine(e.target.value)}/></>}
                    <SapPlantId value={sapPlantId}
                                onChange={e => setSapPlantID(e.target.value)}/>
                    <MultipleProductIds value={id} onChange={e => setId(e.target.value)}/>
                    <FormHelperText>It's easier to manage for us when requests are only covering one env/country
                        combination. If you need more variants, just submit this form, then change the values and submit
                        again.</FormHelperText>
                </Box>
                <Button disabled={isSubmitting}
                        endDecorator={isSubmitting ?
                            <Typography fontSize="xx-small"><Loading/></Typography> : null}
                        type={"submit"}>Submit</Button>
            </Box>
        </form>
    </>
}

export default AddTestProductToSap