import LocalDateTime from "../atom/LocalDateTime";
import {Typography} from "@mui/joy";
import React from "react";

export const Comparing = ({current, to, on, as, labeled, log}) => {
    const toCompare = to ? to[on] : undefined;
    let value = current && current[on];
    let isDiff = value === toCompare;
    if (log) {
        console.log({current, to, on})
        console.log(value, toCompare, isDiff)
    }
    let displayValue;
    if (as === "LocalDateTime") {
        displayValue = <LocalDateTime epochSeconds={value}></LocalDateTime>
    } else {
        displayValue = JSON.stringify(value)
    }
    return <>
        <dt><Typography
            color={isDiff ? undefined : "warning"}>{labeled ? labeled : on.replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`)}</Typography>
        </dt>
        <dd><Typography>{displayValue}</Typography></dd>
    </>
}