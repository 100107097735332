import {useQuery} from "@apollo/client";
import {envHealthCheckResults, latestEnvHealthCheckResults} from "../../api/query";
import React, {useContext, useState} from "react";
import {ErrorContext} from "../../context/ErrorContext";
import {useErrorNotification} from "../../api/apolloClient";
import Loading from "../atom/Loading";
import {distinct} from "../../model/utils";
import Table from '@mui/joy/Table';
import {Box, Button, Chip, Grid, List, ListItem, Typography} from "@mui/joy";
import ResultsIndicator from "../molecule/ResultsIndicator";
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import LocalDateTime from "../atom/LocalDateTime";
import KnownEnvIssues from "../molecule/KnownEnvIssues";
import {LinkTargetBlank} from "../atom/LinkTartgetBlank";

const Focus = ({focussed, it, onClick}) => <>
    <Button size={"sm"} color={"neutral"}
            variant={it.check.id === focussed ? "outlined" : "soft"}
            type={"button"} onClick={onClick} endDecorator={<OpenInFullIcon fontSize={"small"}/>}>
        details</Button>
</>;

const Results = ({latestCheckResults, setFocus, focussed}) => {
    const countries = [null, ...distinct(latestCheckResults.map(it => it.check.country))].sort()
    const stages = [null, ...distinct(latestCheckResults.map(it => it.check.stage))].sort()
    let updatedAtSeconds = latestCheckResults.map(it => it.updatedAt.seconds);
    const earliestSeconds = Math.min(...updatedAtSeconds);
    const latestSeconds = Math.max(...updatedAtSeconds);
    return <>
        <KnownEnvIssues/>
        <Table>
            <thead>
            <tr>
                <th></th>
                {countries.map(it => <th key={it}>{it || "not set"}</th>)}
            </tr>
            </thead>
            <tbody>
            {stages.map(stage => <tr key={stage}>
                <td>{stage || "not set"}</td>
                {countries.map(country => <th key={country}>
                    <Box sx={{display: "flex", flexWrap: "wrap"}}>
                        <ResultsIndicator
                            results={latestCheckResults.filter(result => result.check.country === country && result.check.stage === stage)}
                            setFocus={setFocus} focussed={focussed}/>
                    </Box>
                </th>)}
            </tr>)}
            </tbody>
        </Table>

        <Typography>showing results between <LocalDateTime epochSeconds={earliestSeconds}/> and <LocalDateTime
            epochSeconds={latestSeconds}/> <LinkTargetBlank href={"https://rkt.mediamarktsaturn.com/channel/test-data-solutions-notifications"}>get notified</LinkTargetBlank></Typography>

        {latestCheckResults.find(it => it.status === null) && <>
            <Typography level={"h3"}>In Progress:</Typography>
            {latestCheckResults.filter(it => it.status === null).map(it =>
                <Box key={it.id}>
                    <Typography>{it.check.stage} {it.check.country} {it.check.title}
                        {" "}<Focus it={it} focussed={focussed} onClick={() => setFocus(it.check.id)}/>
                    </Typography>
                </Box>)}
        </>}
        {latestCheckResults.find(it => it.status === "FAILURE") && <>
            <Typography level={"h3"}>Failing:</Typography>
            {latestCheckResults.filter(it => it.status === "FAILURE").map(it =>
                <Box key={it.id}>
                    <Typography>{it.check.stage} {it.check.country} {it.check.title}: <Typography color={"danger"}>
                        {it.steps.find(step => step.status === "FAILURE").step}
                    </Typography>
                        {" "}<Focus it={it} focussed={focussed} onClick={() => setFocus(it.check.id)}/>
                    </Typography>
                </Box>)}
        </>}
        {latestCheckResults.find(it => it.status === "COMPROMISED") && <>
            <Typography level={"h3"}>Compromised:</Typography>
            <Typography level={"body-sm"}>result is inconclusive, e.g. due to rate limiting</Typography>
            {latestCheckResults.filter(it => it.status === "COMPROMISED").map(it =>
                <Box key={it.id}>
                    <Typography>{it.check.stage} {it.check.country} {it.check.title}: <Typography color={"warning"}>
                        {it.steps.find(step => step.status === "COMPROMISED").step}
                    </Typography>
                        {" "}<Focus it={it} focussed={focussed} onClick={() => setFocus(it.check.id)}/>
                    </Typography>
                </Box>)}
        </>}
        {latestCheckResults.find(it => it.status === "SUCCESS") && <>
            <Typography level={"h3"}>Successful</Typography>
            {latestCheckResults.filter(it => it.status === "SUCCESS").map(it =>
                <Box key={it.id}>
                    <Typography>{it.check.stage} {it.check.country} {it.check.title}
                        {" "}<Focus it={it} focussed={focussed} onClick={() => setFocus(it.check.id)}/>
                    </Typography>
                </Box>)}
        </>}
        {latestCheckResults.find(it => it.status === "IGNORED") && <>
            <Typography level={"h3"}>Ignored:</Typography>
            {latestCheckResults.filter(it => it.status === "IGNORED").map(it =>
                <Box key={it.id}>
                    <Typography>{it.check.stage} {it.check.country} {it.check.title}: <Typography>
                        {it.steps.find(step => step.status === "IGNORED").step}
                    </Typography>
                        {" "}<Focus it={it} focussed={focussed} onClick={() => setFocus(it.check.id)}/>
                    </Typography>
                </Box>)}
        </>}
    </>;
};

const ExecutedStep = ({step}) => {
    let status = step.status;
    return <>
        <Typography
            color={status === 'FAILURE' ? 'danger' : status === 'SUCCESS' ? 'success' : status === 'IGNORED' ? 'neutral' : status === 'COMPROMISED' ? 'warning' : 'info'}>{step.step}</Typography>
    </>;
};

const ResultDetails = ({result}) => {
    return <>
        <Typography level={"body-xs"}><LocalDateTime epochSeconds={result.updatedAt.seconds}/></Typography>
        {result.steps.map(it => <ExecutedStep key={it.id} step={it}></ExecutedStep>)}
        {result.status === 'FAILURE' && <>
            <Typography level={"body-xs"}
                        color={"danger"}>{result.steps.find(it => it.status === 'FAILURE')?.message}</Typography>
        </>}

        {result.status === 'COMPROMISED' && <>
            <Typography level={"body-xs"}
                        color={"warning"}>{result.steps.find(it => it.status === 'COMPROMISED')?.message}</Typography>
        </>}
    </>;
};

const FocusResult = ({id, setFocus}) => {
    const query = useQuery(envHealthCheckResults, {
        variables: {
            input: {
                checkId: id
            }
        }
    })
    const {loading, data, error} = query;

    const {setError} = useContext(ErrorContext);
    useErrorNotification(error, setError)

    if (loading) return <Loading/>
    if (error) {
        return null;
    }

    const {check, results} = data.envHealthCheckResults;

    const group = check.path.reduce((it, other) => other);
    return <>
        <Box position={"sticky"} top={0}>
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <Typography level={"h2"} sx={{display: "inline"}}>{check.title}</Typography>
                <Button variant={"soft"} color={"neutral"} type={"button"}
                        onClick={() => setFocus(undefined)}><CloseIcon/></Button>
            </Box>
            <Chip>{group}</Chip>
            {check.description && <Typography level={"body-sm"}>{check.description}</Typography>}
            <List sx={{overflowX: "scroll", display: "flex", flexWrap: "no-wrap", gap: 1}} orientation="horizontal">
                {[...results].sort((it, other) => other.updatedAt.seconds - it.updatedAt.seconds).map(it => <ListItem
                    sx={{maxWidth: "60%", display: "block", overflowWrap: "anywhere"}} key={it.id}><ResultDetails
                    result={it}/></ListItem>)}
            </List>
        </Box>
    </>
};

const EnvironmentDashboard = () => {
    const query = useQuery(latestEnvHealthCheckResults)
    const {loading, data, error} = query;
    const [focus, setFocus] = useState(undefined)

    const {setError} = useContext(ErrorContext);
    useErrorNotification(error, setError)

    if (loading) return <Loading/>
    if (error) {
        return null;
    }

    const {latestCheckResults} = data.envHealthChecks
    let results = [...latestCheckResults].sort((it, other) => {
        return `${it.check.stage}${it.check.country}${it.check.title}`.localeCompare(`${other.check.stage}${other.check.country}${other.check.title}`);
    });

    if (!focus) {
        return <>
            <Typography level={"h1"}>Environment Dashboard</Typography>

            <Results setFocus={setFocus} latestCheckResults={results}/></>
    }

    return <>
        <Typography level={"h1"}>Environment Dashboard</Typography>
        <Grid container spacing={1}>
            <Grid xs={5}>
                <Results focussed={focus} setFocus={setFocus}
                         latestCheckResults={results}/>
            </Grid>
            <Grid xs={7}>
                <FocusResult setFocus={setFocus} id={focus}></FocusResult>
            </Grid>
        </Grid>
    </>

}

export default EnvironmentDashboard