import {Box, Button, FormControl, FormHelperText, FormLabel, Input, Switch, Typography} from "@mui/joy";
import React, {useContext, useEffect, useState} from "react";
import {NativeSelect} from "@mui/material";
import {PreviewProduct} from "../../model/product";
import ProductPreview from "../molecule/ProductPreview";
import {useLazyQuery, useMutation} from "@apollo/client";
import {triggerMdngEdiForReference} from "../../api/mutation";
import Loading from "../atom/Loading";
import {ErrorContext} from "../../context/ErrorContext";
import {findTestProductForReference, product} from "../../api/query";
import {useErrorNotification} from "../../api/apolloClient";
import ExistingReferenceProduct from "../molecule/ExistingReferenceProduct";
import {AuthContext} from "../../context/AuthContext";

const TriggerEdi = () => {
    const {user} = useContext(AuthContext);
    const [stage, setStage] = useState("");
    const [preview, setPreview] = useState(null)
    const [reference, setReference] = useState("")
    const [response, setResponse] = useState( null)
    const [gcpEdi, setGcpEdi] = useState(false);
    const {setError} = useContext(ErrorContext);
    const [fetchProductForReference, findTestProductForReferenceQuery] = useLazyQuery(findTestProductForReference, {notifyOnNetworkStatusChange: true})
    const [fetchProduct, productQuery] = useLazyQuery(product, {notifyOnNetworkStatusChange: true})

    useErrorNotification(findTestProductForReferenceQuery.error, setError)
    useErrorNotification(productQuery.error, setError)

    const [triggerMdngEdiForReferenceMutation, {loading}] = useMutation(triggerMdngEdiForReference);
    useEffect(() => {
        if (!preview?.id) return
        if (!stage) return
        fetchProductForReference({
            notifyOnNetworkStatusChange: true,
            variables: {
                input: {
                    id: preview.id,
                    country: preview.country.toUpperCase(),
                    stage: stage
                }
            }
        }).then(res => {
            const ref = res.data?.findTestProductForReference?.id
            if (ref) {
                fetchProduct({
                    notifyOnNetworkStatusChange: true,
                    variables: {
                        input: {productId: ref}
                    }
                })
            }
        })

    }, [preview?.id, stage, fetchProductForReference, fetchProduct, preview?.country])

    const existingReference = findTestProductForReferenceQuery.data?.findTestProductForReference?.id;
    const readyToSubmit = preview && preview.id

    /**
     * @param e
     * @param {PreviewProduct} product
     */
    const handleSubmit = (e, product) => {
        e.preventDefault();
        if (!readyToSubmit || !user.isAdmin) return
        triggerMdngEdiForReferenceMutation({
            notifyOnNetworkStatusChange: true, variables: {
                input: {
                    country: product.country.toUpperCase(),
                    salesLine: product.salesLine,
                    productId: product.id,
                    stage,
                    useGcpEdi: gcpEdi
                }
            }
        })
            .catch(err => setError(err))
            .then(res => setResponse(res.data.triggerMdngEdiForReference))
    };

    if (response) {
        const target = response.targetProductId;
        return <Box>
            {!response.error && <Typography level={"h3"}>{`${target?.stage}-${target?.country}-${target?.productId}`}</Typography>}
            <pre>{JSON.stringify(response, null, 2)}</pre>
        </Box>
    }

    return <>
        <Typography level={"h2"}>Trigger MDNG EDI</Typography>
        <Typography level={"subtitle"}>Pick a product from production to replicate it in the test mdng</Typography>

        <form onSubmit={(e) => handleSubmit(e, preview)}>
            <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>

                <FormControl>
                    <FormLabel required>Production Webshop Reference</FormLabel>
                    <FormHelperText>like DE-2784780</FormHelperText>
                    <Input required value={reference}
                           name={"production-reference"}
                           placeholder={"DE-2784780"}
                           onChange={e => setReference(e.target.value)}
                           onBlur={e => {
                               const split = e.target.value.split("-");
                               return setPreview(new PreviewProduct(split[1], split[0], "MM"));
                           }}
                    />
                </FormControl>
                {
                    findTestProductForReferenceQuery.loading && <Loading/>
                }
                {
                    existingReference && <>
                        <Typography>existing test product:</Typography>
                        <ExistingReferenceProduct product={productQuery.data?.product.product}></ExistingReferenceProduct>
                    </>
                }
                <FormControl orientation={"horizontal"}>
                    <FormLabel required>Create In</FormLabel>
                    <NativeSelect required value={stage} onChange={(e) => setStage(e.target.value)}>
                        <option value=""></option>
                        <option value="QA">QA</option>
                        <option value="INT">INT</option>
                    </NativeSelect>
                </FormControl>

                    <FormControl orientation={"horizontal"} sx={{maxWidth: 300}}>
                        <FormLabel>gcp edi</FormLabel>
                        <Switch
                            aria-checked={gcpEdi}
                            checked={gcpEdi}
                            onChange={() => setGcpEdi(!gcpEdi)}
                            color={'neutral'}
                            variant="outlined"
                            startDecorator={<><FormHelperText>off</FormHelperText></>}
                            endDecorator={<><FormHelperText>on</FormHelperText></>}
                        />
                    </FormControl>

                <Button disabled={!readyToSubmit} type={"submit"}>{loading ? <><Loading/>Takes up to 2 minutes, please don't close this page</> : "Submit"}</Button>
                <>
                    {preview?.id && preview?.salesLine && preview?.country &&
                        <ProductPreview product={preview}></ProductPreview>}
                </>

            </Box>
        </form>


    </>
}

export default TriggerEdi