import React, {useContext} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {Box, TabList, Tabs, Typography} from "@mui/joy";
import {
    ADMIN_CHECKS_HISTORY_PATH,
    ADMIN_INSPECT_ID_PATH,
    ADMIN_MANAGE_CATALOG_PATH,
    ADMIN_PATH, ADMIN_TRIGGER_EDI_FROM_REFERENCE,
    RELEASE_NOTES_PATH,
    ROOT_PATH,
    SERVICES_PATH,
} from "../../constant/routes";
import VisibleForAdmins from "../atom/VisibleForAdmins";
import {TabLink} from "../atom/TabLink";
import {Announcement, Interests} from "@mui/icons-material";
import {ReleaseNotesReadContext} from "../../context/ReleaseNotesReadContext";
import {SERVICE_ENV_CHECK, userServices} from "../../constant/services";
import {LinkTargetBlank} from "../atom/LinkTartgetBlank";
import {useQuery} from "@apollo/client";
import {latestEnvHealthCheckResults} from "../../api/query";
import {ErrorContext} from "../../context/ErrorContext";
import {useErrorNotification} from "../../api/apolloClient";

function ReleaseNotesTabLink() {
    const {latestReleaseNoteRead, latestReleaseNote} = useContext(ReleaseNotesReadContext)
    let icon = <Announcement
        sx={{ animationName: "updateIconFlashes", animationDuration: "10s", animationIterationCount: "infinite" }}
    />;
    return <TabLink route={RELEASE_NOTES_PATH} label={"Release Notes"} icon={latestReleaseNoteRead !== latestReleaseNote ? icon : null}/>;

}

const RootTabs = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return <Tabs
        value={"/" + location.pathname.split("/")[1]}
        aria-label="Vertical Navigation"
        orientation="vertical"
        onChange={(e, value) => navigate(value)}
    >
        <TabList
            disableUnderline
            sx={{width: "100%"}}
        >
            <TabLink route={ROOT_PATH} label={"Home"}/>
            <TabLink route={SERVICES_PATH} label={"Services"}/>
            <Routes>
                <Route path={`${SERVICES_PATH}/*`} element={<ServiceTabs/>}/>
            </Routes>
            <VisibleForAdmins>
                <TabLink route={ADMIN_PATH} label={"Admin"}/>
                <Routes>
                    <Route path={`${ADMIN_PATH}/*`} element={<AdminTabs/>}/>
                </Routes>
            </VisibleForAdmins>
            <ReleaseNotesTabLink/>
            <Typography level={"body-sm"} sx={{margin: 2}}>
                <LinkTargetBlank fullWidth href={"https://confluence.media-saturn.com/x/6kjDH"}>
                    User Manual
                </LinkTargetBlank>
                <Box p={1}/>
                <LinkTargetBlank fullWidth href={"https://rkt.mediamarktsaturn.com/channel/test-data-solutions"}>
                    Contact
                </LinkTargetBlank>
            </Typography>
        </TabList>

    </Tabs>;
};

function ServiceTabLink({service}) {

    const query = useQuery(latestEnvHealthCheckResults)
    const {loading, data, error} = query;

    const {setError} = useContext(ErrorContext);
    useErrorNotification(error, setError)

    const latestCheckResults = !loading && !error && data.envHealthChecks?.latestCheckResults

    const shouldIndicateEnvError = !loading && !error && latestCheckResults?.find(it => it.status === "FAILURE")

    const icon = service.icon
    return <>
            {service !== SERVICE_ENV_CHECK &&
                <TabLink icon={icon} route={service.path} label={service.shortName}/>}
            {service === SERVICE_ENV_CHECK &&  <TabLink blink={shouldIndicateEnvError}
             icon={icon} route={service.path} label={service.shortName}/>}
        </>;
}

const ServiceTabs = () => {
    const navigate = useNavigate();
    const location = useLocation();

//
    return <Tabs
        value={location.pathname}
        aria-label="Vertical Subnavigation"
        orientation="vertical"
        onChange={(e, value) => navigate(value)}
        variant={"soft"}
    >
        <TabList
            disableUnderline
            sx={{width: "100%"}}
        >
            <TabLink route={SERVICES_PATH} label={"Home"} icon={<Interests/>}/>
            {userServices.map(it => <ServiceTabLink key={it.path} service={it}/>)}
        </TabList>
    </Tabs>;
};

const AdminTabs = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return <Tabs
        value={location.pathname}
        aria-label="Vertical Subnavigation"
        orientation="vertical"
        onChange={(e, value) => navigate(value)}
        variant={"soft"}
    >
        <TabList
            disableUnderline
            sx={{width: "100%"}}
        >
            <TabLink route={ADMIN_PATH} label={"Overview"}></TabLink>
            <TabLink route={ADMIN_MANAGE_CATALOG_PATH} label={"Catalog"}/>
            <TabLink route={ADMIN_CHECKS_HISTORY_PATH} label={"Checks History"}/>
            <TabLink route={ADMIN_TRIGGER_EDI_FROM_REFERENCE} label={"EDI"}/>
            <TabLink route={ADMIN_INSPECT_ID_PATH} label={"Inspect Item"}/>
        </TabList>
    </Tabs>;
};

const NavigationTabs = () => {
    return <>
        <Box sx={{display: 'flex', flexFlow: 'column'}}>
            <RootTabs/>
        </Box>

    </>;
};
export default NavigationTabs