import React from "react";
import Service from "./Service";
import {Box, Grid, Typography} from "@mui/joy";
import VisibleForAdmins from "../atom/VisibleForAdmins";
import {adminServices, userServices} from "../../constant/services";

const Services = () => {
    return <>
        <Typography level={"h2"}>What would you like to do?</Typography>
        <Box p={2}></Box>
        <Grid container spacing={4}>

            {userServices.map(it => <React.Fragment key={it.path}>
                <Service
                    target={it.path}
                    name={it.name}
                    description={it.description}
                    icon={it.icon}
                /> </React.Fragment>)}

            <VisibleForAdmins>

                {adminServices.map(it => <React.Fragment key={it.path}>
                    <Service
                        target={it.path}
                        name={it.name}
                        description={it.description}
                        icon={it.icon}
                    /> </React.Fragment>)}
            </VisibleForAdmins>
        </Grid>
    </>
}


export default Services