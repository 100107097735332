export const ROOT_PATH = "/"

export const SERVICES_PATH = "/services"
export const SERVICE_CATALOG_PATH = "/services/catalog"
export const SERVICE_REQUEST_PRODUCTS_PATH = "/services/create-test-product"
export const SERVICE_REQUEST_PRODUCTS_V2_PATH = "/services/custom-test-product-v2"
export const SERVICE_CHECK_ENV_PATH = "/services/environment-check-results"
export const SERVICE_ADD_TEST_PRODUCT_TO_SAP_PATH = "/services/add-test-product-to-sap"
export const SERVICE_CHANGE_TEST_PRODUCT_PATH = "/services/change-test-product"
export const SERVICE_CREATE_PROMOTIONS_AND_COUPONS_PATH = "/services/proco-creation"
export const SERVICE_STAND_BY_SUPPORT_PATH = "/services/stand-by"

export const ADMIN_PATH = "/admin"
export const ADMIN_MANAGE_CATALOG_PATH = "/admin/catalog"
export const ADMIN_CHECKS_HISTORY_PATH = "/admin/checks-history"
export const ADMIN_INSPECT_ID_PATH = "/admin/inspect"
export const ADMIN_TRIGGER_EDI_FROM_REFERENCE = "/admin/trigger_edi_for_reference"

export const RELEASE_NOTES_PATH = "/release-notes"