import {FormControl, FormLabel, Input} from "@mui/joy";
import React from "react";

const DueDate = ({onChange, value}) => <FormControl>
    <FormLabel required>Due Date</FormLabel>
    <Input variant={"soft"} type={"date"}
           value={value}
           required
           onChange={onChange}/>
</FormControl>;

export default DueDate