import {useContext, useEffect, useState} from "react";
import {Box, Typography} from "@mui/joy";
import Loading from "../atom/Loading";
import {useMutation} from "@apollo/client";
import {checkProduct, trackProduct} from "../../api/mutation";
import {CatalogContext} from "../../context/CatalogContext";

const AddToCatalog = ({stage, country, salesLine, id}) => {
    const error = id.length < 7;
    const {products} = useContext(CatalogContext)
    const [state, setState] = useState(!error? "new" : "error")
    const [trackProductMutation, trackProductState] = useMutation(trackProduct);
    const [checkProductMutation] = useMutation(checkProduct);
    let s = `${stage}-${country}-${salesLine}-${id}`;


    useEffect(() => {
        if (trackProductState.loading) return;
        if (state !== "new") return
        if (error) return
        let productsWithSameId = products.filter(it => it.foreignId === id && it.env === stage && it.country === country && it.salesLine === salesLine);
        if (productsWithSameId.length > 0) {
            setState("duplicate")
            return;
        }
        trackProductMutation({
            variables: {
                input: {
                    id, stage, country, salesLine
                }
            }
        }).catch(err => {
            err.message += ' addProduct'
            console.error(err)
            setState("error, most likely duplicate")
        }).then(result => {
            if (!result?.data) return
            setState("added")
            return checkProductMutation({
                variables: {
                    input: {
                        productId: result.data.trackProduct.productId
                    }
                }
            }).catch(err => {
                err.message += ' track product'
                console.error(err)
            })
        });
    }, [state, checkProductMutation, country, id, products, salesLine, stage, trackProductMutation, trackProductState.loading, error])


    return <>
        <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography level={"h3"}>{s} -> {state}{error && <>{" "}<Typography color={"danger"}>invalid id</Typography></>}</Typography>{state === "new" && <Loading></Loading>}
        </Box>
    </>
}

export default AddToCatalog