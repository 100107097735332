import {useNavigate} from "react-router-dom";
import {Box, Button, Grid, Typography} from "@mui/joy";

const Service = ({name, description, target, icon}) => {
    const navigate = useNavigate();

    const ServiceName = () => <Typography level={"body-md"} component={"h3"}>{name}</Typography>;

    const ServiceDescription = () => <Box>{description.map((d, i) => <Typography textAlign={"justify"} variant={"soft"} p={1}
        key={i}>{d}</Typography>)}</Box>;

    return <Grid xs={12} sm={6} md={4} lg={3}>
            <Box sx={{cursor: 'pointer', display: "flex", gap:1, flexDirection: "column"}} onClick={() => {
                navigate(target)
            }}>
                <Button fullWidth variant={"outlined"} className={"title"} startDecorator={icon}><ServiceName/></Button>
                <ServiceDescription/>
            </Box>
    </Grid>;
};

export default Service