import {useMutation} from "@apollo/client";
import {checkProduct, removeProduct} from "../../api/mutation";
import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Card, CardContent, Chip, Link, Stack, Typography} from "@mui/joy";
import {
    Delete,
    DeleteForever,
    ExpandLess,
    ExpandMore,
    Lock,
    Refresh,
    ShoppingCart,
    Troubleshoot
} from "@mui/icons-material";
import Loading from "../atom/Loading";
import {ErrorContext} from "../../context/ErrorContext";
import VisibleForAdmins from "../atom/VisibleForAdmins";
import {CatalogContext} from "../../context/CatalogContext";
import {useNavigate} from "react-router-dom";
import {displayName} from "../../model/product";
import {useErrorNotification} from "../../api/apolloClient";
import LocalDateTime from "../atom/LocalDateTime";
import {SourceNodesContext} from "../../context/SourceNodesContext";
import WebshopLink from "./WebshopLink";

function toCentimeter(m) {
    if (!m && 0 !== m) return undefined
    return Number.parseFloat((m * 100).toFixed(1));
}

function toKiloGram(m) {
    if (!m) return undefined
    return Number.parseFloat((m / 1000).toFixed(3));
}


const ProductCatalogListItem = ({product}) => {
    const {remove} = useContext(CatalogContext)
    const {sourceNodesByForeignId} = useContext(SourceNodesContext);
    const [checkProductMutation, {loading, error}] = useMutation(checkProduct);
    const {setError} = useContext(ErrorContext);
    useErrorNotification(error, setError)

    if (loading) return <Card variant={"outlined"} sx={{width: 1.0}}>
        <Loading/>
    </Card>
    const ItemCardTitle = () => <><Box sx={{display: "flex", justifyContent: "space-between", mb: 1}}>
        <Typography sx={{width: 0.6}} level={"body-md"} component={"h2"}>{displayName(product)}</Typography>
        <Box display={"flex"} sx={{alignItems: "flex-start"}}>
            {product.latestWebshopScrapeResult &&
                <ShoppingCart color={product.latestWebshopScrapeResult?.buyButtonEnabled ? "success" : "error"}
                              fontSize={"small"} sx={{marginRight: 1}}/>}
            <WebshopLink product={product}/>
        </Box>
    </Box></>;

    const Kind = () => {
        if (!product.latestSalesProductsApiScrapeResult?.kindOfProduct) return null

        return <>
            <Chip variant={"outlined"}
                  color={"neutral"}
                  sx={{textTransform: "capitalize"}}>{product.latestSalesProductsApiScrapeResult?.kindOfProduct?.toLowerCase().replaceAll("_", " ")}</Chip>
        </>;
    };
    const Price = () => {
        if (!product.latestWebshopScrapeResult?.ctaDebug?.price) return null

        return <>
            <Chip variant={"outlined"}
                  color={"neutral"}
                  sx={{textTransform: "capitalize"}}>{product.latestWebshopScrapeResult?.ctaDebug?.price} &euro;</Chip>
        </>;
    };
    const SalesLine = () => <>
        <Chip variant={"outlined"} sx={{
            borderColor: product.salesLine === "SE" ? "rgb(239, 124, 0)" : 'rgb(223, 0, 0)',
            color: product.salesLine === "SE" ? "rgb(239, 124, 0)" : 'rgb(223, 0, 0)'
        }}>{product.salesLine}</Chip>
    </>;
    const Id = () => <>
        <Chip variant={"outlined"} color={"neutral"}>{product.env}-{product.country}-{product.foreignId}</Chip>
    </>;

    const Seller = () => {
        if (!product.latestWebshopScrapeResult?.marketplaceSeller) return null
        return <>
            <Chip variant={"outlined"} color={"primary"}>{product.latestWebshopScrapeResult?.marketplaceSeller}</Chip>
        </>;
    };

    const Warranties = () => {
        if (!product.latestWebshopScrapeResult?.hasOneTimePaymentWarranties && !product.latestWebshopScrapeResult?.hasMonthlyPaymentWarranties) return null
        if (product.latestWebshopScrapeResult?.hasOneTimePaymentWarranties && product.latestWebshopScrapeResult?.hasMonthlyPaymentWarranties) return <Chip
            color={"primary"}
            variant={"outlined"}>warranties (pay once/monthly)</Chip>
        if (product.latestWebshopScrapeResult?.hasOneTimePaymentWarranties) return <Chip color={"primary"}
                                                                                         variant={"outlined"}>warranties
            (pay once)</Chip>
        if (product.latestWebshopScrapeResult?.hasMonthlyPaymentWarranties) return <Chip color={"primary"}
                                                                                         variant={"outlined"}>warranties
            (pay monthly)</Chip>
        return null
    }

    const Services = () => {
        if (!product.latestWebshopScrapeResult?.hasServices) return null

        return <Chip color={"primary"} variant={"outlined"}>services</Chip>
    }

    const PackageDimensions = () => {
        if (!product.latestSalesProductsApiScrapeResult) return null
        const {packageDimensions, packageDimensionsPredicted} = product.latestSalesProductsApiScrapeResult;
        if (!packageDimensions && !packageDimensionsPredicted) return null

        const width = packageDimensions.widthMeters ? toCentimeter(packageDimensions.widthMeters) : toCentimeter(packageDimensionsPredicted.widthMeters);
        const height = packageDimensions.heightMeters ? toCentimeter(packageDimensions.heightMeters) : toCentimeter(packageDimensionsPredicted.heightMeters);
        const depth = packageDimensions.depthMeters ? toCentimeter(packageDimensions.depthMeters) : toCentimeter(packageDimensionsPredicted.depthMeters);
        const weight = packageDimensions.weightGram ? toKiloGram(packageDimensions.weightGram) : toKiloGram(packageDimensionsPredicted.weightGram);

        let anyUndefined = [width, height, depth, weight].indexOf(undefined) !== -1;
        if (anyUndefined) return null

        return <Chip color={"primary"} variant={"outlined"}>{`${width}x${height}x${depth}cm ${weight}kg`}</Chip>
    }

    const LogisticsClass = () => {
        if (!product.latestSalesProductsApiScrapeResult?.logisticsClass) return null
        return <Chip color={"primary"} variant={"outlined"}>Logistics
            Class {product.latestSalesProductsApiScrapeResult?.logisticsClass}</Chip>
    }

    const SameDayPickupIn = () => {
        let sameDayIndications = product.pickupFulfillmentIndications.sameDayPickupSourceNodeIds;
        if (sameDayIndications.length === 0) return null;
        let sourceNodeId = sameDayIndications[0];
        let firstSource = sourceNodesByForeignId.get(sourceNodeId);
        return <Chip  color={"primary"} variant={"outlined"}>SameDay Pickup in <Box
            sx={{maxWidth: 400, textOverflow: "ellipsis", display: "inline"}}>{sourceNodeId} {firstSource?.name}</Box>{
            sameDayIndications.length > 1 ? ` and +${sameDayIndications.length - 1} others` : ""
        }</Chip>
    }

    const NumberOfActiveChecks = () => {
        if (!product.expectations) return null
        const enabled = product.expectations.filter(it => it.enabled);
        if (!(enabled.length >= 1)) return null
        return <Chip variant={"outlined"} startDecorator={<Lock/>}>{product.expectations.length}</Chip>
    }

    const ProductCatalogListItemDetails = () => {
        return <>
            <pre style={{overflowX: "scroll", maxWidth: "100%"}}>{JSON.stringify(product, undefined, 2)}</pre>
        </>
    }

    const Inspect = () => {
        const navigate = useNavigate();
        return <Link href={"/#/admin/inspect/" + product.id} endDecorator={<Troubleshoot/>}
                     size={"sm"} variant={"plain"} type="button"
                     onClick={() => navigate("/admin/inspect/" + product.id)}></Link>
    }


    const RefreshInfo = () => {
        let epochSeconds = product.lastScrapedAt.seconds
        return <Button sx={{flexGrow: 1, justifyContent: "flex-end"}} endDecorator={<Refresh/>}
                       size={"sm"} variant={"plain"} type="button"
                       onClick={() => checkProductMutation({variables: {input: {productId: product.id}}})}>
            latest info from <LocalDateTime epochSeconds={epochSeconds}/>
        </Button>;
    };

    const DeleteProduct = () => {
        const [removeProductMutation, {loading, error}] = useMutation(removeProduct);
        const [sure, setSure] = useState(false);
        useErrorNotification(error, setError)

        useEffect(() => {
            if (!sure) return;
            const timer = setTimeout(() => {
                setSure(false);
            }, 3000);
            return () => clearTimeout(timer);
        }, [sure]);

        return <Button color={"danger"} size={"sm"} variant={"soft"} type="button"
                       onClick={() => {
                           if (!sure) setSure(true);
                           if (sure) removeProductMutation({variables: {input: {productId: product.id}}}).then(() => remove(product.id))
                       }}>
            {!sure && <Delete/>}
            {sure && <><DeleteForever/><Typography>are you sure?{loading && <Loading/>}</Typography></>}
        </Button>
    }

    const ItemCardContent = () => {
        const [expanded, setExpanded] = useState(false);
        return <CardContent>
            <Stack spacing={1} width={1.0}>

                <Box sx={{
                    display: "flex", gap: 1, flexWrap: "wrap", alignItems: "flex-start"
                }}>
                    <SalesLine/>
                    <Id/>
                    <Seller/>
                    <Kind/>
                    <Price/>
                </Box>
                <Box sx={{
                    display: "flex", gap: 1, flexWrap: "wrap", alignItems: "flex-start"
                }}>
                    <Warranties/>
                    <Services/>
                    <PackageDimensions/>
                    <LogisticsClass/>
                    <SameDayPickupIn/>
                    <VisibleForAdmins>
                        <NumberOfActiveChecks/>
                    </VisibleForAdmins>
                    <RefreshInfo/>
                    <VisibleForAdmins>
                        <Inspect/>
                        <DeleteProduct/>
                        {!expanded &&
                            <Button sx={{flexGrow: 0, justifyContent: "flex-end"}} endDecorator={<ExpandMore/>}
                                    size={"sm"} variant={"plain"} type="button"
                                    onClick={() => setExpanded(!expanded)}></Button>}
                        {expanded && <Button sx={{flexGrow: 0, justifyContent: "flex-end"}} endDecorator={<ExpandLess/>}
                                             size={"sm"} variant={"plain"} type="button"
                                             onClick={() => setExpanded(!expanded)}></Button>}
                    </VisibleForAdmins>
                </Box>
                {expanded && <ProductCatalogListItemDetails/>}
            </Stack>
        </CardContent>;
    };

    return <>
        <Card variant={"soft"} data-id={product.id} sx={{width: 1.0}}>
            <Box>
                <ItemCardTitle/>
                <ItemCardContent/>
            </Box>
        </Card>
    </>;
};

export default ProductCatalogListItem