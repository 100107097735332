import {Checkbox, FormControl} from "@mui/joy";

/**
 * for multiple checkboxes that are backed by an array of values
 * @param {string} value
 * @param {boolean} required
 * @param {[Array, Function]} state
 */
const MultiCheckbox = ({value, required, state}) => {
    const [values, setValues] = state;
    const add = value => {
        const newValues = [...values]
        newValues.push(value)
        setValues(newValues)
    };

    const remove = country => {
        const newValues = [...values].filter(c => c !== country)
        setValues(newValues)
    };

    return <>
        <FormControl>
            <Checkbox required={required && values.length === 0} checked={values.includes(value)}
                      onChange={e => e.target.checked ? add(value) : remove(value)}
                      label={value}/>
        </FormControl>

    </>;
};

export default MultiCheckbox