import React, {useEffect, useState} from 'react';
import {useDebounce} from "@uidotdev/usehooks";

const byCategory = category => a => !category || a.latestSalesProductsApiScrapeResult?.kindOfProduct === category;
const byEnv = env => product => !env || product.env === env;
const byLogisticsClass = v => a => {
    if (!v) return true
    if (v === "unknown" && !a.latestSalesProductsApiScrapeResult) return true
    if (v === "unknown") return a.latestSalesProductsApiScrapeResult.logisticsClass === null
    return a.latestSalesProductsApiScrapeResult?.logisticsClass === v;
};
const byCountry = country => a => !country || a.country === country;
const byWebshopBuyButtonEnabled = b => a => {
    if (!b) return true
    if (b === "unknown" && !a.latestWebshopScrapeResult) return true
    if (b === "unknown") return a.latestWebshopScrapeResult.buyButtonEnabled === null
    return (b === "t" === a.latestWebshopScrapeResult?.buyButtonEnabled);
};
const byHasWarranties = v => a => {
    if (!v) return true;
    if (v === "unknown") return a.latestWebshopScrapeResult === null || (a.latestWebshopScrapeResult.hasOneTimePaymentWarranties === null && a.latestWebshopScrapeResult.hasMonthlyPaymentWarranties === null)
    if (v === "none") return a.latestWebshopScrapeResult !== null && (!a.latestWebshopScrapeResult?.hasOneTimePaymentWarranties && !a.latestWebshopScrapeResult?.hasMonthlyPaymentWarranties)
    if (v === "once") return a.latestWebshopScrapeResult?.hasOneTimePaymentWarranties
    if (v === "monthly") return a.latestWebshopScrapeResult?.hasMonthlyPaymentWarranties
    return false
}
const byHasServices = b => a => {
    if (!b) return true
    if (b === "unknown" && !a.latestWebshopScrapeResult) return true
    if (b === "unknown") return a.latestWebshopScrapeResult.hasServices === null
    return (b === "t") === (a.latestWebshopScrapeResult?.hasServices || a.latestWebshopScrapeResult?.hasServices);
}
const bySearchTerm = debouncedSearch => a => !debouncedSearch || JSON.stringify(a).toLowerCase().includes(debouncedSearch.toLowerCase());
const bySalesLine = salesLine => product => !salesLine || product.salesLine === salesLine

function matchUntil(v, dimension, factor = 1) {
    return v === "" || (dimension !== null && dimension <= (v / factor));
}
function matchFrom(v, dimension, factor = 1) {
    return v === "" || (dimension !== null && dimension >= (v / factor));
}

const byFromPrice = v => product => matchFrom(v, product.latestWebshopScrapeResult?.ctaDebug?.price)
const byUntilPrice = v => product => matchUntil(v, product.latestWebshopScrapeResult?.ctaDebug?.price)

const byFromWidth = v => product => {
    let dimension = product.latestSalesProductsApiScrapeResult?.packageDimensions?.widthMeters;
    if (!dimension) dimension = product.latestSalesProductsApiScrapeResult?.packageDimensionsPredicted?.widthMeters;
    return matchFrom(v, dimension, 100);
}
const byUntilWidth = v => product => {
    let dimension = product.latestSalesProductsApiScrapeResult?.packageDimensions?.widthMeters;
    if (!dimension) dimension = product.latestSalesProductsApiScrapeResult?.packageDimensionsPredicted?.widthMeters;
    return matchUntil(v, dimension, 100);
}
const byFromHeight = v => product => {
    let dimension = product.latestSalesProductsApiScrapeResult?.packageDimensions?.heightMeters;
    if (!dimension) dimension = product.latestSalesProductsApiScrapeResult?.packageDimensionsPredicted?.heightMeters;
    return matchFrom(v, dimension, 100);
}
const byUntilHeight = v => product => {
    let dimension = product.latestSalesProductsApiScrapeResult?.packageDimensions?.heightMeters;
    if (!dimension) dimension = product.latestSalesProductsApiScrapeResult?.packageDimensionsPredicted?.heightMeters;
    return matchUntil(v, dimension, 100);
}
const byFromDepth = v => product => {
    let dimension = product.latestSalesProductsApiScrapeResult?.packageDimensions?.depthMeters;
    if (!dimension) dimension = product.latestSalesProductsApiScrapeResult?.packageDimensionsPredicted?.depthMeters;
    return matchFrom(v, dimension, 100);
}
const byUntilDepth = v => product => {
    let dimension = product.latestSalesProductsApiScrapeResult?.packageDimensions?.depthMeters;
    if (!dimension) dimension = product.latestSalesProductsApiScrapeResult?.packageDimensionsPredicted?.depthMeters;
    return matchUntil(v, dimension, 100);
}
const byFromWeight = v => product => {
    let dimension = product.latestSalesProductsApiScrapeResult?.packageDimensions?.weightGram;
    if (!dimension) dimension = product.latestSalesProductsApiScrapeResult?.packageDimensionsPredicted?.weightGram;
    return matchFrom(v, dimension, 0.001);
}
const byUntilWeight = v => product => {
    let dimension = product.latestSalesProductsApiScrapeResult?.packageDimensions?.weightGram;
    if (!dimension) dimension = product.latestSalesProductsApiScrapeResult?.packageDimensionsPredicted?.weightGram;
    return matchUntil(v, dimension, 0.001);
}

const byWebshopHttpStatus = status => a => {
    if (status === "unknown") return !a.latestWebshopScrapeResult
    return !status || a.latestWebshopScrapeResult?.httpStatusCode === +status;
};
const byWebshopProductOnlineStatus = v => a => {
    if (!v) return true
    if (v === "unknown" && !a.latestWebshopScrapeResult) return true
    if (v === "unknown" && a.latestWebshopScrapeResult?.ctaDebug === null) return true
    if (v === "unknown" && a.latestWebshopScrapeResult?.ctaDebug?.productOnlineStatus === null) return true;
    return a.latestWebshopScrapeResult?.ctaDebug?.productOnlineStatus === v;
};

const byDeliveryFulfillmentEarliestRange = range => a => !range || range.isInRange(a.deliveryFulfillmentIndication?.earliest?.seconds, 1)

const byFulfillmentSourceNode = v => a => {
    if (!v) return true
    if (v === "unknown" && !a.deliveryFulfillmentIndication) return true
    if (v === "unknown" && !a.deliveryFulfillmentIndication.fromSourceNode) return true
    return a.deliveryFulfillmentIndication?.fromSourceNode === v;
};

const byIsMarketplaceProduct = b => a => !b || (b === "t" ? !!a.latestWebshopScrapeResult?.marketplaceSeller : !a.latestWebshopScrapeResult?.marketplaceSeller)

const byMarketplaceSeller = seller => a => !seller || a.latestWebshopScrapeResult?.marketplaceSeller === seller;

const byAvailability = v => a => {
    if (!v) return true
    if (v === "unknown" && !a.deliveryFulfillmentIndication) return true
    if (v === "unknown" && !a.deliveryFulfillmentIndication.fromSourceNode) return true
    if (!a.deliveryFulfillmentIndication) return false

    if (v === "hd warehouse") {
        return a.deliveryFulfillmentIndication.isFromWarehouse
    }

    if (v === "hd store") {
        return a.deliveryFulfillmentIndication.isFromStore
    }
    return false
};

const byPickup = v => a => {
    if (!v) return true
    let pickupFulfillmentIndications = a.pickupFulfillmentIndications;
    if (v === "unknown" && !pickupFulfillmentIndications) return true
    if (v === "unknown" && (pickupFulfillmentIndications.sameDayPickupSourceNodeIds.length + pickupFulfillmentIndications.nextDayPickupSourceNodeIds.length) === 0) return true

    if (v === "pickup sameday") {
        if (pickupFulfillmentIndications?.sameDayPickupSourceNodeIds.length > 0) return true
    }

    if (v === "pickup nextday") {
        if (pickupFulfillmentIndications?.nextDayPickupSourceNodeIds.length > 0) return true
    }
    return false
};

export const Filter = {
    byCategory,
    byEnv,
    byLogisticsClass,
    byCountry,
    byWebshopBuyButtonEnabled,
    bySearchTerm,
    byHasWarranties,
    byHasServices,
    bySalesLine,
    byFromPrice,
    byUntilPrice,
    byFromWidth,
    byUntilWidth,
    byFromHeight,
    byUntilHeight,
    byFromDepth,
    byUntilDepth,
    byFromWeight,
    byUntilWeight,
    byWebshopHttpStatus,
    byWebshopProductOnlineStatus,
    byDeliveryFulfillmentEarliestRange,
    byFulfillmentSourceNode,
    byIsMarketplaceProduct,
    byMarketplaceSeller,
    byAvailability,
    byPickup
}

export const CatalogFilterContext = React.createContext(undefined);

function CatalogFilterContextBoundary({children}) {
    const [env, setEnv] = useState("");
    const [country, setCountry] = useState("");
    const [logisticsClass, setLogisticsClass] = useState("");
    const [category, setCategory] = useState("");
    const [search, setSearch] = useState("");
    const [availableOnWebshop, setAvailableOnWebshop] = useState("");
    const [hasWarranties, setHasWarranties] = useState("");
    const [hasServices, setHasServices] = useState("");
    const [salesLine, setSalesLine] = useState("");
    const [fromPrice, setFromPrice] = useState("");
    const [untilPrice, setUntilPrice] = useState("");
    const [fromWidth, setFromWidth] = useState("");
    const [untilWidth, setUntilWidth] = useState("");
    const [fromHeight, setFromHeight] = useState("");
    const [untilHeight, setUntilHeight] = useState("");
    const [fromDepth, setFromDepth] = useState("");
    const [untilDepth, setUntilDepth] = useState("");
    const [fromWeight, setFromWeight] = useState("");
    const [untilWeight, setUntilWeight] = useState("");
    const [isDefault, setIsDefault] = useState(true);
    const [webshopHttpStatus, setWebshopHttpStatus] = useState("");
    const [webshopProductOnlineStatus, setWebshopProductOnlineStatus] = useState("");
    const [deliveryFulfillmentEarliest, setDeliveryFulfillmentEarliest] = useState(null);
    const [fulfillmentSourceNode, setFulfillmentSourceNode] = useState("");
    const [marketplace, setMarketplace] = useState("");
    const [marketplaceSeller, setMarketplaceSeller] = useState("");
    const [availability, setAvailability] = useState("");
    const [pickup, setPickup] = useState("");
    const [filters, setFilters] = useState({});

    const debouncedSearch = useDebounce(search, 300);

    const reset = () => {
        setEnv("")
        setCountry("")
        setCategory("")
        setSearch("")
        setLogisticsClass("")
        setAvailableOnWebshop("")
        setHasWarranties("")
        setHasServices("")
        setSalesLine("")
        setFromPrice("")
        setUntilPrice("")
        setFromWidth("")
        setUntilWidth("")
        setFromHeight("")
        setUntilHeight("")
        setFromDepth("")
        setUntilDepth("")
        setFromWeight("")
        setUntilWeight("")
        setWebshopHttpStatus("")
        setWebshopProductOnlineStatus("")
        setDeliveryFulfillmentEarliest(null)
        setFulfillmentSourceNode("")
        setMarketplace("")
        setMarketplaceSeller("")
        setAvailability("")
        setPickup("")
    }

    const filterValues = {
        env,
        country,
        logisticsClass,
        category,
        debouncedSearch,
        availableOnWebshop,
        hasWarranties,
        hasServices,
        salesLine,
        fromPrice,
        untilPrice,
        fromWidth,
        untilWidth,
        fromHeight,
        untilHeight,
        fromDepth,
        untilDepth,
        fromWeight,
        untilWeight,
        webshopHttpStatus,
        webshopProductOnlineStatus,
        deliveryFulfillmentEarliest,
        fulfillmentSourceNode,
        marketplace,
        marketplaceSeller,
        availability,
        pickup
    };
    useEffect(() => {
        setIsDefault(Object.values(filterValues).join("").replaceAll("false", "") === "");
        // eslint-disable-next-line
    }, Object.values(filterValues))

    useEffect(() => {
        const isDefaultValue = v => v === "" || v === false || v === null
        const newFilters = {}
        if (!isDefaultValue(env)) newFilters.byEnv =  Filter.byEnv(env)
        if (!isDefaultValue(country)) newFilters.byCountry =  Filter.byCountry(country)
        if (!isDefaultValue(category)) newFilters.byCategory =  Filter.byCategory(category)
        if (!isDefaultValue(logisticsClass)) newFilters.byLogisticsClass =  Filter.byLogisticsClass(logisticsClass)
        if (!isDefaultValue(availableOnWebshop)) newFilters.byWebshopBuyButtonEnabled =  Filter.byWebshopBuyButtonEnabled(availableOnWebshop)
        if (!isDefaultValue(debouncedSearch)) newFilters.bySearchTerm =  Filter.bySearchTerm(debouncedSearch)
        if (!isDefaultValue(hasServices)) newFilters.byHasServices =  Filter.byHasServices(hasServices)
        if (!isDefaultValue(hasWarranties)) newFilters.byHasWarranties =  Filter.byHasWarranties(hasWarranties)
        if (!isDefaultValue(salesLine)) newFilters.bySalesLine =  Filter.bySalesLine(salesLine)
        if (!isDefaultValue(fromPrice)) newFilters.byFromPrice =  Filter.byFromPrice(fromPrice)
        if (!isDefaultValue(untilPrice)) newFilters.byUntilPrice =  Filter.byUntilPrice(untilPrice)
        if (!isDefaultValue(fromWidth)) newFilters.byFromWidth =  Filter.byFromWidth(fromWidth)
        if (!isDefaultValue(untilWidth)) newFilters.byUntilWidth =  Filter.byUntilWidth(untilWidth)
        if (!isDefaultValue(fromHeight)) newFilters.byFromHeight =  Filter.byFromHeight(fromHeight)
        if (!isDefaultValue(untilHeight)) newFilters.byUntilHeight =  Filter.byUntilHeight(untilHeight)
        if (!isDefaultValue(fromDepth)) newFilters.byFromDepth =  Filter.byFromDepth(fromDepth)
        if (!isDefaultValue(untilDepth)) newFilters.byUntilDepth =  Filter.byUntilDepth(untilDepth)
        if (!isDefaultValue(fromWeight)) newFilters.byFromWeight =  Filter.byFromWeight(fromWeight)
        if (!isDefaultValue(untilWeight)) newFilters.byUntilWeight =  Filter.byUntilWeight(untilWeight)
        if (!isDefaultValue(webshopHttpStatus)) newFilters.byWebshopHttpStatus =  Filter.byWebshopHttpStatus(webshopHttpStatus)
        if (!isDefaultValue(webshopProductOnlineStatus)) newFilters.byWebshopProductOnlineStatus =  Filter.byWebshopProductOnlineStatus(webshopProductOnlineStatus)
        if (!isDefaultValue(deliveryFulfillmentEarliest)) newFilters.byDeliveryFulfillmentEarliestRange =  Filter.byDeliveryFulfillmentEarliestRange(deliveryFulfillmentEarliest)
        if (!isDefaultValue(fulfillmentSourceNode)) newFilters.byFulfillmentSourceNode =  Filter.byFulfillmentSourceNode(fulfillmentSourceNode)
        if (!isDefaultValue(marketplace)) newFilters.byIsMarketplaceProduct =  Filter.byIsMarketplaceProduct(marketplace)
        if (!isDefaultValue(marketplaceSeller)) newFilters.byMarketplaceSeller =  Filter.byMarketplaceSeller(marketplaceSeller)
        if (!isDefaultValue(availability)) newFilters.byAvailability =  Filter.byAvailability(availability)
        if (!isDefaultValue(pickup)) newFilters.byPickup =  Filter.byPickup(pickup)
        setFilters(newFilters)
        // eslint-disable-next-line
    }, Object.values(filterValues))

    return <CatalogFilterContext.Provider value={{
        filters,
        filterValues,
        isDefault,
        reset,
        env,
        setEnv,
        country,
        setCountry,
        category,
        setCategory,
        search,
        setSearch,
        debouncedSearch,
        logisticsClass,
        setLogisticsClass,
        availableOnWebshop,
        setAvailableOnWebshop,
        hasWarranties,
        setHasWarranties,
        hasServices,
        setHasServices,
        salesLine,
        setSalesLine,
        fromPrice,
        setFromPrice,
        untilPrice,
        setUntilPrice,
        fromWidth,
        setFromWidth,
        untilWidth,
        setUntilWidth,
        fromHeight,
        setFromHeight,
        untilHeight,
        setUntilHeight,
        fromDepth,
        setFromDepth,
        untilDepth,
        setUntilDepth,
        fromWeight,
        setFromWeight,
        untilWeight,
        setUntilWeight,
        webshopHttpStatus,
        setWebshopHttpStatus,
        webshopProductOnlineStatus,
        setWebshopProductOnlineStatus,
        deliveryFulfillmentEarliest,
        setDeliveryFulfillmentEarliest,
        fulfillmentSourceNode,
        setFulfillmentSourceNode,
        marketplace,
        setMarketplace,
        marketplaceSeller,
        setMarketplaceSeller,
        availability,
        setAvailability,
        pickup,
        setPickup
    }}>{children}</CatalogFilterContext.Provider>;
}

export default CatalogFilterContextBoundary;
