import React, {useContext, useRef, useState} from "react";
import Initiative from "../molecule/formControl/Initiative";
import {Box, Button, Checkbox, FormControl, FormHelperText, FormLabel, Typography} from "@mui/joy";
import {MissingTeamOrInitiativeCta} from "../molecule/MissingTeamOrInitiativeCta";
import {ErrorContext} from "../../context/ErrorContext";
import {useMutation, useQuery} from "@apollo/client";
import {createJiraIssue} from "../../api/mutation";
import {jiraInitiatives} from "../../api/query";
import {useErrorNotification} from "../../api/apolloClient";
import Loading from "../atom/Loading";
import Prio from "../molecule/formControl/Prio";
import {SERVICE_STAND_BY_SUPPORT} from "../../constant/services";
import FromToDate from "../molecule/formControl/FromToDate";
import RequestFor from "../molecule/formControl/RequestFor";
import ProductName from "../molecule/formControl/ProductName";
import Country from "../molecule/formControl/Country";
import {SuccessContext} from "../../context/SuccessContext";

const StandBy = () => {
    const [requestFor, setRequestFor] = useState("")
    const [requestingParty, setRequestingParty] = useState("")
    const [team, setTeam] = useState("")
    const [initiative, setInitiative] = useState("")
    const [country, setCountry] = useState("")
    const [initiativeInputValue, setInitiativeInputValue] = useState("");
    const [fromDate, setFromDate] = useState(new Date().toISOString().split('T')[0])
    let now = new Date();
    now.setDate(now.getDate() + 14)
    const [toDate, setToDate] = useState(now.toISOString().split('T')[0])
    const [prio, setPrio] = useState("Trivial")
    const [checkCreation, setCheckCreation] = useState(false)
    const [checkCoach, setCheckCoach] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const {setError} = useContext(ErrorContext);
    const {setSuccess} = useContext(SuccessContext);
    const [createJiraIssueMutation] = useMutation(createJiraIssue);
    const jiraInitiativesQuery = useQuery(jiraInitiatives, {notifyOnNetworkStatusChange: true})

    useErrorNotification(jiraInitiativesQuery.error, setError)

    const formRef = useRef(null);

    if (jiraInitiativesQuery.loading) return <Loading/>
    if (!jiraInitiativesQuery.data?.jiraInitiatives) return null
    const initiatives = jiraInitiativesQuery.data.jiraInitiatives.initiatives.map(it => `${it.key} ${it.summary}`);

    const creationLabel = "Test Product Creation";
    const coachLabel = "Coaching";


    function handleSubmit(e) {
        e.preventDefault();
        if (isSubmitting) return
        setIsSubmitting(true);
        createJiraIssueMutation({
                notifyOnNetworkStatusChange: true,
                variables: {
                    input: {
                        summary: `Stand-By Suppport from ${fromDate} to ${toDate} for ${requestingParty}${country ? ` ${country}` : null}`,
                        description: `please help by ${checkCreation ? creationLabel + "," : ""}${checkCoach ? coachLabel + "," : ""}`,
                        relatesToInitiativeKey: initiative ? initiative.split(" ")[0] : null,
                        prio,
                        service: "STAND_BY_SUPPORT"
                    }
                }
            }
        )
            .catch(err => {
                setIsSubmitting(false);
                return setError(err);
            })
            .then(res => {
                setIsSubmitting(false);
                setSuccess(res.data.createJiraIssue.createdIssue.key);
            })
    }

    return <>
        <Typography level={"h2"}>Stand-By Support</Typography>
        <Typography level={"title-md"}>{SERVICE_STAND_BY_SUPPORT.description}</Typography>
        <form ref={formRef} onSubmit={handleSubmit}>
            <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                <Box sx={{display: "flex", gap: 1, flexDirection: "column"}}>
                    <Box sx={{display: "flex", flexDirection: "row"}}>
                        <RequestFor value={requestFor} onChange={(e) => {
                            let v = e.target.value;
                            setRequestFor(v);
                            if (v === "country") setRequestingParty("country")
                        }}/>
                    </Box>
                    {requestFor === "country" &&
                        <Country orientation={"horizontal"} value={country}
                                 onChange={(e) => setCountry(e.target.value)}/>}
                    {requestFor === "team" && <ProductName value={team} onChange={(event, newValue) => {
                        setTeam(newValue);
                        setRequestingParty(newValue)
                    }}/>}
                    {requestFor === "initiative" && <Initiative
                        value={initiative}
                        onChange={(event, newValue) => {
                            setInitiative(newValue);
                            setRequestingParty(newValue)
                        }}
                        inputValue={initiativeInputValue}
                        onInputChange={(event, newInputValue) => {
                            setInitiativeInputValue(newInputValue);
                        }}
                        options={initiatives}/>}

                    <FormHelperText>We will prioritize your request based on this quarters business
                        priorities.</FormHelperText>
                    <MissingTeamOrInitiativeCta/>
                </Box>
                <Box sx={{display: "flex", flexWrap: "wrap", gap: 1}}>
                    <Prio value={prio} onChange={(e) => setPrio(e.target.value)}/>
                    <FromToDate fromValue={fromDate} toValue={toDate} onChangeFrom={(v) => setFromDate(v)}
                                onChangeTo={(v) => setToDate(v)}/>
                </Box>
                <FormLabel>
                    What kind of support do you need?
                </FormLabel>
                <FormControl>
                    <Checkbox required={!checkCreation && !checkCoach} checked={checkCreation}
                              onChange={() => setCheckCreation((previous) => !previous)}
                              label={creationLabel}/>
                    <FormHelperText>The tasks within this request would be limited to Test Product creation
                        only.</FormHelperText>
                </FormControl>
                <FormControl>
                    <Checkbox required={!checkCreation && !checkCoach} checked={checkCoach}
                              onChange={() => setCheckCoach((previous) => !previous)}
                              label={coachLabel}/>
                    <FormHelperText>The tasks here would be limited to providing Product specific coaching and how to
                        create Product Requests.</FormHelperText>
                </FormControl>
                <Button disabled={isSubmitting}
                        endDecorator={isSubmitting ?
                            <Typography fontSize="xx-small"><Loading/></Typography> : null}
                        type={"submit"}>Submit</Button>
            </Box>
        </form>
    </>
}

export default StandBy