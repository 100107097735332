import {gql} from "@apollo/client";

export const checkProduct = gql`
    mutation checkProduct($input: CheckProductInput!) {
        checkProduct(input: $input) {
            product {
                id
                env
                foreignId
                country
                salesLine
                lastScrapedAt {
                    seconds
                }
                latestWebshopScrapeResult {
                    buyButtonEnabled
                    httpStatusCode
                    title
                    url
                    createdAt {
                        seconds
                    }
                    hasOneTimePaymentWarranties
                    hasMonthlyPaymentWarranties
                    hasServices
                    ctaDebug {
                        price
                        delivery
                        pickup
                        isInAssortment
                        lifecycleStatus
                        onlineReleasedAt
                        isForcedOnline
                        productOnlineStatus
                        crossAvailabilityStatus
                        aggregatedOnlineStatus
                        crossLinkUrl
                        logisticsClass
                        isDigital
                    }
                    marketplaceSeller
                }
                latestSalesProductsApiScrapeResult {
                    httpStatusCode
                    kindOfProduct
                    lifeCycleStatus
                    logisticsClass
                    basicName
                    createdAt {
                        seconds
                    }
                    metaData {
                        createdAtInMasterData {
                            seconds
                        }
                    }
                    dimensions {
                        widthMeters
                        heightMeters
                        depthMeters
                        weightGram
                    }
                    dimensionsPredicted {
                        widthMeters
                        heightMeters
                        depthMeters
                        weightGram
                    }
                    packageDimensions {
                        widthMeters
                        heightMeters
                        depthMeters
                        weightGram
                    }
                    packageDimensionsPredicted {
                        widthMeters
                        heightMeters
                        depthMeters
                        weightGram
                    }
                }
                expectations {
                    id
                    enabled
                    attribute
                    matcher
                    value
                    checkResults {
                        id
                        factId
                        createdAt {
                            seconds
                        }
                        status
                        message
                    }
                }
                pickupFulfillmentIndications {
                    sameDayPickupSourceNodeIds
                    nextDayPickupSourceNodeIds
                }
                deliveryFulfillmentIndication {
                    earliest {
                        seconds
                    }
                    latest {
                        seconds
                    }
                    isFromWarehouse
                    isFromStore
                    fromSourceNode
                }
            }
        }
    }`;

export const removeProduct = gql`
    mutation removeProductFromCatalog($input: RemoveProductInput!) {
        removeProduct(input: $input) {
            productId
        }
    }
`

export const trackProduct = gql`
    mutation trackProduct($input: TrackProductInput!) {
        trackProduct(input: $input) {
            productId
        }
    }
`;


export const addExpectation = gql`
    mutation addExpectation($input: AddExpectationInput!) {
        addExpectation(input: $input) {
            expectationId
        }
    }`;

export const startProductCreationSagaV2 = gql`
    mutation startProductCreationSagaV2($input: StartProductCreationSagaInput!) {
        startProductCreationSagaV2(input: $input) {
            saga {
                id
                user
                referenceProductId {
                    country
                    salesLine
                    stage
                    productId
                }
                team
                initiative
                status
                error
                targetProductId {
                    country
                    salesLine
                    stage
                    productId
                }
                createdAt {
                    seconds
                }
                updatedAt {
                    seconds
                }
            }
        }
    }`;


export const createProductFromReference = gql`
    mutation createProductFromReference($input: CreateProductFromReferenceInput!) {
        createProductFromReference(input: $input) {
            sagaId
        }
    }`;


export const triggerMdngEdiForReference = gql`
    mutation triggerMdngEdiForReference($input: TriggerMdngEdiForReferenceInput!) {
        triggerMdngEdiForReference(input: $input) {
            referenceProductId {
                country
                salesLine
                stage
                productId
            }
            error
            targetProductId {
                country
                salesLine
                stage
                productId
            }
            useGcpEdi
        }
    }`;

export const removeExpectation = gql`
    mutation removeExpectation($input: RemoveExpectationInput!) {
        removeExpectation(input: $input) {
            expectationId
        }
    }`;


export const createJiraIssue = gql`
    mutation createJiraIssue($input: CreateJiraIssueInput!) {
        createJiraIssue(input: $input) {
            createdIssue {
                key
            }
        }
    }`;
