import React, {useState} from 'react';

export const ErrorContext = React.createContext(undefined);

function ErrorDisplayBoundary({ children }) {
  const [error, setError] = useState(null);

  return <ErrorContext.Provider value={{ error, setError }}>{children}</ErrorContext.Provider>;
}

export default ErrorDisplayBoundary;
