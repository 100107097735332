import {Comparing} from "./Comparing";
import React from "react";

export const HealthChecksDiff = ({current, to}) => {
    return <>
        {current && <>
            <dl>
                <Comparing current={current} to={to} on={"status"}/>
                <Comparing current={current} to={to} on={"message"}/>
            </dl>
        </>}
    </>;
};