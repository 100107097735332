class NumberRange {
    constructor(min, max) {
        this.min = min
        this.max = max
    }

    isInRange = (v, factor = 1) => {
        if (v === null) return false
        let number = +v;
        if (isNaN(number)) return false
        let outsideRange = number > +this.max * factor || number < +this.min * factor;
        return !outsideRange;

    }
}

export default NumberRange