class ReleaseNote {
    version;
    date;
    bulletPoints;
    title;
    /**
     * @param {String} version
     * @param {String} date
     * @param {String} title
     * @param {String[]} bulletPoints
     */
    constructor(version, date, title, bulletPoints) {
        this.title = title;
        this.version = version;
        this.date = date;
        this.bulletPoints = bulletPoints;
    }
}

export default ReleaseNote