import React from 'react';
import {useLocalStorage} from "@uidotdev/usehooks";
import releaseNotes from "../constant/releaseNotes";

export const ReleaseNotesReadContext = React.createContext(undefined);

function ReleaseNotesReadBoundary({ children }) {
  const [latestReleaseNoteRead, setLatestReleaseNoteRead] = useLocalStorage("latestReleaseNoteRead", "");
  return <ReleaseNotesReadContext.Provider value={{ latestReleaseNoteRead, setLatestReleaseNoteRead, latestReleaseNote: releaseNotes[0].version }}>{children}</ReleaseNotesReadContext.Provider>;
}

export default ReleaseNotesReadBoundary;
