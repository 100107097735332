import {FormControl, FormLabel} from "@mui/joy";
import {NativeSelect} from "@mui/material";
import React from "react";

const Prio = ({onChange, value}) => <FormControl>
    <FormLabel required>Priority</FormLabel>
    <NativeSelect fullWidth required value={value} onChange={onChange}>
        <option value="Trivial">Trivial</option>
        <option value="Medium">Medium</option>
        <option value="Major">Major</option>
        <option value="Critical">Critical</option>
        <option value="Blocker">Blocker</option>
    </NativeSelect>
</FormControl>;

export default Prio