import React, {useContext, useEffect, useMemo} from "react";
import {Box, FormControl, FormLabel, Input, Typography} from "@mui/joy";
import {CatalogFilterContext, Filter} from "../../context/CatalogFilterContext";
import {NativeSelect} from "@mui/material";
import {distinct, findMaxValue} from "../../model/utils";
import {RangeFilter} from "../molecule/RangeFilter";
import {track} from "../../api/http";
import {AuthContext} from "../../context/AuthContext";
import VisibleForAdmins from "../atom/VisibleForAdmins";
import NumberRange from "../../model/range";
import {SourceNodesContext} from "../../context/SourceNodesContext";
import {useSearchParams} from "react-router-dom";

function formatDate(number) {
    return new Intl.DateTimeFormat("sv-SE", {
        year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric"
    }).format(new Date(number)).replace(" ", "T");
}

export function CatalogFilter({products, filtered}) {
    const {user} = useContext(AuthContext)
    const {
        filters,
        filterValues,
        env,
        setEnv,
        country,
        setCountry,
        category,
        setCategory,
        search,
        setSearch,
        debouncedSearch,
        logisticsClass,
        setLogisticsClass,
        availableOnWebshop,
        setAvailableOnWebshop,
        hasWarranties,
        setHasWarranties,
        hasServices,
        setHasServices,
        salesLine,
        setSalesLine,
        fromPrice,
        setFromPrice,
        untilPrice,
        setUntilPrice,
        fromWidth,
        setFromWidth,
        untilWidth,
        setUntilWidth,
        fromHeight,
        setFromHeight,
        untilHeight,
        setUntilHeight,
        fromDepth,
        setFromDepth,
        untilDepth,
        setUntilDepth,
        fromWeight,
        setFromWeight,
        untilWeight,
        setUntilWeight,
        webshopHttpStatus,
        setWebshopHttpStatus,
        webshopProductOnlineStatus,
        setWebshopProductOnlineStatus,
        deliveryFulfillmentEarliest,
        setDeliveryFulfillmentEarliest,
        fulfillmentSourceNode,
        setFulfillmentSourceNode,
        marketplace,
        setMarketplace,
        marketplaceSeller,
        setMarketplaceSeller,
        availability,
        setAvailability,
        pickup,
        setPickup
    } = useContext(CatalogFilterContext);
    const {sourceNodesByForeignId} = useContext(SourceNodesContext);
    useEffect(() => {
        track("filter", [`user=${user?.username}`, `role=${user?.isAdmin ? "admin" : "user"}`, `isDefault=${Object.values(filterValues).join("") === "falsefalse"}`, `filters=${JSON.stringify(filterValues)}`])
        // eslint-disable-next-line
    }, Object.values(filterValues))

    let [params, setParams] = useSearchParams();

    useEffect(() => {
        const q = params.get("q");
        if (q) setSearch(q)
    }, [params, setSearch])

    useEffect(() => {
        if (debouncedSearch) params.set("q", debouncedSearch);
        if (!debouncedSearch) params.delete("q")
        setParams(params)
    }, [params, setParams, debouncedSearch])

    const kindsOfProduct = [category, ...filtered.map(a => a.latestSalesProductsApiScrapeResult?.kindOfProduct)];
    const allLogisticsClasses = useMemo(() => {
        return products.map(a => a.latestSalesProductsApiScrapeResult?.logisticsClass);
    }, [products]);
    const logisticsClasses = [logisticsClass, ...allLogisticsClasses, "unknown"];
    let allCountries = useMemo(() => {
        return products.map(a => a.country);
    }, [products]);
    let countries = distinct([country, ...allCountries]);
    const maxPrice = filtered.map(it => it.latestWebshopScrapeResult?.ctaDebug?.price).reduce(findMaxValue, undefined)
    const maxWidth = filtered.map(it => it.latestSalesProductsApiScrapeResult?.packageDimensions?.widthMeters || it.latestSalesProductsApiScrapeResult?.packageDimensionsPredicted?.widthMeters).reduce(findMaxValue, undefined)
    const maxHeight = filtered.map(it => it.latestSalesProductsApiScrapeResult?.packageDimensions?.heightMeters || it.latestSalesProductsApiScrapeResult?.packageDimensionsPredicted?.heightMeters).reduce(findMaxValue, undefined)
    const maxDepth = filtered.map(it => it.latestSalesProductsApiScrapeResult?.packageDimensions?.depthMeters || it.latestSalesProductsApiScrapeResult?.packageDimensionsPredicted?.depthMeters).reduce(findMaxValue, undefined)
    const maxWeight = filtered.map(it => it.latestSalesProductsApiScrapeResult?.packageDimensions?.weightGram || it.latestSalesProductsApiScrapeResult?.packageDimensionsPredicted?.weightGram).reduce(findMaxValue, undefined)
    const sourceNodeIds = distinct(["", ...filtered.map(a => a.deliveryFulfillmentIndication?.fromSourceNode), "unknown"]);
    const webshopHttpStatusCodes = useMemo(() => {
        return products.map(a => a.latestWebshopScrapeResult?.httpStatusCode);
    }, [products]);
    const webshopHttpStati = distinct([+webshopHttpStatus, ...webshopHttpStatusCodes, "unknown"]);
    let allWebshopProductOnlineStati = useMemo(() => {
        return products.map(a => a.latestWebshopScrapeResult?.ctaDebug?.productOnlineStatus);
    }, [products]);
    const webshopProductOnlineStati = distinct([webshopProductOnlineStatus, ...allWebshopProductOnlineStati, "unknown"]);
    const marketplaceSellers = distinct([marketplaceSeller, ...filtered.map(a => a.latestWebshopScrapeResult?.marketplaceSeller)]).sort();

    if (countries.includes('DE')) {
        countries = countries.filter(country => country !== 'DE')
        countries.push('DE MM')
        countries.push('DE SE')
    }

    const countryNameByCode = new Map([
        ["AT", "Austria"],
        ["BE", "Belgium"],
        ["DE MM", "Germany (Mediamarkt)"],
        ["DE SE", "Germany (Saturn)"],
        ["HU", "Hungary"],
        ["IT", "Italy"],
        ["LU", "Luxembourg"],
        ["NL", "Netherlands"],
        ["PL", "Poland"],
        ["PT", "Portugal"],
        ["ES", "Spain"],
        ["SE", "Sweden"],
        ["CH", "Switzerland"],
        ["TR", "Turkey"],
    ])

    const applyAllFiltersBut = skippedFilters => {
        let filtered = products
        Object.keys(filters).filter(key => !skippedFilters.includes(key)).forEach(key => filtered = filtered.filter(filters[key]))
        return filtered;
    };

    const appliedAllFiltersButEnv = applyAllFiltersBut(["byEnv"]);
    const numberOfProductsForEnv = e => appliedAllFiltersButEnv.filter(Filter.byEnv(e)).length;

    const appliedAllFiltersButOnlyAvailable = applyAllFiltersBut(["byWebshopBuyButtonEnabled"]);
    const numberOfProductsForAvailability = a => appliedAllFiltersButOnlyAvailable
        .filter(Filter.byWebshopBuyButtonEnabled(a)).length;

    const appliedAllFiltersButCountry = applyAllFiltersBut(["byCountry", "bySalesLine"]);
    const numberOfProductsForCountry = c => {
        if (!c.startsWith('DE ')) {
            return appliedAllFiltersButCountry
                .filter(Filter.byCountry(c)).length;
        }
        return appliedAllFiltersButCountry
            .filter(Filter.byCountry(c.split(' ')[0]))
            .filter(Filter.bySalesLine(c.split(' ')[1])).length;
    };

    const appliedAllFiltersButCategory = applyAllFiltersBut(["byCategory"]);
    const numberOfProductsForCategory = c => appliedAllFiltersButCategory
        .filter(Filter.byCategory(c)).length;

    const appliedAllFiltersButLogisticsClass = applyAllFiltersBut(["byLogisticsClass"]);
    const numberOfProductsForLogisticsClass = l => appliedAllFiltersButLogisticsClass
        .filter(Filter.byLogisticsClass(l)).length;

    const appliedAllFiltersButHasWarranties = applyAllFiltersBut(["byHasWarranties"]);
    const numberOfProductsForHasWarranties = a => appliedAllFiltersButHasWarranties
        .filter(Filter.byHasWarranties(a)).length;

    const appliedAllFiltersButHasServices = applyAllFiltersBut(["byHasServices"]);
    const numberOfProductsForHasServices = a => appliedAllFiltersButHasServices
        .filter(Filter.byHasServices(a)).length;

    const appliedAllFiltersButWebshopHttpStatus = applyAllFiltersBut(["byWebshopHttpStatus"]);
    const numberOfProductsForWebshopHttpStatus = a => appliedAllFiltersButWebshopHttpStatus.filter(Filter.byWebshopHttpStatus(a)).length;

    const appliedAllFiltersButWebshopProductOnlineStatus = applyAllFiltersBut(["byWebshopProductOnlineStatus"]);
    const numberOfProductsForWebshopProductOnlineStatus = a => appliedAllFiltersButWebshopProductOnlineStatus.filter(Filter.byWebshopProductOnlineStatus(a)).length;

    const appliedAllFiltersButByFulfillmentSourceNode = applyAllFiltersBut(["byFulfillmentSourceNode"]);
    const numberOfProductsForByFulfillmentSourceNode = a => appliedAllFiltersButByFulfillmentSourceNode.filter(Filter.byFulfillmentSourceNode(a)).length;

    const appliedAllFiltersButIsMarketplace = applyAllFiltersBut(["byIsMarketplaceProduct"]);
    const numberOfProductsForIsMarketplace = a => appliedAllFiltersButIsMarketplace.filter(Filter.byIsMarketplaceProduct(a)).length;

    const appliedAllFiltersButMarketplaceSeller = applyAllFiltersBut(["byMarketplaceSeller"]);
    const numberOfProductsForMarketplaceSeller = a => appliedAllFiltersButMarketplaceSeller.filter(Filter.byMarketplaceSeller(a)).length;

    const appliedAllFiltersButByAvailability = applyAllFiltersBut(["byAvailability"]);
    const numberOfProductsByAvailability = a => appliedAllFiltersButByAvailability.filter(Filter.byAvailability(a)).length;

    const appliedAllFiltersButByPickup = applyAllFiltersBut(["byPickup"]);
    const numberOfProductsByPickup = a => appliedAllFiltersButByPickup.filter(Filter.byPickup(a)).length;

    return <>
        <Typography level={"body-sm"}>Basic Filter</Typography>
        <Box sx={{display: "flex", gap: 2, flexDirection: "column"}} role={"search"}>
            <FormControl>
                <FormLabel>Text</FormLabel>
                <Input variant={"soft"} value={search} placeholder={"like ID, category, .."}
                       onChange={e => setSearch(e.target.value)}/>
            </FormControl>

            <FormControl>
                <FormLabel>System Environment</FormLabel>
                <NativeSelect value={env} variant={"filled"} onChange={(e) => setEnv(e.target.value)}>
                    <option value="">all ({numberOfProductsForEnv("")})</option>
                    {["INT", "QA"].map(c => <option key={c}
                                                    value={c}>{c} ({numberOfProductsForEnv(c)})</option>)}
                </NativeSelect>
            </FormControl>

            <FormControl>
                <FormLabel>Country</FormLabel>
                <NativeSelect value={country.startsWith('DE') ? country + ' ' + salesLine : country} variant={"filled"}
                              onChange={(e) => {
                                  const value = e.target.value
                                  if (!value.startsWith('DE ')) {
                                      setCountry(e.target.value)
                                      setSalesLine('')
                                      return
                                  }
                                  const salesLine = value.split(' ')[1]
                                  setCountry('DE')
                                  setSalesLine(salesLine)
                              }}>
                    <option value="">all ({numberOfProductsForCountry("")})</option>
                    {
                        distinct(countries)
                            .sort((it, other) => countryNameByCode.get(it)?.localeCompare(countryNameByCode.get(other)))
                            .map(c => <option key={c}
                                              value={c}>{countryNameByCode.get(c) || c} ({numberOfProductsForCountry(c)})</option>)
                    }
                </NativeSelect>
            </FormControl>

            <Typography level={"body-sm"}>Webshop Product Details Page</Typography>
            <VisibleForAdmins>
                <FormControl>
                    <FormLabel>Http Status</FormLabel>
                    <NativeSelect value={webshopHttpStatus} variant={"filled"}
                                  onChange={(e) => setWebshopHttpStatus(e.target.value)}>
                        <option value="">all ({numberOfProductsForWebshopHttpStatus("")})</option>
                        {webshopHttpStati
                            .sort()
                            .map(v => <option
                                key={v}
                                value={v}>{v} ({numberOfProductsForWebshopHttpStatus(v)})</option>)}
                    </NativeSelect>
                </FormControl>
            </VisibleForAdmins>

            <FormControl>
                <FormLabel>Product Online Status</FormLabel>
                <NativeSelect value={webshopProductOnlineStatus} variant={"filled"}
                              onChange={(e) => setWebshopProductOnlineStatus(e.target.value)}>
                    <option value="">all ({numberOfProductsForWebshopProductOnlineStatus("")})</option>
                    {webshopProductOnlineStati
                        .sort()
                        .map(v => <option
                            key={v}
                            value={v}>{v.toLowerCase().replaceAll("_", " ")} ({numberOfProductsForWebshopProductOnlineStatus(v)})</option>)}
                </NativeSelect>
            </FormControl>


            <FormControl>
                <FormLabel>Buy Button</FormLabel>
                <NativeSelect value={availableOnWebshop} variant={"filled"}
                              onChange={(e) => setAvailableOnWebshop(e.target.value)}>
                    <option value="">all ({numberOfProductsForAvailability("")})</option>
                    <option value={"f"}>disabled ({numberOfProductsForAvailability("f")})</option>
                    <option value={"t"}>enabled ({numberOfProductsForAvailability("t")})</option>
                    <option value="unknown">unknown ({numberOfProductsForAvailability("unknown")})</option>
                </NativeSelect>
            </FormControl>

            <FormControl>
                <FormLabel>Warranties</FormLabel>
                <NativeSelect value={hasWarranties} variant={"filled"}
                              onChange={(e) => setHasWarranties(e.target.value)}>
                    <option value="">all ({numberOfProductsForHasWarranties("")})</option>
                    <option value={"none"}>without ({numberOfProductsForHasWarranties("none")})</option>
                    <option value={"once"}>pay once ({numberOfProductsForHasWarranties("once")})</option>
                    <option value={"monthly"}>pay monthly ({numberOfProductsForHasWarranties("monthly")})</option>
                    <option value="unknown">unknown ({numberOfProductsForHasWarranties("unknown")})</option>
                </NativeSelect>
            </FormControl>
            <FormControl>
                <FormLabel>Services</FormLabel>
                <NativeSelect value={hasServices} variant={"filled"} onChange={(e) => setHasServices(e.target.value)}>
                    <option value="">all ({numberOfProductsForHasServices("")})</option>
                    <option value={"f"}>without ({numberOfProductsForHasServices("f")})</option>
                    <option value={"t"}>with ({numberOfProductsForHasServices("t")})</option>
                    <option value="unknown">unknown ({numberOfProductsForHasServices("unknown")})</option>
                </NativeSelect>
            </FormControl>
            <VisibleForAdmins>
                <FormControl>
                    <FormLabel>Marketplace Product</FormLabel>
                    <NativeSelect value={marketplace} variant={"filled"}
                                  onChange={(e) => setMarketplace(e.target.value)}>
                        <option value="">all ({numberOfProductsForIsMarketplace("")})</option>
                        <option value={"f"}>exclude ({numberOfProductsForIsMarketplace("f")})</option>
                        <option value={"t"}>only ({numberOfProductsForIsMarketplace("t")})</option>
                    </NativeSelect>
                </FormControl>
                <FormControl>
                    <FormLabel>Marketplace Seller</FormLabel>
                    <NativeSelect value={marketplaceSeller} variant={"filled"}
                                  onChange={(e) => setMarketplaceSeller(e.target.value)}>
                        <option value="">all ({numberOfProductsForMarketplaceSeller("")})</option>
                        {marketplaceSellers
                            .sort()
                            .map(v => <option
                                key={v}
                                value={v}>{v} ({numberOfProductsForMarketplaceSeller(v)})</option>)}
                    </NativeSelect>
                </FormControl>
            </VisibleForAdmins>

            <RangeFilter labels={['Min Price', 'Max Price']}
                         fromState={[fromPrice, setFromPrice]}
                         untilState={[untilPrice, setUntilPrice]}
                         upperBound={maxPrice} factor={1}/>


            <Typography level={"body-sm"}>Product</Typography>


            <FormControl>
                <FormLabel>Kind Of Product</FormLabel>
                <NativeSelect value={category} variant={"filled"} sx={{textTransform: "capitalize"}}
                              onChange={(e) => setCategory(e.target.value)}>
                    <option value="">all ({numberOfProductsForCategory("")})</option>
                    {distinct(kindsOfProduct).sort().map(kindOfProduct => <option
                        key={kindOfProduct}
                        value={kindOfProduct}>{kindOfProduct.toLowerCase().replaceAll("_", " ")} ({numberOfProductsForCategory(kindOfProduct)})</option>)}
                </NativeSelect>
            </FormControl>

            <FormControl>
                <FormLabel>Logistics Class</FormLabel>
                <NativeSelect value={logisticsClass} variant={"filled"}
                              onChange={(e) => setLogisticsClass(e.target.value)}>
                    <option value="">all ({numberOfProductsForLogisticsClass("")})</option>
                    {distinct(logisticsClasses)
                        .sort((a, b) => +a - +b)
                        .map(v => <option
                            key={v}
                            value={v}>{v} ({numberOfProductsForLogisticsClass(v)})</option>)}
                </NativeSelect>
            </FormControl>

            <FormLabel>Packaging Dimensions</FormLabel>
            {
                [
                    ['Min Width cm', 'Max Width cm', fromWidth, setFromWidth, untilWidth, setUntilWidth, maxWidth, 100],
                    ['Min Height cm', 'Max Height cm', fromHeight, setFromHeight, untilHeight, setUntilHeight, maxHeight, 100],
                    ['Min Depth cm', 'Max Depth cm', fromDepth, setFromDepth, untilDepth, setUntilDepth, maxDepth, 100],
                    ['Min Weight kg', 'Max Weight kg', fromWeight, setFromWeight, untilWeight, setUntilWeight, maxWeight, 0.001],
                ].map(([label1, label2, from, setFrom, until, setUntil, max, factor]) => <RangeFilter
                    key={label1 + label2}
                    labels={[label1, label2]}
                    fromState={[from, setFrom]}
                    untilState={[until, setUntil]}
                    upperBound={max} factor={factor}/>)}

            <Typography level={"body-sm"}>Delivery Fulfillment</Typography>
            <FormLabel>Earliest Between</FormLabel>
            <FormControl>
                <FormLabel>From</FormLabel>
                <Input variant={"soft"} type={"datetime-local"}
                       value={(deliveryFulfillmentEarliest?.min && formatDate(deliveryFulfillmentEarliest?.min * 1000)) || ""}
                       onChange={e => {
                           let number = Date.parse(e.target.value);
                           if (isNaN(number)) return setDeliveryFulfillmentEarliest(null)
                           return setDeliveryFulfillmentEarliest(new NumberRange(number / 1000, Math.max(deliveryFulfillmentEarliest?.max || Date.now() / 1000, number / 1000)));
                       }}/>
            </FormControl>
            <FormControl>
                <FormLabel>Until</FormLabel>
                <Input variant={"soft"} type={"datetime-local"}
                       value={(deliveryFulfillmentEarliest?.max && formatDate(deliveryFulfillmentEarliest?.max * 1000)) || ""}
                       onChange={e => {
                           let number = Date.parse(e.target.value);
                           if (isNaN(number)) return setDeliveryFulfillmentEarliest(null)
                           return setDeliveryFulfillmentEarliest(new NumberRange(Math.min(deliveryFulfillmentEarliest?.min || Date.now() / 1000, number / 1000), number / 1000));
                       }}/>
            </FormControl>
            <FormControl>
                <FormLabel>Source</FormLabel>
                <NativeSelect value={fulfillmentSourceNode} variant={"filled"}
                              onChange={(e) => setFulfillmentSourceNode(e.target.value)}>
                    <option value="">all ({numberOfProductsForByFulfillmentSourceNode("")})</option>
                    {sourceNodeIds
                        .sort((a, b) => +a - +b)
                        .map(v => {
                            let sourceNode = sourceNodesByForeignId.get(v);
                            if (sourceNode) {
                                return <option
                                    key={v}
                                    value={v}>
                                    {v} ({numberOfProductsForByFulfillmentSourceNode(v)}) {sourceNode.type} {sourceNode.name}
                                </option>;
                            }
                            return <option
                                key={v}
                                value={v}>
                                {v} ({numberOfProductsForByFulfillmentSourceNode(v)})
                            </option>;
                        })}
                </NativeSelect>
            </FormControl>
            <FormControl>
                <FormLabel>Delivery Variant</FormLabel>
                <NativeSelect value={availability} variant={"filled"}
                              onChange={(e) => setAvailability(e.target.value)}>
                    <option value="">all ({numberOfProductsByAvailability("")})</option>
                    <option value={"hd warehouse"}>HD, home delivery from warehouse
                        ({numberOfProductsByAvailability("hd warehouse")})
                    </option>
                    ;
                    <option value={"hd store"}>SFS, home delivery from store
                        ({numberOfProductsByAvailability("hd store")})</option>;
                    <option value={"unknown"}>unknown ({numberOfProductsByAvailability("unknown")})</option>;
                </NativeSelect>
            </FormControl>

            <Typography level={"body-sm"}>Pickup Fulfillment</Typography>
            <FormControl>
                <FormLabel>Pickup Variant</FormLabel>
                <NativeSelect value={pickup} variant={"filled"}
                              onChange={(e) => setPickup(e.target.value)}>
                    <option value="">all ({numberOfProductsByPickup("")})</option>
                    <option value={"pickup sameday"}>SDPU, sameday pickup
                        ({numberOfProductsByPickup("pickup sameday")})
                    </option>
                    ;
                    <option value={"pickup nextday"}>NDPU, pickup next day
                        ({numberOfProductsByPickup("pickup nextday")})</option>;
                    <option value={"unknown"}>unknown ({numberOfProductsByPickup("unknown")})</option>;
                </NativeSelect>
            </FormControl>
        </Box>
    </>
}

export default CatalogFilter