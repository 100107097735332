import {FormControl, FormLabel, List} from "@mui/joy";
import MultiCheckbox from "../MultiCheckbox";
import React from "react";

function SalesLinesMultiSelect({state}) {
    return <FormControl>
        <FormLabel required>Sales Line</FormLabel>
        <List orientation={"horizontal"} sx={{gap: 1}}>
            <MultiCheckbox required value={"MM"} state={state}/>
            <MultiCheckbox required value={"SE"} state={state}/>
        </List>
    </FormControl>;
}

export default SalesLinesMultiSelect