import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {productCreationSaga} from "../../api/query";
import {useContext, useEffect, useState} from "react";
import {ErrorContext} from "../../context/ErrorContext";
import {useErrorNotification} from "../../api/apolloClient";
import Loading from "../atom/Loading";
import {Box, Card, Chip, Typography} from "@mui/joy";
import LocalDateTime from "../atom/LocalDateTime";
import {startProductCreationSagaV2} from "../../api/mutation";
import {LinkTargetBlank} from "../atom/LinkTartgetBlank";

const TestProductRequest = () => {
    let {id} = useParams()
    const query = useQuery(productCreationSaga, {
        variables: {
            input: {id}
        }, notifyOnNetworkStatusChange: true
    })
    const {loading, data, error, refetch, startPolling, stopPolling} = query;
    let saga = data?.productCreationSaga?.saga
    const [startProductCreationSagaMutation, {
        mutationLoading, mutationError
    }] = useMutation(startProductCreationSagaV2);
    const [processing, setProcessing] = useState(false)
    const {setError} = useContext(ErrorContext);
    useErrorNotification(error, setError)
    useErrorNotification(mutationError, setError)


    useEffect(() => {
        if (saga?.status === "NEW") {
            startProductCreationSagaMutation({variables: {input: {sagaId: id}}, notifyOnNetworkStatusChange: true})
                .then(_ => refetch({input: {id}}))
        }
    }, [saga, id, refetch, startProductCreationSagaMutation, processing])
    useEffect(() => {
        if (saga && saga.status !== "NEW" && !processing) {
            setProcessing(true)
        }
    }, [saga, processing, setProcessing, saga?.updatedAt.seconds])

    useEffect(() => {
        if (saga?.status !== "NEW") {
            startPolling(5000)
        }
        return stopPolling
    }, [saga, id, startPolling, stopPolling, setProcessing, saga?.updatedAt.seconds])

    useEffect(() => {
        if (saga?.status === "ERROR") {
            stopPolling()
            setProcessing(false)
        }
    }, [saga, id, startPolling, stopPolling, setProcessing, saga?.updatedAt.seconds])

    useEffect(() => {
        if (saga?.status === "SUCCESS") {
            stopPolling()
            setProcessing(false)
        }
    }, [saga, id, startPolling, stopPolling, setProcessing, saga?.updatedAt.seconds])

    if (loading || mutationLoading) return <Loading/>

    const referenceProduct = saga.referenceProductId;
    const targetProduct = saga.targetProductId
    return <>
        <Typography level={"h2"}>Requested Test Product</Typography>
        <Typography>Request received. We will notify you via mail to {saga.user}@mediamarktsaturn.com</Typography>
        <Card variant={"soft"}>
            {processing ? <Box mr={1} right={0} top={0} position={"absolute"}>
                <Loading/>
            </Box> : null}
            <Typography level={"h3"}><Chip>status: {saga.status.toLowerCase().replaceAll("_", " ")}</Chip></Typography>
            {saga.status === "SUCCESS" && <><LinkTargetBlank href={"https://jira.media-saturn.com/browse/" + saga.jiraKey}>{saga.jiraKey}</LinkTargetBlank></>}
            <Typography>reference
                product: {referenceProduct.stage}-{referenceProduct.country}-{referenceProduct.salesLine}-{referenceProduct.productId}</Typography>
            <Typography>target
                product: {targetProduct.stage}-{targetProduct.country}-{targetProduct.salesLine}-{targetProduct.productId || "not-yet-known"}</Typography>
            <Typography>by: {saga.user}</Typography>
            <Typography>for: {saga.team || saga.initiative || "Country"}</Typography>
            <Typography level={"body-xs"}>
                created <LocalDateTime
                epochSeconds={saga.createdAt.seconds}/> last change <LocalDateTime
                epochSeconds={saga.updatedAt.seconds}/>
            </Typography>
            {saga.error && <>
                <Typography level={"h4"} color={"danger"}>An error has occoured. The team has been notified and will look into this issue and get in touch with you. You can certainly try again, the result might not change though.</Typography>
                <Typography>{saga.error}</Typography>
            </>}
        </Card></>
}

export default TestProductRequest