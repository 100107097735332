import {Button, Typography} from "@mui/joy";

const StatusIndicator = ({status, setFocus, checkId, focussed, group}) => {
    let abbreviatedGroup = group;
    if (abbreviatedGroup.length > 4) {
        abbreviatedGroup = abbreviatedGroup.at(0) + (abbreviatedGroup.length -2) +  abbreviatedGroup.at(abbreviatedGroup.length -1)
    }
    const handleClick = () => {
        setFocus(checkId)
    }
    let color;
    if (status === 'FAILURE') {
        color = "danger";
    } else {
        color = status === 'SUCCESS' ? "success" : status === 'COMPROMISED' ? "warning" : "primary";
    }

    let variant;
    let isFocussed = checkId === focussed;
    if (isFocussed) {
        variant = "soft";
    } else if (!isFocussed && (status === null) ) {
        variant = "plain";
    } else if (!isFocussed && status === "IGNORED") {
        variant = "outlined";
    } else {
        variant = "solid";
    }
    return <Button sx={{padding: 0.5}} color={color} variant={variant} type={"button"} onClick={handleClick}><Typography sx={{margin: 0, padding: 0}} level={"title-sm"}>{abbreviatedGroup}</Typography></Button>;
}

const ResultsIndicator = ({results, setFocus, focussed}) => {
    if (!results || results.length < 1) return null
    return <>
        {results.map(it => <StatusIndicator focussed={focussed} setFocus={setFocus} key={it.id} checkId={it.check.id}
    status={it.status} group={it.check.path.reduce((path, otherPath) => otherPath)}/>)}
    </>
}

export default ResultsIndicator