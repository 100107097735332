import React, {useContext, useRef, useState} from "react";
import {
    Autocomplete,
    AutocompleteOption,
    Box,
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    ListItemContent,
    Textarea,
    Typography
} from "@mui/joy";
import MultiCheckbox from "../molecule/MultiCheckbox";
import {NativeSelect} from "@mui/material";
import {ProductToCreate} from "../../model/product";
import Table from "@mui/joy/Table";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {MissingTeamOrInitiativeCta} from "../molecule/MissingTeamOrInitiativeCta";
import {SourceNodesContext} from "../../context/SourceNodesContext";
import {ErrorContext} from "../../context/ErrorContext";
import {useErrorNotification} from "../../api/apolloClient";
import Loading from "../atom/Loading";
import {useMutation, useQuery} from "@apollo/client";
import {createJiraIssue} from "../../api/mutation";
import {AuthContext} from "../../context/AuthContext";
import {jiraInitiatives} from "../../api/query";
import RequestFor from "../molecule/formControl/RequestFor";
import ProductName from "../molecule/formControl/ProductName";
import Initiative from "../molecule/formControl/Initiative";
import Stage from "../molecule/formControl/Stage";
import Country from "../molecule/formControl/Country";
import SalesLinesMultiSelect from "../molecule/formControl/SalesLinesMultiSelect";
import Prio from "../molecule/formControl/Prio";
import DueDate from "../molecule/formControl/DueDate";
import SapPlantId from "../molecule/formControl/SapPlantId";
import {SuccessContext} from "../../context/SuccessContext";
import {intSourceNodes, qaSourceNodes } from "../../constant/sourceNodes"

const ProductCreationQuestionnaire = () => {
    const {user} = useContext(AuthContext)
    const [productsToCreate, setProductsToCreate] = useState([ProductToCreate.emptyProduct()])
    const [requestFor, setRequestFor] = useState("")
    const [team, setTeam] = useState("")
    const [initiative, setInitiative] = useState("")
    const [initiativeInputValue, setInitiativeInputValue] = useState("");

    const [country, setCountry] = useState("");
    const [env, setEnv] = useState("");
    const salesLinesState = useState(["MM"]);
    const [line, setLine] = useState(productsToCreate.length - 1)
    const [reference, setReference] = useState(productsToCreate[line].reference)
    const featuresState = useState(productsToCreate[line].features);
    const [features, setFeatures] = featuresState
    const [salesLines, setSalesLines] = salesLinesState
    const [serviceRequirements, setServiceRequirements] = useState(productsToCreate[line].service);
    const [warrantyRequirements, setWarrantyRequirements] = useState(productsToCreate[line].warranty);
    const [onlineAvailability, setOnlineAvailability] = useState(productsToCreate[line].onlineAvailability)
    const [storeAvailability, setStoreAvailability] = useState(productsToCreate[line].storeAvailability)
    const [stores, setStores] = useState(productsToCreate[line].physicalStores)
    const [logisticsClass, setLogisticsClass] = useState(productsToCreate[line].logisticsClass)
    const [morphologicalData, setMorphologicalData] = useState(productsToCreate[line].morphologicalData)
    const [fspId, setFspId] = useState(productsToCreate[line].fspId)
    const [sapPlantId, setSapPlantID] = useState(productsToCreate[line].sapPlantId)
    const [price, setPrice] = useState(productsToCreate[line].price)
    const [furtherDetails, setFurtherDetails] = useState(productsToCreate[line].furtherDetails)
    const [isSubmitting, setIsSubmitting] = useState(false)
    let now = new Date();
    now.setDate(now.getDate() + 14)
    const [dueDate, setDueDate] = useState(now.toISOString().split('T')[0])
    const [prio, setPrio] = useState("Trivial")

    const {sourceNodesByForeignId, query: {loading, error}} = useContext(SourceNodesContext);
    const {setError} = useContext(ErrorContext);
    const {setSuccess} = useContext(SuccessContext);
    const [createJiraIssueMutation] = useMutation(createJiraIssue);
    const jiraInitiativesQuery = useQuery(jiraInitiatives, {notifyOnNetworkStatusChange: true})

    useErrorNotification(jiraInitiativesQuery.error, setError)
    useErrorNotification(error, setError)

    const formRef = useRef(null);

    if (loading || jiraInitiativesQuery.loading) return <Loading/>
    if (!jiraInitiativesQuery.data?.jiraInitiatives) return null
    const initiatives = jiraInitiativesQuery.data.jiraInitiatives.initiatives.map(it => `${it.key} ${it.summary}`);

    let currentProducts = [...productsToCreate]
    currentProducts.splice(line, 1, new ProductToCreate(
        reference,
        serviceRequirements,
        warrantyRequirements,
        onlineAvailability,
        storeAvailability,
        stores,
        logisticsClass,
        morphologicalData,
        fspId,
        sapPlantId,
        price,
        furtherDetails,
        features
    ));

    let hasReference = currentProducts.find(it => it.reference !== "");
    let hasOnlineAvailability = currentProducts.find(it => it.onlineAvailability !== "");
    let hasPhysicalStore = currentProducts.find(it => it.storeAvailability !== "");
    let hasWarranty = currentProducts.find(it => it.features.includes("Attached Warranty") && it.warranty !== "");
    let hasService = currentProducts.find(it => it.features.includes("Attached Service") && it.service !== "");
    let hasPrice = currentProducts.find(it => it.features.includes("Specific Price") && it.price !== "");
    let hasLogisticsClass = currentProducts.find(it => it.features.includes("Logistics Class") && it.logisticsClass !== "");
    let hasMorphData = currentProducts.find(it => it.features.includes("Morphological Data") && it.morphologicalData !== "");
    let hasFspId = currentProducts.find(it => it.features.includes("FSP ID") && it.fspId !== "");
    let hasSapPlantId = currentProducts.find(it => it.features.includes("SAP Plant ID") && it.sapPlantId !== "");
    let hasMore = currentProducts.find(it => it.furtherDetails !== "");


    const handleAdd = () => {
        if (!formRef.current.checkValidity()) {
            formRef.current.reportValidity()
            return
        }
        setProductsToCreate([...currentProducts, ProductToCreate.emptyProduct()]);
        setLine(productsToCreate.length)
    };

    const handleAddNew = () => {
        if (!formRef.current.checkValidity()) {
            formRef.current.reportValidity()
            return
        }
        setProductsToCreate([...currentProducts, ProductToCreate.emptyProduct()]);
        setLine(productsToCreate.length)
        setReference("")
        setFeatures([])
        setServiceRequirements("")
        setWarrantyRequirements("")
        setOnlineAvailability("")
        setStoreAvailability("")
        setStores([])
        setLogisticsClass("")
        setMorphologicalData("")
        setFspId("")
        setSapPlantID("")
        setPrice("")
        setFurtherDetails("")
    };

    let description = "|"
    if (hasReference) description += ` reference |`
    if (hasOnlineAvailability) description += ` online |`
    if (hasPhysicalStore) description += ` store |`
    if (hasWarranty) description += ` warranties |`
    if (hasService) description += ` services |`
    if (hasPrice) description += ` price |`
    if (hasLogisticsClass) description += ` logistics class |`
    if (hasMorphData) description += ` morph. data |`
    if (hasFspId) description += ` fsp id |`
    if (hasSapPlantId) description += ` sap plant id |`
    if (hasMore) description += ` notes |`
    description += ` created id |\n`

    currentProducts.forEach(it => {
        description += "|"
        if (hasReference) description += ` ${it.reference} |`
        if (hasOnlineAvailability) description += ` ${it.onlineAvailability === "t" ? "yes" : "no"} |`
        if (hasPhysicalStore) description += ` ${it.storeAvailability === "t" ? it.physicalStores.map(it => it.foreignId ? it.foreignId + (it.active ? " " : " (inactive) ") + it.name : it.name).join("; ") : "no"} |`
        if (hasWarranty) description += ` ${it.features.includes("Attached Warranty") ? it.warranty : ""} |`
        if (hasService) description += ` ${it.features.includes("Attached Service") ? it.service : ""} |`
        if (hasPrice) description += ` ${it.features.includes("Specific Price") ? it.price : ""} |`
        if (hasLogisticsClass) description += ` ${it.features.includes("Logistics Class") ? it.logisticsClass : ""} |`
        if (hasMorphData) description += ` ${it.features.includes("Morphological Data") ? it.morphologicalData : ""} |`
        if (hasFspId) description += ` ${it.features.includes("FSP ID") ? it.fspId : ""} |`
        if (hasSapPlantId) description += ` ${it.features.includes("SAP Plant ID") ? it.sapPlantId : ""} |`
        if (hasMore) description += ` ${it.furtherDetails.replaceAll("\n", "; ")} |`
        description += ` |\n`
    })

    let requester = team;
    if (requestFor === "initiative") {
        description += `\n`
        description += `relates to ${initiative}`
        requester = initiative?.split(" ")[0]
    }
    if (requestFor === "country") {
        requester = "country"
    }
    // https://confluence.atlassian.com/jirakb/how-to-create-issues-using-direct-html-links-in-jira-server-159474.html
    const summary = `[${env}] [${country}${country === "DE" ? "] [" + salesLines.join(" ") : ""}] Test Products for ${requester}`;
    const href = `https://jira.media-saturn.com/secure/CreateIssueDetails!init.jspa?pid=25219&issuetype=3&priority=4&summary=${summary}&components=27375&description=${encodeURIComponent(description)}&reporter=${user.username}`;

    function handleSubmit(e) {
        e.preventDefault();
        if (isSubmitting) return
        setIsSubmitting(true);
        createJiraIssueMutation({
                notifyOnNetworkStatusChange: true,
                variables: {
                    input: {
                        summary,
                        description,
                        relatesToInitiativeKey: initiative ? initiative.split(" ")[0] : null,
                        dueDate,
                        prio,
                        service: "REQUEST_ARTICLES"
                    }
                }
            }
        )
            .catch(err => {
                setIsSubmitting(false);
                return setError(err);
            })
            .then(res => {
                setIsSubmitting(false);
                setSuccess(res.data.createJiraIssue.createdIssue.key);
            })
    }

    const remove = toRemove => {
        let newProducts = []
        productsToCreate.forEach((it, i) => {
            if (i !== toRemove) newProducts.push(it)
        })
        setLine(line < toRemove ? line : line - 1);
        setProductsToCreate(newProducts)
    };

    const edit = async (toEdit) => {
        setProductsToCreate(currentProducts);
        setLine(toEdit)
        const productToEdit = currentProducts[toEdit]
        setReference(productToEdit.reference)
        setServiceRequirements(productToEdit.service)
        setWarrantyRequirements(productToEdit.warranty)
        setOnlineAvailability(productToEdit.onlineAvailability)
        setStoreAvailability(productToEdit.storeAvailability)
        setStores(productToEdit.physicalStores)
        setLogisticsClass(productToEdit.logisticsClass)
        setMorphologicalData(productToEdit.morphologicalData)
        setFspId(productToEdit.fspId)
        setSapPlantID(productToEdit.sapPlantId)
        setPrice(productToEdit.price)
        setFurtherDetails(productToEdit.furtherDetails)
        setFeatures(productToEdit.features)
    };

    return <>
        <Typography level={"h2"}>Request Test Product</Typography>

        <form ref={formRef} action={href} method={"GET"} target="_blank" onSubmit={handleSubmit}>

            <Box sx={{display: "flex", gap: 1, flexDirection: "column"}}>
                <RequestFor value={requestFor} onChange={(e) => setRequestFor(e.target.value)}/>

                {requestFor === "team" &&
                    <ProductName value={team} onChange={(event, newValue) => setTeam(newValue)}/>}
                {requestFor === "initiative" && <Initiative
                    value={initiative}
                    onChange={(event, newValue) => {
                        setInitiative(newValue);
                    }}
                    inputValue={initiativeInputValue}
                    onInputChange={(event, newInputValue) => {
                        setInitiativeInputValue(newInputValue);
                    }}
                    options={initiatives}/>}
                <FormHelperText>We will prioritize your request based on this quarters business
                    priorities.</FormHelperText>
                <MissingTeamOrInitiativeCta/>
                <Box
                    sx={{display: "flex", flexWrap: "wrap", gap: 3}}>
                    <Stage value={env} onChange={(e) => {
                        setEnv(e.target.value);
                        setStores([])
                    }}/>
                    <Country value={country} onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "DE") {
                            setSalesLines(["MM"])
                        }
                        if (value === "DE") {
                            setSalesLines(["MM", "SE"])
                        }
                        setCountry(value);
                        setStores([])
                    }}/>

                    {country === "DE" && <><SalesLinesMultiSelect state={salesLinesState}/></>}
                    <Prio value={prio} onChange={(e) => setPrio(e.target.value)}/>
                    <DueDate value={dueDate} onChange={e => {
                        setDueDate(e.target.value);
                    }}/>

                </Box>
                <FormHelperText>It's easier to manage for us when requests are only covering one env/country
                    combination. If you need more variants, just submit this form, then change the values and submit
                    again.</FormHelperText>
            </Box>
            <Box p={2}></Box>
            <Box sx={{display: "flex", gap: 3}}>

                {currentProducts.length > 1 &&
                    <Table sx={{overflowWrap: "anywhere"}}>
                        <thead>
                        <tr>
                            {<th>remove</th>}
                            {hasReference && <th>reference</th>}
                            {hasOnlineAvailability && <th>online</th>}
                            {hasPhysicalStore && <th>physical</th>}
                            {hasMore && <th>more</th>}
                            {hasWarranty && <th>warranties</th>}
                            {hasService && <th>services</th>}
                            {hasPrice && <th>price</th>}
                            {hasLogisticsClass && <th>logistics class</th>}
                            {hasMorphData && <th>morph. data</th>}
                            {hasFspId && <th>fsp id</th>}
                            {hasSapPlantId && <th>sap plant id</th>}
                            {<th>edit</th>}

                        </tr>
                        </thead>
                        <tbody>
                        {currentProducts.map((product, i) => {
                            let it = product;

                            const enabled = i !== line;
                            return <tr key={i}>
                                <td>
                                    {<Button
                                        disabled={!enabled}
                                        variant={enabled ? "solid" : "plain"}
                                        sx={{padding: 0}}
                                        color={"danger"}
                                        size={"sm"} onClick={() => remove(i)}><CloseIcon
                                        fontSize={"small"}></CloseIcon></Button>}
                                </td>


                                {hasReference && <td>{it.reference}</td>}
                                {hasOnlineAvailability && <td>{it.onlineAvailability === "t" ? "yes" : "no"}</td>}
                                {hasPhysicalStore &&
                                    <td>{it.storeAvailability === "t" ? it.physicalStores.map(it => it.foreignId ? it.foreignId + (it.active ? " " : " (inactive) ") + it.name : it.name).join("; ") : "no"}</td>}
                                {hasMore && <td>{it.furtherDetails}</td>}
                                {hasWarranty &&
                                    <td>{it.features?.includes("Attached Warranty") ? it.warranty : ""}</td>}
                                {hasService && <td>{it.features?.includes("Attached Service") ? it.service : ""}</td>}
                                {hasPrice && <td>{it.features?.includes("Specific Price") ? it.price : ""}</td>}
                                {hasLogisticsClass &&
                                    <td>{it.features?.includes("Logistics Class") ? it.logisticsClass : ""}</td>}
                                {hasMorphData &&
                                    <td>{it.features?.includes("Morphological Data") ? it.morphologicalData : ""}</td>}
                                {hasFspId && <td>{it.features?.includes("FSP ID") ? it.fspId : ""}</td>}
                                {hasSapPlantId && <td>{it.features?.includes("SAP Plant ID") ? it.sapPlantId : ""}</td>}
                                <td>
                                    {<Button
                                        disabled={!enabled}
                                        variant={enabled ? "solid" : "plain"}
                                        sx={{padding: 0}} color={"neutral"}
                                        size={"sm"} onClick={() => edit(i)}>
                                        <EditIcon fontSize={"small"}/>
                                    </Button>}
                                </td>

                            </tr>;
                        })}
                        </tbody>
                    </Table>}
            </Box>

            <Box sx={{display: "flex", gap: 3}}>
                <div style={{width: "60%"}}>
                    <Box sx={{display: 'flex', flexDirection: "column", gap: 2}}>

                        <FormControl>
                            <FormLabel required>Reference Product ID / URL</FormLabel>
                            <Input required value={reference} placeholder={"url or id like INT DE 12498466"}
                                   onChange={e => setReference(e.target.value)}/>
                            <FormHelperText>When there is a reference product we should take inspiration
                                from</FormHelperText>
                        </FormControl>

                        <FormControl orientation={"horizontal"}>
                            <FormLabel required>Needs Online Availability</FormLabel>
                            <NativeSelect required value={onlineAvailability} variant={"filled"}
                                          onChange={(e) => setOnlineAvailability(e.target.value)}>
                                <option value="">please select</option>
                                <option value={"t"}>yes</option>
                                <option value={"f"}>no</option>
                            </NativeSelect>
                        </FormControl>

                        <FormControl orientation={"horizontal"}>
                            <FormLabel required>Needs Store Availability</FormLabel>
                            <NativeSelect required value={storeAvailability} variant={"filled"}
                                          onChange={(e) => setStoreAvailability(e.target.value)}>
                                <option value="">please select</option>
                                <option value={"t"}>yes</option>
                                <option value={"f"}>no</option>
                            </NativeSelect>
                        </FormControl>

                        {storeAvailability === "t" && country && env && <FormControl>
                            <FormLabel required>In Stores:</FormLabel>
                            <Autocomplete
                                required={stores.length === 0}
                                multiple
                                filterSelectedOptions
                                value={stores}
                                onChange={(event, newValue) => {
                                    setStores(newValue);
                                }}
                                placeholder="409 MM Examplestore"
                                options={[...sourceNodesByForeignId.values()]

                                    .filter(it => (env === "QA" ? qaSourceNodes : intSourceNodes).includes(+it.foreignId))
                                    .filter(it => it.country === country)
                                    .filter(it => it.type === "STORE")
                                    .filter(it => country === "DE" ? salesLines.includes(it.name.substring(0, 2)) : true)
                                    .sort((it, other) => it.foreignId - other.foreignId)
                                }
                                sx={{maxWidth: 500}}

                                getOptionLabel={(option) => `${option.foreignId}${option.active ? " " : " (inactive) "}${option.name}`}
                                renderOption={(props, option) => (
                                    <AutocompleteOption {...props}>
                                        <ListItemContent>
                                            {`${option.foreignId}${option.active ? " " : " (inactive) "}${option.name}`}
                                        </ListItemContent>
                                    </AutocompleteOption>
                                )}

                            />
                        </FormControl>}
                        <FormControl>
                            <FormLabel>More</FormLabel>
                            <Textarea minRows={2} value={furtherDetails}
                                      onChange={e => setFurtherDetails(e.target.value)}
                                      placeholder={"Name, category, FSK, pre-order, promotion, bundle, lead time, ...."}/>
                            <FormHelperText>what else do we need to know?</FormHelperText>
                        </FormControl>

                    </Box>

                </div>
                <div style={{width: "40%"}}>
                    <Box sx={{display: 'flex', flexDirection: "column", gap: 2}}>
                        <Box sx={{display: 'flex', flexDirection: "column", gap: 1}}>
                            <FormControl>
                                <FormLabel>additional requirements</FormLabel>
                            </FormControl>
                            <MultiCheckbox value={"Attached Warranty"} state={featuresState}/>
                            <MultiCheckbox value={"Attached Service"} state={featuresState}/>
                            <MultiCheckbox value={"Specific Price"} state={featuresState}/>
                            <MultiCheckbox value={"Logistics Class"} state={featuresState}/>
                            <MultiCheckbox value={"Morphological Data"} state={featuresState}/>
                            <MultiCheckbox value={"FSP ID"} state={featuresState}/>
                            <MultiCheckbox value={"SAP Plant ID"} state={featuresState}/>
                        </Box>

                        {features.includes("Attached Warranty") && <FormControl>
                            <FormLabel required>Warranties</FormLabel>
                            <Input required type="text" value={warrantyRequirements}
                                   placeholder={"Product ID"}
                                   onChange={e => setWarrantyRequirements(e.target.value)}/>
                        </FormControl>}
                        {features.includes("Attached Service") && <FormControl>
                            <FormLabel required>Services</FormLabel>
                            <Input required value={serviceRequirements}
                                   placeholder={"Product ID"}
                                   onChange={e => setServiceRequirements(e.target.value)}/>
                        </FormControl>}
                        {features.includes("Specific Price") && <FormControl>
                            <FormLabel required>Price</FormLabel>
                            <Input required value={price} placeholder={"12.99"}
                                   onChange={e => setPrice(e.target.value)}
                                   slotProps={{
                                       "input": {
                                           "pattern": "[0-9.,]*"
                                       }
                                   }
                                   }
                            />
                            <FormHelperText>positiv number, ',' or '.' as decimal separator</FormHelperText>
                        </FormControl>}

                        {features.includes("Logistics Class") && <FormControl>
                            <FormLabel required>Logistics Class</FormLabel>
                            <Input required value={logisticsClass} placeholder={"3"}
                                   onChange={e => setLogisticsClass(e.target.value)}/>
                        </FormControl>}
                        {features.includes("Morphological Data") && <FormControl>
                            <FormLabel required>Morphological Data</FormLabel>
                            <Input required value={morphologicalData}
                                   placeholder={"10cmx5cmx1cm weight 0.06kg"}
                                   onChange={e => setMorphologicalData(e.target.value)}/>
                        </FormControl>}
                        {features.includes("FSP ID") && <FormControl>
                            <FormLabel required>FSP ID</FormLabel>
                            <Input required value={fspId} placeholder={"1403, 1402"}
                                   onChange={e => setFspId(e.target.value)}/>
                        </FormControl>}
                        {features.includes("SAP Plant ID") && <SapPlantId value={sapPlantId}
                                                                          onChange={e => setSapPlantID(e.target.value)}/>}

                        <Box sx={{display: "flex", justifyContent: "space-between", gap: 1, alignItems: "center"}}>
                            <Typography sx={{flexGrow: 1}}>Add Product:</Typography><Button type={"button"}
                                                                                            variant={"soft"}
                                                                                            onClick={handleAdd}>Copy
                            Current</Button><Button type={"button"} variant={"soft"} onClick={handleAddNew}>Add
                            New</Button></Box>
                        <Button disabled={isSubmitting}
                                endDecorator={isSubmitting ?
                                    <Typography fontSize="xx-small"><Loading/></Typography> : null}
                                type={"submit"}>Submit{productsToCreate.length > 1 ? " " + (productsToCreate.length) : ""}</Button>
                    </Box>
                </div>
            </Box>
        </form>
    </>;
};

export default ProductCreationQuestionnaire;