import {Box, ListItemDecorator, Tab} from "@mui/joy";

export const TabLink = ({route, label, icon, blink}) => {

    return <>
        <Box>
            <Tab disableIndicator indicatorInset component={"a"} className={blink ? "blink-red" : ""} href={`/#${route}`} value={route}>{icon ?
                <ListItemDecorator>
                    {icon}
                </ListItemDecorator>: null}{label}</Tab>
        </Box>
    </>;
};