import React, {useEffect, useState} from 'react';
import {useQuery} from "@apollo/client";
import {sourceNodes as sourceNodesQuery} from "../api/query";

export const SourceNodesContext = React.createContext({sourceNodesByForeignId: new Map()});

function SourceNodesContextBoundary({ children }) {
    const query =  useQuery(sourceNodesQuery)
    const {loading, data} = query;

    const [sourceNodes, setSourceNodes] = useState([])

    useEffect(() => {
        if (loading) return
        setSourceNodes(data?.sourceNodes?.sourceNodes || []);
    }, [loading, data])

    return <SourceNodesContext.Provider value={{sourceNodesByForeignId: new Map(sourceNodes.map((it) => [it.foreignId, it])),  query}}>{children}</SourceNodesContext.Provider>;
}

export default SourceNodesContextBoundary;
