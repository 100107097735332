/**
 * https://confluence.media-saturn.com/spaces/viewspace.action?key=MMSTech
 * @type {string[]}
 */
export const productNames =  [
  "After Sales",
  "Tech Repair",
  "Tech Support",
  "Analytics Data Solutions",
  "Analytics Services",
  "Analytics Data Foundation",
  "Analytics Governance / Data Excellence (ACoE)",
  "Analytics Governance / Data Excellence (ACoE)",
  "Analytics Governance / Data Excellence (ACoE)",
  "Analytics Intelligent Automation",
  "Assortment Optimization",
  "Sales Upgrader",
  "Space Management",
  "B2B Key Account Management",
  "Service Integration Layer",
  "Store Services",
  "Home Services",
  "Remote Services",
  "Checkout & Payment Analytics",
  "Crosschannel Checkout (XCC)",
  "CrossChannel Posting (XCP)",
  "Debtor Management (DMC)",
  "Digital Sales",
  "E-Receipt (Fiscas)",
  "Fraud Management, Payment and Financing Services",
  "Gift Card Services",
  "XPay - Payment Platform",
  "Service Contract Management",
  "Service Contract Management",
  "Service Contract Management",
  "Service Mobile Management",
  "Service Product Management",
  "Foundation",
  "Revenue",
  "Retention",
  "Activation",
  "A/B Testing",
  "Webshop Rollout Team Upper Funnel",
  "Checkout Pages",
  "Content Specials Pages",
  "Ecommerce Data Feeds",
  "Home & Campaigning",
  "Offer Selection Components",
  "Product Detail Page",
  "Webshop Orders & Wishlist",
  "Search & Browse",
  "Search Backoffice",
  "Search Engine Core",
  "Search Engine Core",
  "Search Engine Core",
  "Search Engine Machine Learning",
  "Shop now",
  "Shop now",
  "Shop now",
  "Touchpoint Realtime Analytics",
  "Touchpoint Tracking & Analytics Foundation",
  "Webshop Cloud Infrastructure",
  "Webshop Foundations",
  "Webshop Foundations",
  "Webshop Foundations",
  "Webshop Foundations",
  "Webshop Profile & Loyalty",
  "Webshop Rollout Team Lower Funnel",
  "Technical Campaigning",
  "Marketing Data Collection",
  "Marketing Ressource Management",
  "Recommendations",
  "Customer Communication Hub",
  "Customer Analytics",
  "Marketing Data Foundation",
  "Marketing AI",
  "Customer Unification",
  "Global Loyalty",
  "Global Loyalty",
  "Global Loyalty",
  "Comarch Loyalty Management",
  "CRM Loyalty APP",
  "Customer Master Data",
  "Contact Center Al",
  "Contact Center Platform",
  "Cyber Security",
  "Forecasting & Replenishment",
  "Relin",
  "PPX",
  "Saldo",
  "Industry Data Portal (IDP)",
  "ESB DevOps",
  "InStoreTV",
  "Condition Contract Management (CCM)",
  "Operational Financial Services",
  "Corporate Financial Services",
  "Corporate Stock Valuation",
  "Supply Chain Finance Integration",
  "Operational Analytics",
  "Corporate Performance Management",
  "Reporting Services",
  "Contract Master",
  "Contract Master",
  "Contract Master",
  "Condition Calculation Engine",
  "Purchase to Pay Costs",
  "Purchase to Pay Costs",
  "Purchase to Pay Costs",
  "Purchase to Pay Goods",
  "Purchase to Pay Goods",
  "Purchase to Pay Goods",
  "GBS Data Backend",
  "GBS Data Backend",
  "GBS Data Backend",
  "HR Insight",
  "People Development",
  "HR Administration",
  "API-Platform",
  "Cloud Platform",
  "Cloud Platform",
  "Cloud Platform",
  "Computing",
  "Active Directory",
  "Asset Management",
  "IAM",
  "Monitoring Platform",
  "Monitoring Platform",
  "Monitoring Platform",
  "Monitoring Platform",
  "ITSM Platform and Automation",
  "ITSM Platform and Automation",
  "ITSM Platform and Automation",
  "IT-Service Management",
  "Onsite Store Support",
  "Service Desk",
  "Branch Network",
  "Global WAN",
  "Network Data Center and Security",
  "Client Platform",
  "OnePortal",
  "OnePortal",
  "OnePortal",
  "Collaboration",
  "Communication",
  "Paperless Store",
  "Marketplace One-Offer",
  "Marketplace Analytics & Growth",
  "Marketplace Seller Management",
  "SAP Cloud / Basis / Archiving",
  "SAP Cloud / Basis / Archiving",
  "SAP Cloud / Basis / Archiving",
  "OCS Foundation",
  "SAP Authorization",
  "EDI integration",
  "Retail Masterdata",
  "Supply Chain Analytics",
  "Supply Chain Fulfillmet",
  "Supply Chain Inbound",
  "Supply Chain Inbound",
  "Supply Chain Inbound",
  "Supply Chain now Logistics",
  "Supply Chain now Finance",
  "Supply Chain Returns",
  "Warehouse Integration",
  "Availability and Reservation",
  "Delivery Promise and Sourcing",
  "Customer Order Engine",
  "Return in Transit (RIT)",
  "Customer Order Services Core",
  "Order Management Legacy",
  "Competitor Data",
  "Price Calculation",
  "Price Calculation",
  "Price Calculation",
  "Pricing Cockpit",
  "Promotion & Couponing",
  "Product Data Sourcing",
  "Product Data NOW",
  "Product Data Management",
  "Product Data Analytics & Automation",
  "Test Data Solutions",
  "Store Checkout 2.0",
  "Store Checkout",
  "Sales App",
  "Sales App",
  "Sales App",
  "Pick'n'Pack App",
  "Shelf App",
  "Carrie App",
  "Store Analytics",
  "Store Now",
  "Store Presentation",
  "Store Foundation",
  "Transport Management Systems",
  "Transport orchestration & planning",
  "Warehouse Management Hub",
  "Warehouse Management DC"
]

