import {FormControl, FormLabel, Input} from "@mui/joy";
import React from "react";

const FromToDate = ({onChangeFrom, fromValue, onChangeTo, toValue}) => <>
    <FormControl>
        <FormLabel>From</FormLabel>
        <Input variant={"soft"} type={"date"}
               value={fromValue}
               onChange={e => {
                   onChangeFrom(e.target.value)
               }}/>
    </FormControl>
    <FormControl>
        <FormLabel>Until</FormLabel>
        <Input variant={"soft"} type={"date"}
               value={toValue}
               onChange={e => {
                   onChangeTo(e.target.value)
               }}/>
    </FormControl>
</>;

export default FromToDate