import React, {useContext, useEffect, useState} from 'react';
import {ErrorContext} from '../../context/ErrorContext';
import {IconButton} from "@mui/joy";
import {Close} from "@mui/icons-material";
import Snackbar from "@mui/joy/Snackbar";

function ShowsErrorFromContext() {
    const {error} = useContext(ErrorContext);
    const [isNew, setIsNew] = useState(false);
    useEffect(() => {
        if (!error) {
            setIsNew(false);
            return;
        }
        setIsNew(true);
    }, [error]);

    return (error && isNew && (<>
        <Snackbar
            variant="outlined"
            color="danger"
            open={isNew}
            onClose={(event, reason) => {
                if (reason === 'clickaway') {
                    return;
                }
            }}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            endDecorator={
                <IconButton
                    onClick={() => setIsNew(false)}
                    variant="outlined"
                    color="neutral"
                >
                    <Close/>
                </IconButton>
            }
        >
            {error.toString()}
        </Snackbar>
</>));
}

export default ShowsErrorFromContext;
