import {LinkTargetBlank} from "../atom/LinkTartgetBlank";

const WebshopLink = ({product}) => {
    const url = product.latestWebshopScrapeResult?.url || product.webshopScrapeResults?.[0].url
    if (!url) return null
    return <LinkTargetBlank
        href={url}
        trackingTarget={`/external/webshop/${product.env}-${product.country}-${product.salesLine}-${product.foreignId}`}
    >Webshop</LinkTargetBlank>;
};
export default WebshopLink