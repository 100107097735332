import React, {useContext, useEffect, useState} from "react";
import Loading from "../atom/Loading";
import {ErrorContext} from "../../context/ErrorContext";
import Pagination from "../molecule/Pagination";
import CatalogFilter from "./CatalogFilter";
import ProductsList from "../molecule/ProductsList";
import {CatalogFilterContext} from "../../context/CatalogFilterContext";
import {Alert, Box, Button, Card, IconButton, Stack, Typography} from "@mui/joy";
import {CatalogContext} from "../../context/CatalogContext";
import {Close, FilterAltOff} from "@mui/icons-material";
import {Sort} from "../../model/product";
import {useErrorNotification} from "../../api/apolloClient";
import {NotFoundCta} from "../molecule/NotFoundCta";
import LoadingBar from "../atom/LoadingBar";

const BrowseCatalog = () => {
    const {products, query} = useContext(CatalogContext)
    const {error, loading} = query;
    const {setError} = useContext(ErrorContext);
    const {
        filters,
        filterValues,
        isDefault,
        reset,
    } = useContext(CatalogFilterContext);
    const [page, setPage] = useState(0);
    // set to true to activate red error-badge
    const [showIssue, setShowIssue] = useState(false);
    const perPage = 20;
    const [productsToDisplay, setProductsToDisplay] = useState([]);


    useEffect(() => {
        // on filter change, return to page 0
        setPage(0)
        // eslint-disable-next-line
    }, Object.values(filterValues))

    useErrorNotification(error, setError)


    useEffect(() => {
        if(loading || !(products instanceof Array)) return;
        let filteredProducts = [...products];
        Object.keys(filters).forEach(key => filteredProducts = filteredProducts.filter(filters[key]))
        setProductsToDisplay(filteredProducts.sort(Sort.byCreatedInMasterDataDesc))
        // eslint-disable-next-line
    }, [Object.values(filterValues).join(""), products, filters, loading])


    if (loading || !(products instanceof Array)) return <>
        <Box sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
            <Loading/>
            <Typography level={"body-sm"}>first load might take a while, please be patient</Typography>
            <LoadingBar/>
        </Box>
    </>
    if (error) {
        return null;
    }

    const productsForPage = productsToDisplay.slice(perPage * page, perPage * (page + 1));
    return <>
        {showIssue && <Alert
            color={"danger"}
            variant={"solid"}
            endDecorator={
                <React.Fragment>
                    <IconButton variant="soft" size="sm" color="neutral" onClick={() => setShowIssue(false)}>
                        <Close />
                    </IconButton>
                </React.Fragment>
            }
        >
            <Typography>KNOWN ISSUE: BE shows no webshop link. See also https://rkt.mediamarktsaturn.com/channel/consumer_upper_funnel?msg=BZRiftK8Fn5bh7gc7</Typography>
        </Alert>}
        <Box sx={{display: "flex", gap: 2}}>
            <Box sx={{maxWidth: 240}}>
                <h1>Catalog</h1>
                <CatalogFilter products={products} filtered={productsToDisplay}/>
            </Box>
            <Stack spacing={1} width={1.0}>
                {productsForPage.length === 0 && <>
                    <Card variant={"outlined"} sx={{width: 1.0}}><Typography>No results matching your
                        search <Button variant={"outlined"} color={"neutral"} onClick={reset}>reset all filters</Button></Typography>
                    </Card>
                </>}
                {productsForPage.length > 0 && <>
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                        <Button disabled={isDefault} color={isDefault ? "neutral" : "primary"} variant={"soft"}
                                onClick={reset}>reset filters<FilterAltOff/></Button>
                        <Pagination results={productsToDisplay.length} perPage={perPage}
                            currentPage={page}
                                    setPage={setPage}/>
                    </Box>
                    <ProductsList products={productsForPage}/>
                    <Pagination results={productsToDisplay.length} perPage={perPage}
                        currentPage={page}
                                setPage={setPage}/>
                </>}
                <NotFoundCta/>
            </Stack>
        </Box>
    </>
}

export default BrowseCatalog