let href = window.location.href;
export const IS_STAGE_LOCAL = href.includes("localhost:3000")
export const IS_STAGE_DEV = href.includes("test-data-navigator-dev.")
export const IS_STAGE_INT = href.includes("test-data-navigator-int.")
export const IS_STAGE_QA = href.includes("test-data-navigator-qa.")
export const IS_STAGE_PROD = href.includes("test-data-navigator.")
export let STAGE = "FIXME"
if (IS_STAGE_LOCAL) STAGE = "LOCAL"
if (IS_STAGE_DEV) STAGE = "DEV"
if (IS_STAGE_INT) STAGE = "INT"
if (IS_STAGE_QA) STAGE = "QA"
if (IS_STAGE_PROD) STAGE = "PROD"