import React, {useState} from 'react';

export const SuccessContext = React.createContext(undefined);

function SuccessBoundary({ children }) {
  const [success, setSuccess] = useState(null);

  return <SuccessContext.Provider value={{ success, setSuccess }}>{children}</SuccessContext.Provider>;
}

export default SuccessBoundary;
