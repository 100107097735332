import {FormControl, FormLabel} from "@mui/joy";
import {NativeSelect} from "@mui/material";
import React from "react";

const Country = ({value, onChange, orientation}) => <FormControl orientation={orientation}>
    <FormLabel required>Country</FormLabel>
    <NativeSelect required value={value} variant={"filled"}
                  onChange={onChange}>
        <option value="" disabled></option>
        <option value="AT">Austria (AT)</option>
        <option value="BE">Belgium (BE)</option>
        <option value="DE">Germany (DE)</option>
        <option value="HU">Hungary (HU)</option>
        <option value="IT">Italy (IT)</option>
        <option value="LU">Luxembourg (LU)</option>
        <option value="NL">Netherlands (NL)</option>
        <option value="PL">Poland (PL)</option>
        <option value="PT">Portugal (PT)</option>
        <option value="ES">Spain (ES)</option>
        <option value="SE">Sweden (SE)</option>
        <option value="CH">Switzerland (CH)</option>
        <option value="TR">Turkey (TR)</option>
    </NativeSelect>
</FormControl>;
export default Country