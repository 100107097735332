import {Box, Typography} from "@mui/joy";
import NavigationTabs from "./NavigationTabs";
import React from "react";
import VisibleForAdmins from "../atom/VisibleForAdmins";
import AdminToggles from "../molecule/AdminToggles";
import {STAGE} from "../../constant/stage";

const LeftMenu = () => {
    return <>
        <Box role='navigation'
             sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '95vh'}}>
            <Box sx={{width: "100%"}}>
                <NavigationTabs/>
            </Box>
            <Box>
                <VisibleForAdmins>
                    <AdminToggles/>
                    <Box m={3}/>
                </VisibleForAdmins>
                    <Box p={1}>
                        <Typography level={"body-xs"}>v{process.env.REACT_APP_VERSION} {STAGE}</Typography>
                    </Box>
            </Box>
        </Box>

    </>;
};

export default LeftMenu