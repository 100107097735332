class Service {
    name;
    path;
    description;
    shortName;
    icon;

    /**
     * @param {String} name
     * @param {String} shortName
     * @param {String} path
     * @param {String[]} description
     * @param {JSX.Element} icon
     */
    constructor(name, shortName, path, description, icon) {
        this.shortName = shortName;
        this.name = name;
        this.path = path;
        this.description = description;
        this.icon = icon;
    }
}

export default Service