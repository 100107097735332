export const displayName = product => product.latestSalesProductsApiScrapeResult?.basicName || product.latestWebshopScrapeResult?.title || "unknown";

const byForeignIdDesc = (a, b) => {
    if (a.foreignId > b.foreignId) {
        return 1;
    }
    if (b.foreignId > a.foreignId) {
        return -1;
    }
    return 0;
};

let byCreatedInMasterDataDesc = (a1, a2) => {
    let a1Seconds = a1.latestSalesProductsApiScrapeResult?.metaData?.createdAtInMasterData?.seconds;
    let a2Seconds = a2.latestSalesProductsApiScrapeResult?.metaData?.createdAtInMasterData?.seconds;
    if (!a1Seconds) return 1
    if (!a2Seconds) return -1
    if (!a2Seconds && !a1Seconds) return 0
    return a2Seconds - a1Seconds
};

export const Sort = {
    byForeignIdDesc,
    byCreatedInMasterDataDesc
}

export class PreviewProduct {
    id
    country
    salesLine

    constructor(id, country, salesLine) {
        this.id = id;
        this.country = country;
        this.salesLine = salesLine;
    }

    // https://www.mediamarkt.de/de/product/_samsung-galaxy-s22-5g-128-gb-phantom-white-dual-sim-galaxy-s22-5g-2784780.html
    static fromUrl = (url) => {
        const id = url.split("-").pop().split(".html")[0]
        let salesLine;
        if (url.includes("saturn.")) {
            salesLine = SALESLINE.SATURN;
        }
        if (url.includes("mediamarkt.")) {
            salesLine = SALESLINE.MEDIAMARKT;
        }
        let country;
        if (salesLine === SALESLINE.SATURN) {
            country = url.split("saturn.").pop().split("/")[0]
        }
        if (salesLine === SALESLINE.MEDIAMARKT) {
            country = url.split("mediamarkt.").pop().split("/")[0]
        }
        return new PreviewProduct(id, country, salesLine)
    }
}

export const SALESLINE = {
    MEDIAMARKT: "MM",
    SATURN: "SE"
}

export class ProductToCreate {
    reference
    service
    warranty
    onlineAvailability
    storeAvailability
    physicalStores
    logisticsClass
    morphologicalData
    fspId
    sapPlantId
    price
    furtherDetails
    features

    constructor(reference, service, warranty, onlineAvailability,storeAvailability,  physicalStores, logisticsClass, morphologicalData, fspId, sapPlantId, price, furtherDetails, features) {
        this.reference = reference;
        this.service = service;
        this.warranty = warranty;
        this.onlineAvailability = onlineAvailability;
        this.storeAvailability = storeAvailability;
        this.physicalStores = physicalStores;
        this.logisticsClass = logisticsClass;
        this.morphologicalData = morphologicalData;
        this.fspId = fspId;
        this.sapPlantId = sapPlantId;
        this.price = price;
        this.furtherDetails = furtherDetails;
        this.features = features
    }

    static emptyProduct() {
        return new ProductToCreate(
            "",
            "",
            "",
            "",
            "",
            [],
            "",
            "",
            "",
            "",
            "",
            "",
            []
        );
    }
}



