import Snackbar from "@mui/joy/Snackbar";
import {IconButton} from "@mui/joy";
import {Close} from "@mui/icons-material";
import {LinkTargetBlank} from "../atom/LinkTartgetBlank";
import React from "react";

function JiraIssueCreationSuccess({createdJiraIssue, onClick, open}) {
    return <Snackbar
        variant="soft"
        color="success"
        open={open}
        onClose={(event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
        }}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
        size={"lg"}
        endDecorator={
            <IconButton
                onClick={onClick}
                variant="outlined"
                color="neutral"
            >
                <Close/>
            </IconButton>
        }
    >
        Jira issue <LinkTargetBlank
        href={"https://jira.media-saturn.com/browse/" + createdJiraIssue}>{createdJiraIssue}</LinkTargetBlank> created
    </Snackbar>;
}

export default JiraIssueCreationSuccess