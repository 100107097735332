import {Box, Button} from "@mui/joy";
import {NavigateBefore, NavigateNext} from "@mui/icons-material";
import React from "react";

const Pagination = ({results, currentPage, setPage}) => {
    const pages = results < 2 ? 1 : results - 1

    const PageButton = () => {
            return <Button color={"primary"} variant={"soft"} disabled>{pages - currentPage} / {pages}</Button>
    };

    return <>
        <Box sx={{display: "inline-flex", justifyContent: "center"}}>
            <Button variant={"plain"}
                    onClick={() => setPage(Math.min(pages - 1, currentPage + 1))}><NavigateBefore/></Button>
            <PageButton/>
            <Button variant={"plain"} onClick={() => setPage(Math.max(0, currentPage - 1))}><NavigateNext/></Button>
        </Box>
    </>;
};

export default Pagination