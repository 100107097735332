import {FormControl, FormLabel} from "@mui/joy";
import {NativeSelect} from "@mui/material";
import React from "react";

function SalesLine({onChange, value}) {
    return <FormControl>
        <FormLabel required>Sales Line</FormLabel>
        <NativeSelect fullWidth required value={value} onChange={onChange}>
            <option value="MM">MM</option>
            <option value="SE">SE</option>
        </NativeSelect>
    </FormControl>;
}

export default SalesLine