import {Box, FormHelperText, Typography} from "@mui/joy";
import {LinkTargetBlank} from "../atom/LinkTartgetBlank";
import React from "react";

export const MissingTeamOrInitiativeCta = () => <>
        <FormHelperText>Your Team or Initiative is missing?
            <Box p={0.5}/>
            <Typography level={"body-sm"}>
            <LinkTargetBlank href={"https://rkt.mediamarktsaturn.com/channel/test-data-solutions"}>Let us know!</LinkTargetBlank>
            </Typography></FormHelperText>
    </>;