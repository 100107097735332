import {Box, Grid} from "@mui/joy";
import ShowsErrorFromContext from "../organism/ShowsErrorFromContext";
import React from "react";
import ShowsSuccessFromContext from "../organism/ShowsSuccessFromContext";

const Page = ({leftNav, children}) => {
    return <>
        <ShowsErrorFromContext/>
        <Grid container spacing={0.5} disableEqualOverflow>
            <Grid xs={4} sm={3} md={2} lg={2}>
                {leftNav}
            </Grid>
            <Grid xs={8} sm={9} md={10} lg={10} role={"main"}>
                <Box m={0.5}>{children}</Box>
            </Grid>
        </Grid>
        <ShowsSuccessFromContext/>
    </>
}

export default Page