import React, {useContext, useEffect, useState} from 'react';
import {SuccessContext} from "../../context/SuccessContext";
import JiraIssueCreationSuccess from "../molecule/JiraIssueCreationSuccess";

function ShowsSuccessFromContext() {
    const {success} = useContext(SuccessContext);
    const [show, setShow] = useState(false)
    useEffect(() => {
        if (!success) return
        setShow(true)
    }, [success])

    return <JiraIssueCreationSuccess open={show} onClick={() => setShow(false)}
                                         createdJiraIssue={success}/>;
}

export default ShowsSuccessFromContext;
