import {useQuery} from "@apollo/client";
import {salesProduct} from "../../api/query";
import {Box, Button, Card, Typography} from "@mui/joy";
import Loading from "../atom/Loading";
import {useContext} from "react";
import {ErrorContext} from "../../context/ErrorContext";
import {useErrorNotification} from "../../api/apolloClient";

/**
 *
 * @param {PreviewProduct} product
 * @returns {JSX.Element}
 * @constructor
 */
const ProductPreview = ({product}) => {
    let variables = {
        input: {
            id: product.id, salesLine: product.salesLine, stage: "PROD", country: product.country?.toUpperCase(),
        }
    };
    const query = useQuery(salesProduct, {variables, notifyOnNetworkStatusChange: true})
    const {loading, data, error, refetch} = query;
    const {setError} = useContext(ErrorContext);
    useErrorNotification(error, setError)

    if (loading) return <Loading/>;
    let retry = <Button color={"warning"} onClick={() => refetch(variables)}>{loading ? <Loading/> : "retry"}</Button>;
    if (error) return retry;
    const salesProductApi = data.salesProductApi;
    if (!salesProductApi) return null;
    if (!salesProductApi.name && !salesProductApi.kindOfProduct) {
        return <Typography color={"danger"}>no results, check url {retry}</Typography>
    }
    return <>
        <Card variant={"soft"}
              sx={{display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-around"}}>
            <Box sx={{}}>
                <Typography level={"h4"}>{`PROD ${product.country?.toUpperCase()} ${product.salesLine} ${product.id}`}</Typography>
                <Typography level={"h5"}>{salesProductApi.name}</Typography>
                <Typography level={"h5"}>{salesProductApi.kindOfProduct}</Typography>
            </Box>
            {salesProductApi.productImageUrl &&
                <img style={{objectFit: "cover", maxWidth: 200, borderRadius: 10}} src={salesProductApi.productImageUrl}
                     alt="product"/>}

        </Card>
    </>
}

export default ProductPreview