import ReleaseNote from "../model/releaseNote";

// eslint-disable-next-line
const upcoming = [
]

const releaseNotes = [
    new ReleaseNote("1.5.6", "2024-04-16", "Catalog Query Parameter", [
        "the catalog url now accepts a query parameter so you can now link to specific search results.",
        "https://test-data-navigator.cloud.mmst.eu/#/services/catalog?q=ocs",
        "https://test-data-navigator.cloud.mmst.eu/#/services/catalog?q=1471565",
    ]),
    new ReleaseNote("1.4.19", "2024-03-25", "display similar env health checks", [
        "environment health dashboard now shows abbreviated 'group' of check. like for 'webshop' it shows 'w5p', 'delivery time indication' shows abbreviated as 'd16n'",
        "this should make it easier to see what systems are affected"
    ]),
    new ReleaseNote("1.4.15", "2024-03-19", "show Switzerland in the catalog", [
        "the catalog now also shows entries from Switzerland (CH)"
    ]),
     new ReleaseNote("1.4.14", "2024-03-18", "highlight environment issues", [
        "left navigation menu: the 'services -> env health' icon now blinks red if there are failing (not successful and not ignored) health checks"
    ]),
    new ReleaseNote("1.4.0", "2024-03-05", "all TDT services accessible through this app", [
        "TDS-377 the test data team offers specific services. previously requested through Jira, these all are now available here (and potentially exclusively here). You should see an extended services menu. Sending any of the forms should open a suitable jira issue.",
        "Previously only for internal use, we now show a dashboard listing the status of our test environment smoke tests. Check the user manual to learn more. You'll find it in the list of services"
    ]),
    new ReleaseNote("1.3.7", "2024-03-01", "Release Notes", [
        "TDS-379 this release note informs you about the release of the release notes. You'll also now see a version info on the bottom left."
    ]),
     new ReleaseNote("1.2.28", "2024-02-24", "Skip Jira Step", [
        "TDS-253 when requesting to create products and specifying the physical store: instead of free text it is now a list build from the source nodes API and https://confluence.media-saturn.com/pages/viewpage.action?pageId=409641628 GMS Testsystems in the GCP (Stage QA) and https://confluence.media-saturn.com/pages/viewpage.action?pageId=409641868 (Stage INT)",
        "when requesting to create products: submitting the form will not forward you to Jira, instead the Jira issue will be created in the background and a notification will be shown"
    ]),
    new ReleaseNote("1.2.27", "2024-02-24", "Source Node List When Creating Products", [
        "You can now pick source nodes from a list instead of providing a free text",
    ]),
    new ReleaseNote("1.2.22", "2024-02-21", "Catalog Filter", [
        "TDS-371 when selecting some option from the \"http status filter\" in the catalog, the not selected options are still displayed",
        "TDS-371 when selecting some option from the \"product online status\" in the catalog, the not selected options are still displayed",
    ]),
    new ReleaseNote("1.2.21", "2024-02-20", "Query Initiatives", [
        "TDS-311 when having to select an initiative, the list is now queried from Jira instead of hard coded"
    ]),
    new ReleaseNote("1.2.2", "2024-02-05", "Webshop Buy Button Indicator", [
        "We added a colored cart icon to the catalog list items to show if they are available on the webshop."
    ]),
]

export default releaseNotes