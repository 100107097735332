import {Alert, Typography} from "@mui/joy";
import React from "react";

const KnownEnvIssues = () => {
    const issues = [];
    if (issues.length === 0) {
        return null
    }
    return <>
        <Typography level={"h3"}>known issues:
            {issues.map((issue, i) =>
                <Alert key={i}
                       color={"danger"}
                       variant={"outlined"}>
                    {issue}
                </Alert>)}

        </Typography>
    </>;
};

export default KnownEnvIssues