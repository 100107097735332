import {Link} from "@mui/joy";
import {OpenInNew} from "@mui/icons-material";
import React, {useContext} from "react";
import {AuthContext} from "../../context/AuthContext";
import {trackNavigate} from "../../api/http";

export const LinkTargetBlank = ({href, children, fullWidth, trackingTarget}) => {
    const {user} = useContext(AuthContext)

    return <Link
        sx={fullWidth ? {width: 1.0}: null}
        onClick={() => trackNavigate(user, trackingTarget || href)}
        href={href} target="_blank" rel="noreferrer" endDecorator={<><OpenInNew fontSize={"small"}/></>}>{children}</Link>;
};