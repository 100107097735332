import {Typography} from "@mui/joy";
import {LinkTargetBlank} from "../atom/LinkTartgetBlank";
import React from "react";

export const NotFoundCta = () => <>
    <Typography sx={{textAlign: 'end'}}>
        <Typography mt={3}>Not found what you were looking for?</Typography>
    </Typography>
    <Typography sx={{textAlign: 'end'}}>
        <LinkTargetBlank href={"https://rkt.mediamarktsaturn.com/channel/test-data-solutions"}>Let us know!</LinkTargetBlank>
    </Typography>
    </>;