import {useQuery} from "@apollo/client";
import React, {useContext} from "react";
import {ErrorContext} from "../../context/ErrorContext";
import {useErrorNotification} from "../../api/apolloClient";
import Loading from "../atom/Loading";
import {latestHealthCheckResults} from "../../api/query";
import {Chip, List, ListItem, Typography} from "@mui/joy";
import {Check, Close} from "@mui/icons-material";
import LocalDateTime from "../atom/LocalDateTime";
import {LinkTargetBlank} from "../atom/LinkTartgetBlank";
import {ADMIN_INSPECT_ID_PATH} from "../../constant/routes";

const ChecksHistory = () => {
    const query =  useQuery(latestHealthCheckResults)
    const {loading, data, error} = query;

    const { setError } = useContext(ErrorContext);
    useErrorNotification(error, setError)

    if (loading) return <Loading/>
    if (error) {
        return null;
    }

    const results = data.latestHealthCheckResults;

    if (!results || results.length < 1) {
        return null;
    }

    return <>
        <Typography level={"h2"}>History</Typography>
        <List>
            {results.map(result => {
                let success = result.status === "SUCCESS";
                return <React.Fragment key={result.id}>
                    <ListItem>
                        <Chip variant="soft" color={success ? "success" : "warning"}>
                            {success ? <Check/> : <Close/>}
                        </Chip>
                        <LocalDateTime epochSeconds={result.createdAt.seconds} />{" "}{`${result.attribute} ${result.matcher} ${result.value} ${result.message || ""}`.toLowerCase()}
                        <LinkTargetBlank href={`/#${ADMIN_INSPECT_ID_PATH}/${result.productId}`}/>
                      </ListItem>

                </React.Fragment>;
            })}
        </List>
    </>
}

export default ChecksHistory