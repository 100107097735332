import {Box, Card, List, ListItem, Typography} from "@mui/joy";
import {useContext, useEffect} from "react";
import {ReleaseNotesReadContext} from "../../context/ReleaseNotesReadContext";
import releaseNotes from "../../constant/releaseNotes";

const ReleaseNotes = () => {
    const {latestReleaseNote, setLatestReleaseNoteRead} = useContext(ReleaseNotesReadContext)

    useEffect(() => {
        setLatestReleaseNoteRead(latestReleaseNote)
    }, [latestReleaseNote, setLatestReleaseNoteRead])

    return <>
        <Typography level={"h2"}>Release Notes</Typography>
        <Box p={2} sx={{display: "flex", flexDirection:"column", gap: 2}}>
            {releaseNotes.map(it => <Card key={it.version}>
                <Typography level={"h3"}>v{it.version} {it.date} {it.title}</Typography>
                <List marker={"circle"}>
                    {it.bulletPoints.map((point, i) => <ListItem key={i}><Typography>{point}</Typography></ListItem>)}
                </List>
            </Card>)
            }
        </Box>
    </>
}

export default ReleaseNotes